import React, { useEffect, useRef, useState } from 'react';
import './RentalAccount.scss';
import { Helmet } from 'react-helmet';

import { IDropDownValues, IRentalAccountData, IRentalAccountsData } from '../../../commons/api/rental/Rental.interface';
import {
  Accordion,
  AccordionItem,
  Badge,
  Card,
  CardBody,
  Dropdown,
  ErrorPanel,
  LoadingIndicator,
  Section,
  StatusLink,
  SubSection,
  SVG,
} from 'digit.commons.ui-components';
import RentalAccountDetail from './RentalAccountDetails/RentalAccountDetails';

import { RENTAL_ACCOUNT_LABELS, RENTAL_OBJECT_ICON } from '../../constants/containers/rental-account-labels';
import { FAQ_LABELS } from '../../constants/containers/faq-labels';
import { RENTAL_ACCOUNT, REQUESTS_AND_DOCUMENTS } from '../../constants/routes';

import { piwikEvents } from '../../../commons/utility/piwikEvents';
import { FORMATTER } from '../../utility/formatHandler';
import { useHistory, useParams } from 'react-router-dom';
import { newMoment } from '../../../commons/utility/DateFunctions';
import { CovidSupportAnnouncement } from '../../components/Announcement/CovidSupportAnnouncement';
import { IAnnouncement, IAnnouncements } from '../../../commons/api/announcement/Announcement.interface';
import RentalApi from '../../../commons/api/rental/Rental.api';
import RentalAnnouncementApi from '../../../commons/api/announcement/RentalAnnouncement.api';
import { useFetch } from '../../../commons/hooks/useFetch';
import VideoPlayer, { VideoPlayerInterface } from '../../components/VideoPlayer/VideoPlayer';
import { checkVideoSeen, setVideoAsSeen } from '../../utility/videoCacheHandler';

export const rentalVideoOptions: VideoPlayerInterface = {
  autoPlay: false,
  controls: true,
  muted: false,
  sources: [
    {
      src: '/assets/videos/rental/Berechnung_des_Mietzins.m3u8',
      type: 'application/x-mpegURL',
    },
  ],
  poster: '/assets/videos/rental/Berechnung_des_Mietzins.jpg',
  tracks: [
    {
      src: '/assets/videos/rental/Berechnung_des_Mietzins.vtt',
      kind: 'captions',
      default: false,
      srclang: 'de',
      label: 'Deutsch',
    },
  ],
};

interface IMatchParams {
  vkont?: string;
}

const RentalAccount: React.FC = () => {
  const [selectedRentalAccount, setSelectedRentalAccount] = useState<IRentalAccountData>();
  const [activeFAQTab, setActiveFAQTab] = useState<number>(-1);
  const [rentalAccounts, setRentalAccounts] = useState<IRentalAccountData[]>(null);
  const [announcements, setAnnouncements] = useState<IAnnouncement[]>(null);
  const [isVideoSeen, setVideoSeen] = useState<boolean>(checkVideoSeen('rentalVideoSeen'));
  const dropdownValues = useRef<IDropDownValues[]>();
  const params = useParams<IMatchParams>();
  const history = useHistory();

  const playerRef = useRef(null);

  const { data, isFetchInProgress, fetchErrors } = useFetch<IRentalAccountsData & IAnnouncements>({
    [RentalApi.orig]: () => RentalApi.fetchRentalAccount(true),
    [RentalAnnouncementApi.orig]: () => RentalAnnouncementApi.fetchAndSortAnnouncementsByPriority(),
  });

  useEffect(() => {
    if (data && data.rentalAccounts) {
      const sortedRentalAccounts = [...data.rentalAccounts]?.sort(
        (rentalAccount1: IRentalAccountData, rentalAccount2: IRentalAccountData) => {
          /* KUN-1961: invalid objects should be at the very bottom, Wohnung should be ranked higher than any other type */
          if (rentalAccount1.validTo < newMoment().valueOf() && rentalAccount1.validTo < rentalAccount2.validTo) {
            return 1;
          } else if (
            rentalAccount2.validTo < newMoment().valueOf() &&
            rentalAccount2.validTo < rentalAccount1.validTo
          ) {
            return -1;
          }

          if (rentalAccount2.detail.usageTypeText === 'Wohnung' && rentalAccount1.detail.usageTypeText !== 'Wohnung') {
            return 1;
          } else if (
            rentalAccount1.detail.usageTypeText === 'Wohnung' &&
            rentalAccount2.detail.usageTypeText !== 'Wohnung'
          ) {
            return -1;
          }
          return 0;
        }
      );
      setRentalAccounts(sortedRentalAccounts);
      setAnnouncements(data.announcements);
    }
  }, [data]);

  useEffect(() => {
    if (rentalAccounts) {
      if (params?.vkont) {
        setSelectedRentalAccount(rentalAccounts.find(account => account.vKont === params.vkont));
      } else {
        setSelectedRentalAccount(rentalAccounts[0]);
      }

      setDropdownValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rentalAccounts]);

  const handlePlayerReady = player => {
    playerRef.current = player;
  };

  const setDropdownValues = () => {
    let dropDownOptions = [];
    rentalAccounts.forEach(rentalAccount =>
      dropDownOptions.push({
        rentalObjectText: rentalAccount.rentalObjectText,
        rentalObjectType: rentalAccount.detail.usageTypeText,
        vKont: rentalAccount.vKont,
        icon: RENTAL_OBJECT_ICON[rentalAccount.detail.usageTypeText]
          ? RENTAL_OBJECT_ICON[rentalAccount.detail.usageTypeText]
          : SVG.rental_object_flat,
      })
    );
    dropdownValues.current = dropDownOptions;
  };

  const onAccordionTabChange = activeTab => setActiveFAQTab(activeTab === activeFAQTab ? -1 : activeTab);

  const onDropdownChange = vkont => {
    setSelectedRentalAccount(rentalAccounts.find(account => account.vKont === vkont));
    history.push(`/mietzinskonto/${vkont}`);
  };

  const renderAnnouncements = () => {
    return announcements?.map((announcement, idx) => {
      switch (announcement.type) {
        case 'COVID_FLAT_SUPPORT':
          if (
            announcement.data.RentalObjects.some(rentalObject => rentalObject.vkont === selectedRentalAccount.vKont)
          ) {
            return (
              <div className={'RentalAccount__announcement'}>
                <CovidSupportAnnouncement
                  id={`rental-announcement-${idx}`}
                  key={`rental-announcement-${announcement.id}`}
                  announcement={announcement}
                  hideLinkToDigitPage={true}
                  onClose={() => () => {}}
                />
              </div>
            );
          } else {
            return <></>;
          }
        default:
          return <></>;
      }
    });
  };

  const renderAndPublishErrorToMatomo = () => {
    if (fetchErrors.some(error => error.orig === 'RentalApi')) {
      piwikEvents.trackEvent('technischer Fehler', 'Mietzinskonto', 'Fehler beim Laden der Details des Mietzinskontos');

      return (
        <ErrorPanel
          id="rental-account"
          title={RENTAL_ACCOUNT_LABELS.generalErrorPanel.title}
          errorText={RENTAL_ACCOUNT_LABELS.generalErrorPanel.text}
          descriptionText={RENTAL_ACCOUNT_LABELS.generalErrorPanel.description}
        />
      );
    } else {
      piwikEvents.trackEvent('fachlicher Fehler', 'Mietzinskonto', 'Es konnten keine aktiven Verträge gefunden werden');

      return (
        <ErrorPanel
          id="no-active-contract"
          title={RENTAL_ACCOUNT_LABELS.noActiveContractErrorPanel.title}
          errorText={RENTAL_ACCOUNT_LABELS.noActiveContractErrorPanel.text}
          descriptionText={RENTAL_ACCOUNT_LABELS.noActiveContractErrorPanel.description}
        />
      );
    }
  };

  const options =
    dropdownValues.current &&
    dropdownValues.current.map((val: IDropDownValues) => {
      return {
        icon: val.icon,
        value: val.vKont,
        text: `${val.rentalObjectType}: ${FORMATTER.formatSAPString(val.rentalObjectText)}`,
      };
    });

  if (fetchErrors.isNullOrEmpty() && (isFetchInProgress || !selectedRentalAccount)) {
    return <LoadingIndicator id="rental-account" />;
  }

  return (
    <>
      <Helmet>
        <title>{RENTAL_ACCOUNT_LABELS.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name={'description'} content={RENTAL_ACCOUNT_LABELS.meta} />
      </Helmet>
      <Section
        id={'rental-account'}
        className={'RentalAccount'}
        title={RENTAL_ACCOUNT_LABELS.title}
        removePaddingBottom
      >
        <span role="alert" aria-live="assertive" className="sr-only">
          <em>{RENTAL_ACCOUNT_LABELS.title}</em> wurde geladen
        </span>
        {selectedRentalAccount && !isVideoSeen && (
          <div className={'RentalAccount__announcement'}>
            <Card
              id={'rental-explanation-video'}
              className={'Announcement RentalAccount__video'}
              onDismiss={() => setVideoAsSeen('rentalVideoSeen', setVideoSeen)}
            >
              <header>
                <Badge id={'rental-explanation-video'} modifier={'GREEN'} iconBefore={SVG.bell}>
                  {RENTAL_ACCOUNT_LABELS.video.badgeText}
                </Badge>
                <h3>{RENTAL_ACCOUNT_LABELS.video.title}</h3>
                <h4>{RENTAL_ACCOUNT_LABELS.video.subtitle}</h4>
              </header>
              <CardBody>
                <VideoPlayer
                  options={rentalVideoOptions}
                  onReady={handlePlayerReady}
                  onPlayThrough={() => setVideoAsSeen('rentalVideoSeen')}
                />
              </CardBody>
            </Card>
          </div>
        )}
        {selectedRentalAccount && renderAnnouncements()}
        {options && options.length > 0 && (
          <div className="RentalAccount__container">
            <Dropdown
              id={'rental-account'}
              label={RENTAL_ACCOUNT_LABELS.dropDown.label}
              required={true}
              options={options}
              value={params?.vkont || options[0].value}
              onChange={onDropdownChange}
              errorMessage={RENTAL_ACCOUNT_LABELS.dropDown.errorMessage}
            />
          </div>
        )}
        {fetchErrors?.some(error => error.orig === 'RentalApi') ? (
          renderAndPublishErrorToMatomo()
        ) : (
          <>
            <RentalAccountDetail {...selectedRentalAccount?.detail} onWhatHappensLinkClick={() => setActiveFAQTab(2)} />
            {selectedRentalAccount && selectedRentalAccount.detail ? (
              <>
                {selectedRentalAccount && !selectedRentalAccount.overdue && (
                  <SubSection id={'rental-account-documents'} title={'Dokumente'}>
                    <StatusLink
                      id={'rental-account-documents-rent-information-1'}
                      className={'RentalAccount__StatusLink'}
                      level={'4'}
                      icon={SVG.euro_bills}
                      title={RENTAL_ACCOUNT_LABELS.documents.rent.title}
                      description={RENTAL_ACCOUNT_LABELS.documents.rent.description}
                      linkDestination={`${REQUESTS_AND_DOCUMENTS}/mietzins`}
                      linkRouteState={{
                        closeRoute: RENTAL_ACCOUNT,
                        selectedRentalObject: selectedRentalAccount.vKont,
                      }}
                      linkIsExternal={false}
                      linkText={RENTAL_ACCOUNT_LABELS.documents.rent.linkText}
                    />
                    <StatusLink
                      id={'rental-account-documents-rent-information-2'}
                      className={'RentalAccount__StatusLink'}
                      icon={SVG.euro_bills}
                      title={RENTAL_ACCOUNT_LABELS.documents.interestInformation.title}
                      level={'4'}
                      description={RENTAL_ACCOUNT_LABELS.documents.interestInformation.description}
                      linkDestination={`${REQUESTS_AND_DOCUMENTS}/zinszettel`}
                      linkRouteState={{
                        closeRoute: RENTAL_ACCOUNT,
                        selectedRentalObject: selectedRentalAccount.vKont,
                      }}
                      linkIsExternal={false}
                      linkText={RENTAL_ACCOUNT_LABELS.documents.interestInformation.linkText}
                    />
                  </SubSection>
                )}
                <SubSection
                  id={'rental-account-bankinfo'}
                  title={RENTAL_ACCOUNT_LABELS.bankInfo.title}
                  removePaddingTop={selectedRentalAccount && !selectedRentalAccount.overdue}
                >
                  <dl>
                    <div className={'RentalAccount__bankinfo'}>
                      <dt>{RENTAL_ACCOUNT_LABELS.bankInfo.rentalObject}</dt>
                      <dd>
                        {`${selectedRentalAccount.detail.usageTypeText} · ${FORMATTER.formatSAPString(
                          selectedRentalAccount.rentalObjectText
                        )}`}
                      </dd>
                    </div>
                    <div className={'RentalAccount__bankinfo'}>
                      <dt>{RENTAL_ACCOUNT_LABELS.bankInfo.vkont}</dt>
                      <dd>{selectedRentalAccount.detail.vKont}</dd>
                    </div>
                  </dl>
                  <p className={'RentalAccount__p'}>{RENTAL_ACCOUNT_LABELS.bankInfo.info}</p>
                  <h4>{RENTAL_ACCOUNT_LABELS.bankInfo.title}</h4>
                  <dl>
                    {' '}
                    <div className={'RentalAccount__bankinfo'}>
                      <dt>{RENTAL_ACCOUNT_LABELS.bankInfo.iban} </dt>
                      <dd>{selectedRentalAccount.detail.iban}</dd>
                    </div>
                    <div className={'RentalAccount__bankinfo'}>
                      {' '}
                      <dt>{RENTAL_ACCOUNT_LABELS.bankInfo.bic}</dt>
                      <dd>{selectedRentalAccount.detail.swift}</dd>
                    </div>
                  </dl>
                  <p className={'RentalAccount__p'}>{RENTAL_ACCOUNT_LABELS.bankInfo.info2}</p>
                </SubSection>
                {/*Section for cashmashine (Kassautomat) */}
                <SubSection
                  id={'rental-account-cash-machine'}
                  title={RENTAL_ACCOUNT_LABELS.cashMachine.title}
                  removePaddingTop
                >
                  <p className="RentalAccount__paragraph">{RENTAL_ACCOUNT_LABELS.cashMachine.descr}</p>
                  <p className="RentalAccount__paragraph">{RENTAL_ACCOUNT_LABELS.cashMachine.info}</p>
                </SubSection>
                {isVideoSeen && (
                  <SubSection id={'rental-explanation-video'} removePaddingTop>
                    <Accordion className={'RentalAccount__video--seen'} id={'rental-video'} name={'rental-video'}>
                      <AccordionItem
                        title={null}
                        titleDescription={RENTAL_ACCOUNT_LABELS.video.permanentVideoTitle}
                        icon={SVG.play}
                      >
                        <VideoPlayer
                          options={rentalVideoOptions}
                          onReady={handlePlayerReady}
                          onPlayThrough={() => setVideoAsSeen('rentalVideoSeen')}
                        />
                      </AccordionItem>
                    </Accordion>
                  </SubSection>
                )}
                <SubSection id={'rental-account-faq'} title={FAQ_LABELS.title} background>
                  <Accordion
                    activeTab={activeFAQTab}
                    onActiveTabChange={onAccordionTabChange}
                    id={'accordion-rental-account-faq-id'}
                    name={'faq'}
                    className={'RentalAccount__faq'}
                  >
                    <AccordionItem title={FAQ_LABELS.rental.accordionQandA[1].accordionTitle}>
                      {FAQ_LABELS.rental.accordionQandA[1].accordionBody}
                    </AccordionItem>
                    <AccordionItem title={FAQ_LABELS.rental.accordionQandA[2].accordionTitle}>
                      {FAQ_LABELS.rental.accordionQandA[2].accordionBody}
                    </AccordionItem>
                    {selectedRentalAccount && selectedRentalAccount.detail.balanceAmount > 0 ? (
                      <AccordionItem title={FAQ_LABELS.rental.accordionQandA[8].accordionTitle}>
                        {FAQ_LABELS.rental.accordionQandA[8].accordionBody}
                      </AccordionItem>
                    ) : (
                      <AccordionItem title={FAQ_LABELS.rental.accordionQandA[3].accordionTitle}>
                        {FAQ_LABELS.rental.accordionQandA[3].accordionBody}
                      </AccordionItem>
                    )}
                  </Accordion>
                </SubSection>
              </>
            ) : null}
          </>
        )}
      </Section>
    </>
  );
};

export default RentalAccount;

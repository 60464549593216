import { queryRentalAccount } from './Rental.graphql';
import { IExtendedError } from '../ExtendedError.interface';
import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { IRentalAccountsData } from './Rental.interface';
import { IGraphQL } from '../../interfaces/GraphQL.interface';
import AbstractRequestApi from '../AbstractRequestApi';

class RentalApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().rentalApi,
      'RentalApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  fetchRentalAccount(withDetails?: boolean): Promise<IGraphQL<IRentalAccountsData>> {
    return this.getRentalAccount(withDetails).catch((error: IExtendedError) => this.handleResponse(error));
  }

  private getRentalAccount(withDetails?: boolean): Promise<IGraphQL<IRentalAccountsData>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryRentalAccount(),
      'getRentalAccount',
      {
        withDetails,
      }
    );
  }
}

export default new RentalApi();

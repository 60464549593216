import React from 'react';
import { Badge, Button, Card, CardBody, SVG } from 'digit.commons.ui-components';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';
import { ANNOUNCEMENT } from '../../constants/components/announcement';
import { FORMATTER } from '../../utility/formatHandler';
import { LAUNDRY_DAY_BOOKING_ROUTE, LAUNDRY_DAY_CANCELLATION_ROUTE } from '../../constants/routes';
import { parseISO8601 } from '../../../commons/utility/DateFunctions';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';
import '../../../portal/components/Announcement/Announcement.scss';
import { useHistory } from 'react-router-dom';

interface IBrokenAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  showDetails?: boolean;
  onClose?: () => void;
}

const BrokenAnnouncement: React.FC<IBrokenAnnouncementProps> = ({ id, announcement, showDetails, onClose }) => {
  const { booking } = announcement && announcement.data;
  const history = useHistory();

  let heading: string;
  let info: string;

  const close = async () => {
    await announcement.closeFunction();
    onClose && onClose();
  };

  switch (announcement.type) {
    case 'DEFECT_DRYER':
      heading = ANNOUNCEMENT.broken.title.brokenDryer;
      info = ANNOUNCEMENT.broken.description.brokenDryer(booking.laundryAddress);
      break;
    case 'DEFECT_WASHER':
      heading = ANNOUNCEMENT.broken.title.brokenWasher;
      info = ANNOUNCEMENT.broken.description.brokenWasher(booking.laundryAddress);
      break;
    case 'DEFECT_BOTH':
      heading = ANNOUNCEMENT.broken.title.brokenWasherAndDryer;
      info = ANNOUNCEMENT.broken.description.brokenWasherAndDryer(booking.laundryAddress);
      break;
  }

  const onLaundryDayCancelClick = () => {
    history.push({
      pathname: LAUNDRY_DAY_CANCELLATION_ROUTE,
      state: { booking: booking, backRoute: LAUNDRY_DAY_BOOKING_ROUTE },
    });
  };

  return (
    announcement.data && (
      <>
        <Card
          id={`${id}-broken-announcement`}
          className="Announcement Announcement--broken"
          onDismiss={close}
          dismissDescribedById={`${id}-broken-announcement-header-id`}
        >
          <header id={`${id}-broken-announcement-header-id`}>
            <Badge id={`${id}-broken-announcement`} modifier="RED" iconBefore={SVG.laundry_locked}>
              {ANNOUNCEMENT.broken.badge}
            </Badge>
            <h3>{heading}</h3>
          </header>
          <CardBody>
            {showDetails ? (
              <>
                {ANNOUNCEMENT.broken.body.details(
                  info,
                  DATE_FORMATTER.formatDateTime(parseISO8601(booking.startingAt), 'dd., D. MMMM'),
                  FORMATTER.formatTimeSlotFromDates(
                    parseISO8601(booking.startingAt),
                    parseISO8601(booking.endingAt),
                    'HH.mm'
                  )
                )}
                <Button id={`${id}-broken-announcement`} modifier="secondary" onClick={onLaundryDayCancelClick}>
                  {ANNOUNCEMENT.actions.cancelReservation}
                </Button>{' '}
              </>
            ) : (
              ANNOUNCEMENT.broken.body.preview(id, info)
            )}
          </CardBody>
        </Card>
      </>
    )
  );
};

export default BrokenAnnouncement;

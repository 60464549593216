import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components';
import { NATUERLICH_SICHER_NUMBER } from '../general';

export const LAUNDRY_DAY_REMOTE_OPENING = {
  pageTitle: 'Tür fernöffnen  - Wiener Wohnen Mieter*innenportal',
  title: 'Tür fernöffnen',
  subtitle: 'Bitte geben Sie den Grund für die Türöffnung an',
  fieldsetSrOnly: 'Auswahl der Begründung für die Fernöffnung',
  description: (
    <>
      <p>
        Die Tür sollte innerhalb von 60 Sekunden geöffnet werden können. In dieser Zeit darf der elektronische Schlüssel
        nicht benutzt werden. Ist die Tür nach einer Minute noch nicht offen, probieren Sie es erneut oder rufen Sie bei
        der Servicenummer{' '}
        <Hyperlink id={'laundry-day-remote-opening'} to={`tel:${NATUERLICH_SICHER_NUMBER}`}>
          {NATUERLICH_SICHER_NUMBER}
        </Hyperlink>{' '}
        an.
      </p>
      <p>Es wird dabei nur die Tür geöffnet. Zur Aktivierung der Stromversorgung benötigen Sie Ihre Service-Karte.</p>
    </>
  ),
  cancel: 'Abbrechen',
  send: 'Senden',
  serviceError: {
    title: 'Türöffnung nicht möglich',
    text: 'Eine Türöffnung ist im Moment leider nicht möglich. Bitte probieren Sie es später noch einmal.',
    description: (
      <>
        {' '}
        Bei dringenden Fragen wenden Sie sich bitte an die naTÜRlich sicher Service-Nummer unter{' '}
        <Hyperlink id="laundry-day-remote-opening" to={`tel:${NATUERLICH_SICHER_NUMBER}`} customHref={true}>
          {NATUERLICH_SICHER_NUMBER}
        </Hyperlink>
      </>
    ),
    backLinkText: 'Zurück zur Wachtage-Übersicht',
  },
  linkToRemoteOpening: (linkTo: { pathname: string; state: any }, id: string) => (
    <p key={`${id}-remote-opening`}>
      Sie haben sich ausgesperrt? Dann können Sie die Tür auch{' '}
      <Hyperlink id={`${id}-remote-opening`} key={`${id}-remote-opening`} to={linkTo} iconAfter={SVG.arrow_right}>
        fernöffnen
      </Hyperlink>
    </p>
  ),
  notPossibleRightNow: (
    <>
      <p>
        <em>Sie haben bereits die Funktion zur Türöffnung verwendet.</em>
      </p>
      <p>
        Bitte haben Sie Geduld, die Öffnung der Tür kann bis zu einer Minute dauern. Nach dieser Zeit können Sie es
        erneut versuchen.
      </p>
    </>
  ),
};

export const LAUNDRY_DAY_REMOTE_OPENING_POLLING_EVENT_TYPE = 'laundryLanding-bookingResults';
export const LAUNDRY_DAY_REMOTE_OPENING_SENT_AT_LOCAL_STORAGE = 'laundryDayRemoteOpeningSentAt';

import { AuthStates, IAuthStateData, IDowntime } from './commons/api/authenticationState/AuthenticationState.interface';
import AuthenticationStateApi from './commons/api/authenticationState/AuthenticationState.api';
import { INVALID_CONTRACT, PRELANDING, REGISTRATION } from './portal/constants/routes';
import { isCookieSetCorrectly } from './portal/containers/CookieLayover/CookieLayover';

export class AuthenticationState {
  static isLoggedIn: boolean = false;
  static isOtfuLoggedIn: boolean = false;
  static isContractInvalid: boolean = false;
  static isInDowntime: boolean = false;
  static isAccessToPortalBlocked: boolean = false;
  static oneWeekBeforeDowntime: boolean = false;
  static downtime: IDowntime = null;
  static authRedirectUrl: string = PRELANDING;

  static async init(): Promise<any> {
    await AuthenticationStateApi.retrieveAuthenticationState()
      .then(response => {
        this.downtime = response.downtime;
        this.oneWeekBeforeDowntime = oneWeekBeforeDowntime(response);
        this.isInDowntime = currentlyInDowntime(response);

        if (!isCookieSetCorrectly()) {
          this.authRedirectUrl = PRELANDING;
        } else if (!this.oneWeekBeforeDowntime && response.downtime?.blockAccessToPortal) {
          this.isAccessToPortalBlocked = response.downtime?.blockAccessToPortal;
        } else {
          this.isLoggedIn = response.state === AuthStates.AUTHENTICATED;
          this.isOtfuLoggedIn = response.state === AuthStates.OTFU;
          this.isContractInvalid = response.state === AuthStates.INVALID_CONTRACT;
          switch (response.state) {
            case AuthStates.AUTHENTICATED:
              this.authRedirectUrl = '';
              break;
            case AuthStates.OTFU:
              this.authRedirectUrl = REGISTRATION;
              break;
            case AuthStates.INVALID_CONTRACT:
              this.authRedirectUrl = INVALID_CONTRACT;
              break;
            default:
              this.authRedirectUrl = PRELANDING;
          }
        }
      })
      .catch(_ => {
        this.isLoggedIn = false;
        this.isOtfuLoggedIn = false;
        this.isInDowntime = true;
        this.downtime = {
          blockAccessToPortal: true,
          startDate: 0,
          endDate: 9999999999999,
          reason:
            'Es kommt derzeit zu Störungen und Ausfällen, an der Behebung wird bereits mit Hochdruck gearbeitet. Bitte entschuldigen Sie die Unannehmlichkeiten.',
        } as IDowntime;
        this.authRedirectUrl = PRELANDING;
        this.isAccessToPortalBlocked = true;
      });
  }
}

export function oneWeekBeforeDowntime(authState: IAuthStateData): boolean {
  if (authState.downtime == null) return false;
  const now = Date.now();
  return now >= oneWeekBefore(authState.downtime.startDate) && now < authState.downtime.startDate;
}

export function currentlyInDowntime(authState: IAuthStateData): boolean {
  if (authState.downtime == null) return false;
  const now = Date.now();
  return now >= authState.downtime.startDate && now < authState.downtime.endDate;
}

function oneWeekBefore(date: number): number {
  return date - 1000 * 60 * 60 * 24 * 7;
}

export const stdpAuthenticationRequiredHandler = () => {
  AuthenticationState.isLoggedIn = false;
  window.location.href = PRELANDING;
};

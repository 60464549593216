import React from 'react';
import './BypassMenu.scss';
import { IBypassMenuProps } from './BypassMenu.interface';
import menuItems from './BypassMenu.json';

const BypassMenu = (props: IBypassMenuProps) => {
  const createMenu = () => {
    return menuItems.map(item => {
      return (
        <li className="BypassMenu__item" key={item.to}>
          <a onClick={item.triggerNav ? props.triggerNav : null} href={item.to}>
            {item.name}
          </a>
        </li>
      );
    });
  };

  return (
    <ul id="bypass-menu-id" className="BypassMenu">
      {createMenu()}
    </ul>
  );
};

export default BypassMenu;

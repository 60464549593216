import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components';
import { LAUNDRY_DAY_BASE_ROUTE, LAUNDRY_DAY_REMOTE_OPENING_ROUTE } from '../routes';
import { CUSTOMER_SERVICE_NUMBER } from '../general';

export const ANNOUNCEMENT = {
  actions: {
    cancelReservation: 'Reservierung stornieren',
    showDetails: 'Details anzeigen',
    closeInformation: 'Meldung schließen',
  },
  broken: {
    badge: 'Störungsmeldung',
    title: {
      brokenWasher: 'Waschmaschine defekt',
      brokenDryer: 'Trockner defekt',
      brokenWasherAndDryer: 'Waschgeräte defekt',
    },
    description: {
      brokenWasher: (address: string) =>
        `In der Waschkabine ${address} wurde eine defekte Waschmaschine gemeldet. Die Wartungsfirma ist bereits informiert.`,
      brokenDryer: (address: string) =>
        `In der Waschkabine ${address} wurde ein defekter Wäschetrockner gemeldet. Die Wartungsfirma ist bereits informiert.`,
      brokenWasherAndDryer: (address: string) =>
        `In der Waschkabine ${address} wurden Waschmaschine und Trockner als defekt gemeldet. Die Wartungsfirma ist bereits informiert.`,
    },
    body: {
      preview: (id: string, info: string): JSX.Element => (
        <>
          <p>{info}</p>
          <Hyperlink
            id={`${id}-laundryday-broken-announcement`}
            to={LAUNDRY_DAY_BASE_ROUTE}
            iconAfter={SVG.arrow_right}
          >
            Details anzeigen
          </Hyperlink>
        </>
      ),
      details: (info: string, date: JSX.Element, timeslot: JSX.Element): JSX.Element => (
        <>
          <p>{info}</p>
          <p>
            Sie haben aktuell folgenden Waschtag auf dieser Maschine reserviert: <em>{date},</em> {timeslot} Uhr
          </p>
        </>
      ),
    },
  },
  locked: {
    badge: 'Nutzungs-Sperre',
    title: 'Sie wurden gesperrt',
    body: {
      preview: (
        <>
          <p>Die Nutzung der Waschküche ist Ihnen im Moment nicht erlaubt.</p>
          <Hyperlink
            id="locked-announcement-laundryday-landing"
            to={LAUNDRY_DAY_BASE_ROUTE}
            iconAfter={SVG.arrow_right}
          >
            Details anzeigen
          </Hyperlink>
        </>
      ),
      details: (
        <>
          <p>
            Die Nutzung der Waschküche ist Ihnen im Moment nicht erlaubt. Sie können daher auch keine Waschtage
            reservieren. Bitte wenden Sie sich an die <em>Wiener Wohnen Service-Nummer</em> für nähere Informationen:
          </p>
          <Hyperlink id="locked-announcement-customer-number" to={`tel:${CUSTOMER_SERVICE_NUMBER}`} customHref={true}>
            {CUSTOMER_SERVICE_NUMBER}
          </Hyperlink>
        </>
      ),
    },
  },
  reminder: {
    badge: 'Erinnerung',
    title: {
      tomorrow: 'Morgen ist Waschtag',
      today: 'Heute ist Waschtag',
      now: 'Sie können jetzt waschen',
    },
    description: {
      cancelInfo:
        'Sollten Sie diesen Waschtag nicht benötigen, bitten wir Sie rechtzeitig zu stornieren, damit andere ihn nutzen können',
      todayInfo: (washCabinInfo: string, timeslot: string) =>
        `Die ${washCabinInfo} ist von ${timeslot} für Sie reserviert.`,
      nowInfo: (washCabinInfo: string, end: string) => `Die ${washCabinInfo} ist noch bis ${end} für Sie reserviert.`,
      accordionItemInfo: (washCabinInfo: string, address: string, timeslot: string) =>
        `${timeslot}, ${address}, ${washCabinInfo}`,
    },
    body: {
      details: {
        today: (address: string, timeSlot: JSX.Element): JSX.Element => (
          <p>
            Die Waschkabine {address !== null ? `"${address}"` : ''} ist von <em>{timeSlot}</em> für Sie reserviert.
          </p>
        ),
        now: (booking, timeEnd: JSX.Element): JSX.Element => (
          <p>
            Die Waschkabine {booking.laundryAddress !== null ? `"${booking.laundryAddress}"` : ''} ist{' '}
            <em>noch bis {timeEnd}</em> für Sie reserviert.
          </p>
        ),
      },
    },
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
};

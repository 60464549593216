export interface IAuthStateData {
  state: string;
  downtime: IDowntime;
}

export interface IDowntime {
  startDate: number; //Unix epoch time
  endDate: number; //Unix epoch time
  reason: string;
  blockAccessToPortal: boolean;
}

export enum AuthStates {
  OTFU = 'otfu',
  AUTHENTICATED = 'authenticated',
  INVALID_CONTRACT = 'invalid_contract',
}

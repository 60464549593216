import { formatArgumentsClause } from '../GraphQLHelper';

export const queryTenancyContracts = (gepartId?: string) => {
  const args = {
    gepartId: gepartId,
  };

  return `query {operationName} {
              tenancyContracts ${formatArgumentsClause(args)} {
                  gepartId
                  vkont
                  streetName
                  streetnumber
                  stairAndDoor
                  zipcode
                  intreno
                  contractBegin
                  validFrom
                  validTo
                  iban
                  swift
                  tplnr
                  rltitl
                  recnnr
                  xmbez
                  city
                  swenr
                  smenr
              }
            }`;
};

export const queryRentalObjectType = (gepartId?: string) => {
  const args = [
    {
      gepartId: gepartId,
    },
  ];
  return `query {operationName}  {
      tenancyContracts${formatArgumentsClause(args)}  {
            gepartId
            vkont
            xmbez
      }
    }`;
};

export const queryTenantNameAndFeatures = (gepartId?: string) => {
  const args = {
    gepartId: gepartId,
  };
  return `query {operationName} {
            tenant${formatArgumentsClause(args)} {
                details {
                    firstName
                    lastName
                    gender
                    academicTitle
                    gepartId
                    hb
                }
            }
            clientFeatures {
              path
              accessType
            }                
         }`;
};

export const queryTenantWithDetailsAndFeatures = (gepartId?: string) => {
  const args = {
    gepartId: gepartId,
  };
  return `
          query {operationName}{
            tenant${formatArgumentsClause(args)} {
              gepartId
              dateTermsAccepted
              details {
                  gepartId
                academicTitle
                firstName
                salutation
                lastName
                birthDate
                gender
                email
                faxNumber
                telNumberMobile
                telNumber
                streetName
                streetnumber
                stairAndDoor
                zipcode
                gepartTyp
                compRegNr
                taxNumber
                city
                hb
              }
            }
            clientFeatures {
                path
                accessType
            }
          }`;
};

export const queryTenantWithEmail = (gepartId?: string) => {
  const args = {
    gepartId: gepartId,
  };
  return `query {operationName} {
    tenant${formatArgumentsClause(args)} {
      gepartId
      details {
        email
      }
    }
  }`;
};

export const mutationUpdateContactData = (email: string, telNumber: string, telNumberMobile: string) => `
mutation {operationName} {
    updateContactData(contactData:
        {
          email: "${email}",
          telNumber: "${telNumber}",
          telNumberMobile: "${telNumberMobile}"
        }
    )
  }
`;

export const mutationTriggerChangeName = () => `
mutation {operationName} {
  triggerChangeName
}
`;

import React from 'react';
import { Hyperlink } from 'digit.commons.ui-components';
import { TENANT_PORTAL_SERVICE_NUMBER } from './component-labels';

const attempts = {
  1: (
    <p>
      Sie haben noch <em>2 weitere Versuche</em>, bevor ihr Zugang für <time dateTime="PT15M">15 Minuten</time> gesperrt
      wird.
    </p>
  ),
  2: (
    <p>
      Sie haben noch <em>1 weiteren Versuch</em>, bevor ihr Zugang für <time dateTime="PT15M">15 Minuten</time> gesperrt
      wird.
    </p>
  ),
};

export const ERRORBOX_MESSAGE = {
  errorOccured: 'Achtung',
  pinAndNumberDoNotMatch: (attempt: number) => {
    const attemptText = attempts[attempt];

    return (
      <>
        <p>Die Wiener Wohnen ID und der Wiener Wohnen PIN passen nicht zusammen.</p>
        <p>
          <em>Achtung:</em> Ihre Service-Karten-Nummer oder Kundennummer (z.B. auf Rechnungen) können nicht für die
          Registrierung verwendet werden. Achten Sie bitte darauf, dass Sie Ihre Wiener Wohnen ID und Ihren Wiener
          Wohnen PIN verwenden.
        </p>
        {attemptText}
      </>
    );
  },
  lockedForFifteenMinutes: (
    <>
      <p>
        Wiener Wohnen ID und PIN wurden 3 mal falsch eingegeben. Ihr Zugang zum Wiener Wohnen Portal wird zur Sicherheit{' '}
        <em>
          für <time dateTime="PT15M">15 Minuten</time> gesperrt.
        </em>
      </p>
      <p>
        Sollten Sie Ihre Wiener Wohnen ID oder Ihren PIN vergessen haben, rufen Sie uns bitte an unter{' '}
        <Hyperlink id={'contact-error-box'} customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
      </p>
    </>
  ),
};

export const FEEDBACK_ERROR = {
  submitError: {
    title: 'Hoppala!',
    description:
      'Dieser Service ist im Moment leider nicht erreichbar. Probieren Sie bitte später noch einmal Ihr Feedback abzusenden.',
  },
};

export const NOTIFICATION_MESSAGE = {
  changesSaved: 'Änderungen wurden gespeichert',
  changesNotSaved: 'Änderungen konnten leider nicht gespeichert werden',
};

export const FORM_VALIDATION_MESSAGE = {
  customerNumberError: 'Die Eingabe muss gültig sein',
  pinError: 'Die Eingabe muss gültig sein',
  emailError: 'Die Eingabe muss gültig sein',
  mobileError: 'Die Eingabe muss gültig sein',
  landlineError: 'Die Eingabe muss gültig sein',
  firstNameError: 'Die Eingabe muss gültig sein',
  lastNameError: 'Die Eingabe muss gültig sein',
  dateError: 'Kein gültiges Datum',
};

export const ERROR_PANEL = {
  tenantNotFound: 'Das Mieter*innenportal ist derzeit leider nicht erreichbar',
  tenantNotFoundDescription:
    'Leider kann zur Zeit nicht auf das Mieter*innenportal zugegriffen werden. Bitte versuchen Sie es später noch einmal. Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer.',
};

import React from 'react';
import { CUSTOMER_SERVICE_NUMBER } from '../component-labels';
import { Hyperlink } from 'digit.commons.ui-components';

export const INVALIDCONTRACT_LABELS = {
  pageTitle: 'Vertrag abgelaufen - Wiener Wohnen Mieter*innenportal',
  title: 'Vertrag abgelaufen',
  meta: 'Vertrag abgelaufen',
  errorPanel: {
    title: 'Ihr Mietvertrag ist abgelaufen',
    errorText:
      'Sie sind nicht mehr als Mieter*in bei Wiener Wohnen gemeldet und können daher nicht auf das Mieter*innenportal zugreifen.',
    descriptionText: (
      <>
        Bei dringenden Fragen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
        <Hyperlink id="invalid-contract" to={`tel: ${CUSTOMER_SERVICE_NUMBER}`} customHref={true}>
          {CUSTOMER_SERVICE_NUMBER}
        </Hyperlink>
        .
      </>
    ),
  },
};

import { IExtendedError } from '../ExtendedError.interface';
import { mutationCloseAnnouncement, queryAnnouncements } from './Announcement.graphql';
import { IAnnouncements } from './Announcement.interface';
import LaundryDaySCSInitializer from '../../../laundryday/LaundryDaySCSInitializer';
import AbstractRequestApi from '../AbstractRequestApi';
import { IGraphQL } from '../../interfaces/GraphQL.interface';

class LaundryDayAnnouncementApi extends AbstractRequestApi {
  constructor() {
    super(
      () => LaundryDaySCSInitializer.apiConfig().laundryDayApi,
      'LaundryDayAnnouncementApi',
      () => LaundryDaySCSInitializer.queryConfig(),
      () => LaundryDaySCSInitializer.authenticationRequiredHandler()
    );
  }

  fetchAnnouncements(): Promise<IGraphQL<IAnnouncements>> {
    return this.makeCacheableGraphQlRequest(
      LaundryDaySCSInitializer.apiConfig().paths.pathToGraphQL,
      queryAnnouncements(),
      'fetchAnnouncements'
    ).then(response => {
      response.data.getAnnouncements.forEach(it => (it.closeFunction = () => this.closeAnnouncement(it.id)));
      return {
        data: {
          announcements: response.data.getAnnouncements,
        },
        extensions: response.data.extensions,
      } as IGraphQL<IAnnouncements>;
    });
  }

  closeAnnouncement(id: string): Promise<boolean> {
    const query = {
      query: mutationCloseAnnouncement(id),
    };
    return this.postGraphQlRequest(LaundryDaySCSInitializer.apiConfig().paths.pathToGraphQL, 'closeAnnouncement', query)
      .then<boolean>((response: any) => response.data.closeAnnouncement)
      .catch((error: IExtendedError) => {
        this.handleResponse(error);
        return null;
      });
  }
}

export default new LaundryDayAnnouncementApi();

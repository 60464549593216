export const LANDING_LABELS = {
  title: 'Startseite',
  pageTitle: 'Wiener Wohnen Mieter*innenportal',
  meta: 'Startseite - Ihr persönliches Mieter*innenportal bei Wiener Wohnen',
  welcome: 'Willkommen',
  info: 'Ihre persönlichen Informationen und Services im Überblick',
  tiles: {
    bezirksnews: {
      title: 'Bezirksnews',
      text: ' Immer aktuell - die Wiener Wohnen Bezirksnews!',
      link: 'Jetzt anschauen!',
      href: 'https://www.wienerwohnen.at/bezirksnews',
    },
    rent: {
      title: 'Mietzinskonto',
      text: 'Der aktuelle Stand Ihres Mietzinskontos immer auf einen Blick.',
      link: 'Zum Mietzinskonto',
    },
    rentalPreview: {
      iconTitle: 'Erinnerung',
      title: 'Zahlungen sind fällig',
      description: 'Für folgende Mietobjekte sind noch Zahlungen fällig:',
      linkTitle: 'Zum Mietzinskonto',
    },
    complexInformation: {
      title: 'Hof-Informationen',
      text: 'Alle Informationen und Dokumente rund um Ihren Hof finden Sie hier.',
      link: 'Zu den Hof-Informationen',
    },
    laundry: {
      title: 'Waschtage',
      text: 'Reservieren Sie Ihren nächsten Waschtag in einer unserer Waschküchen.',
      link: 'Zu den Waschtagen',
    },
    applicationForm: {
      title: 'Anträge und Dokumente',
      text: 'Hier können Sie Dokumente herunterladen und online Anträge einreichen.',
      link: 'Zu Anträge und Dokumente',
    },
  },
};

import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import AbstractRequestApi from '../AbstractRequestApi';
import { IGraphQL } from '../../interfaces/GraphQL.interface';
import { IAnnouncements } from './Announcement.interface';
import { queryAnnouncements } from './Announcement.graphql';

class BuildingComplexAnnouncementApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().buildingComplexApi,
      'PipeAnnouncementApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }
}

export default new BuildingComplexAnnouncementApi();

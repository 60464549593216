import { Configuration } from '../../../Configuration';
import { IAuthStateData } from './AuthenticationState.interface';
import { Method } from '../ApiMethodEnum';
import AbstractRequestApi from '../AbstractRequestApi';

class AuthenticationStateApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().authenticationStateApi,
      'AuthenticationStateApi',
      () => Configuration.queryConfig()
    );
  }

  async retrieveAuthenticationState(): Promise<IAuthStateData> {
    try {
      return this._makeRequest(Configuration.apiConfig().paths.pathToStateCheck, Method.GET);
    } catch (error) {
      this.handleResponse(error);
    }
  }
}

export default new AuthenticationStateApi();

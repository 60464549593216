export const WHATS_NEW_DETAILS_LABELS = {
  meta: 'Neuigkeiten - Ihr persönliches Mieter*innenportal bei Wiener Wohnen',
  pageTitle: 'Neuigkeiten',
  backLink: 'Zurück zur Startseite',
};

export const WHATS_NEW_ANNOUNCEMENT_LABELS = {
  badge: 'Neuigkeiten',
  history: 'Alle Neuigkeiten anzeigen',
  acknowledged: 'Alles klar!',
};

export const WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS = {
  category: 'technischer Fehler',
  action: 'Neuigkeiten',
  fetchWhatsNewContent: 'Fehler beim Laden der Whats New Feature Liste',
  acknowledgeWhatsNewContent: 'Fehler bei der Bestätigung die Whats New Feature Liste gesehen zu haben',
};

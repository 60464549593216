import React, { Component, Fragment } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  CurrencyAmount,
  Data,
  Hyperlink,
  Icon,
  Key,
  SVG,
  Value,
} from 'digit.commons.ui-components';

import { IRentalAccountDetail } from '../../../../commons/api/rental/Rental.interface';

import { RENTAL_ACCOUNT_LABELS } from '../../../constants/containers/rental-account-labels';
import { FORMATTER } from '../../../utility/formatHandler';
import { newMoment } from '../../../../commons/utility/DateFunctions';
import { DATE_FORMATTER } from '../../../../commons/utility/DateFormatter';
import { INFORMATION_RENT_ARREARS_SUPPORT } from '../../../constants/links';

class RentalAccountDetail extends Component<IRentalAccountDetail, {}> {
  render() {
    const {
      vKontLbl,
      gepartIdLbl,
      showBankInfoLbl,
      depositeWithcheckLbl,
      residentialHelpLbl,
      onLbl,
      whathappensLbl,
      balanceAmountLbl,
      billCurrentMonthLbl,
      billNextMonthLbl,
      lastConsideredPaymentLbl,
      rentArrearsSupportLabel,
    } = RENTAL_ACCOUNT_LABELS.details;

    const {
      vKont,
      gepartId,
      balanceAmount,
      balanceAmount2,
      balanceAmount2Date,
      lastPaymentBudat,
      lastPaymentAmount,
      lastAidBudat,
      lastAidAmount,
      usageTypeText,
      onWhatHappensLinkClick,
    } = this.props;

    const currentDate = newMoment();

    let amountDescription: any = (
      <Fragment>
        <Icon icon={SVG.green_smiley} className={'RentalAccount__feedback-icon'} /> {balanceAmountLbl.noPaymentsToDo}
      </Fragment>
    );
    // if the amount from backend is greater 0 update text
    if (balanceAmount < 0) {
      amountDescription = balanceAmountLbl.paytoday;
    }
    // if the amount from backend is smaller 0 update text
    if (balanceAmount > 0) {
      amountDescription = balanceAmountLbl.paymentcredit;
    }

    if (this.props.vKont) {
      return (
        <article className={'RentalAccount__object'}>
          <div className={'RentalAccount__contract'}>
            <Data id="rental-account-details-contract" className="RentalAccount__contract-data">
              <Key>{vKontLbl}</Key>
              <Value isNumber={true}>{vKont}</Value>
            </Data>
            <Data id="rental-account-details-contract-gepart" className="RentalAccount__contract-data">
              <Key>{gepartIdLbl}</Key>
              <Value isNumber={true}>{gepartId}</Value>
            </Data>
          </div>
          {/*Card for Balance Amount */}
          <Card id={'balance-amount'}>
            <CardHeader title={balanceAmountLbl.title} />
            <CardBody>
              <p className="RentalAccount__paragraph">{balanceAmountLbl.descr}</p>
              <CurrencyAmount amount={balanceAmount} amountDescription={amountDescription} />
              {/*Display of the Amount Description, amountDescription gets set in the If Statements where the Amount gets manipulated  */}
              <span
                aria-hidden={true}
                className={`RentalAccount__feedback
                              ${balanceAmount === 0 ? 'RentalAccount__feedback--green' : ''}
                              ${balanceAmount !== 0 ? 'RentalAccount__feedback--big' : ''}
                              `}
              >
                {amountDescription}
              </span>

              {/*Display last Update and if the Amount is not 0 display a Link for Bankinformation*/}
              <div className={'RentalAccount__pending-container'}>
                <Data id="rental-account-details-update" className="RentalAccount__update">
                  <div className="RentalAccount__update-icon" aria-hidden={true}>
                    <Icon icon={SVG.calendar} />
                  </div>
                  <div className="RentalAccount__update-content">
                    <Key>{balanceAmountLbl.lastUpdateLbl}</Key>
                    <Value>{DATE_FORMATTER.dateFormat(currentDate.valueOf())}</Value>
                  </div>
                </Data>

                {balanceAmount < 0 && (
                  <Hyperlink
                    className="RentalAccount__link"
                    customHref={true}
                    iconBefore={SVG.arrow_down}
                    id={'rental-account-details-bankinfo'}
                    to={'#rental-account-bankinfo-sub-section-id'}
                  >
                    {showBankInfoLbl}
                  </Hyperlink>
                )}

                {balanceAmount > 0 && (
                  <Hyperlink
                    className="RentalAccount__link"
                    iconAfter={SVG.arrow_right}
                    id={'rental-account-details-accordion'}
                    to="#rental-account-faq-sub-section-id"
                    onClick={onWhatHappensLinkClick}
                  >
                    {whathappensLbl}
                  </Hyperlink>
                )}
              </div>
              {balanceAmount < 0 && (
                <div className={'RentalAccount__adjustment'}>
                  {RENTAL_ACCOUNT_LABELS.details.balanceAmountLbl.adjustmentsDescription}
                </div>
              )}

              {balanceAmount < 0 && usageTypeText === 'Wohnung' && (
                <div className="RentalAccount__hyperlink-box">
                  Aktuelle Informationen zur Hilfe bei Mietzinsrückstand und Hinweise zu den städtischen
                  Hilfseinrichtungen finden Sie hier:{' '}
                  <Hyperlink
                    id={'rental-account-details-support'}
                    to={INFORMATION_RENT_ARREARS_SUPPORT}
                    isExternal={true}
                    iconAfter={SVG.external_link}
                  >
                    {rentArrearsSupportLabel}
                  </Hyperlink>
                </div>
              )}
            </CardBody>
          </Card>
          {/*Card for the Bill of next month - shown between 22th and 4th of month with duedate at 5th,
            in the first 4 days of a month we show the balanceAmount2 as is whould be due in current month (KUN-3807) */}
          {balanceAmount2 !== 0 && (currentDate.date() >= 22 || currentDate.date() <= 4) ? (
            currentDate.date() >= 1 && currentDate.date() <= 4 ? (
              <Card id={'rental-account-details-bill-next-month'}>
                <CardHeader title={billCurrentMonthLbl.title} />
                <CardBody>
                  <p className="RentalAccount__paymentdescr">{billCurrentMonthLbl.descr}</p>
                  <Data
                    id="rental-account-details-payment-next-month"
                    className={'RentalAccount__Payment RentalAccount__Payment--big'}
                  >
                    <Key>
                      {balanceAmount2 > 0 ? billCurrentMonthLbl.yourBalance : billCurrentMonthLbl.payUntil}{' '}
                      {DATE_FORMATTER.dateFormat(newMoment(balanceAmount2Date).date(5).subtract(1, 'months').valueOf())}
                    </Key>
                    <Value>
                      <strong>{balanceAmount2.toLocaleString('de-de')}€</strong>
                    </Value>
                  </Data>
                </CardBody>
              </Card>
            ) : (
              <Card id={'rental-account-details-bill-next-month'}>
                <CardHeader title={billNextMonthLbl.title} />
                <CardBody>
                  <p className="RentalAccount__paymentdescr">{billNextMonthLbl.descr}</p>
                  <Data
                    id="rental-account-details-payment-next-month"
                    className={'RentalAccount__Payment RentalAccount__Payment--big'}
                  >
                    <Key>
                      {balanceAmount2 > 0 ? billNextMonthLbl.yourBalance : billNextMonthLbl.payUntil}{' '}
                      {DATE_FORMATTER.dateFormat(newMoment(balanceAmount2Date).date(5).valueOf())}
                    </Key>
                    <Value>
                      <strong>{balanceAmount2.toLocaleString('de-de')}€</strong>
                    </Value>
                  </Data>
                </CardBody>
              </Card>
            )
          ) : null}
          {/*Card for the Payments that are paid, will only be displayed if there are any payments*/}
          {lastPaymentBudat || lastAidBudat ? (
            <Card className="RentalAccount__last-payment-wrapper" id={'rental-account-details-last-payment'}>
              <CardHeader title={lastConsideredPaymentLbl.title} />
              <CardBody>
                {lastPaymentBudat && (
                  <Data
                    id="rental-account-details-payment-last-paid"
                    className={'RentalAccount__Payment RentalAccount__Payment--small'}
                  >
                    <Key>
                      {depositeWithcheckLbl} {this.props.lastPaymentTypeDesc} {onLbl}{' '}
                      {DATE_FORMATTER.dateFormat(lastPaymentBudat)}
                    </Key>
                    <Value>
                      <strong>{FORMATTER.amountFormat(lastPaymentAmount)}</strong>
                    </Value>
                  </Data>
                )}
                {lastAidBudat && (
                  <Data
                    id="rental-account-details-payment-aid"
                    className={'RentalAccount__Payment RentalAccount__Payment--small'}
                  >
                    <Key>
                      {this.props.lastAidTypeDesc} {residentialHelpLbl} {DATE_FORMATTER.dateFormat(lastAidBudat)}
                    </Key>
                    <Value>
                      <strong>{FORMATTER.amountFormat(lastAidAmount)}</strong>
                    </Value>
                  </Data>
                )}
              </CardBody>
            </Card>
          ) : null}
        </article>
      );
    } else {
      return '';
    }
  }
}

export default RentalAccountDetail;

import React from 'react';
import ReactDOM from 'react-dom';
import './commons/styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Configuration } from './Configuration';
import { BrowserRouter } from 'react-router-dom';
import { AuthenticationState, stdpAuthenticationRequiredHandler } from './AuthenticationState';
import smoothscroll from 'smoothscroll-polyfill';
import 'digit.commons.ui-components/dist/index.css';
import SmartBanner from 'react-smartbanner';
import './smartbanner.css';
import ApplicationFormSCSInitializer from './applicationform/ApplicationFormSCSInitializer';
import LaundryDaySCSInitializer from './laundryday/LaundryDaySCSInitializer';
import DocumentArchiveSCSInitializer from './documentarchive/DocumentArchiveSCSInitializer';

// enable smooth scrolling for Edge/IE and Safari and Safari iOS
if (/Edge|AppleWebKit|IE/.test(navigator.userAgent)) {
  smoothscroll.polyfill();
}

Configuration.init()
  .then(() =>
    Promise.all([
      ApplicationFormSCSInitializer.init(Configuration.applicationFormConfig(), stdpAuthenticationRequiredHandler),
      LaundryDaySCSInitializer.init(Configuration.laundryDayConfig(), stdpAuthenticationRequiredHandler),
      DocumentArchiveSCSInitializer.init(Configuration.documentArchiveConfig(), stdpAuthenticationRequiredHandler),
    ])
  )
  .then(() => AuthenticationState.init())
  .then(() => {
    ReactDOM.render(
      <>
        <SmartBanner
          title={'Stadt Wien - Wiener Wohnen'}
          storeText={{
            ios: 'Wiener Wohnen',
            android: 'Wiener Wohnen',
            windows: 'Wiener Wohnen',
            kindle: 'Wiener Wohnen',
          }}
          price={{ ios: 'Stadt Wien', android: 'Stadt Wien', windows: 'Stadt Wien', kindle: 'Stadt Wien' }}
          button={'Öffnen'}
        />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </>,
      document.getElementById('root')
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import { CONTACT, COOKIE_SETTINGS, DATA_PROTECTION, FAQ, IMPRESSUM, WHATS_NEW } from '../../constants/routes';

export const footerMenu = (isLoggedIn: boolean) => {
  const items = [
    {
      name: 'Impressum',
      to: IMPRESSUM,
      id: 'link-to-imprint',
    },
    {
      name: 'Datenschutz',
      to: DATA_PROTECTION,
      id: 'link-to-dataprotection',
    },
    {
      name: 'Cookie-Einstellungen',
      to: COOKIE_SETTINGS,
      id: 'link-to-cookie-settings',
    },
    {
      name: 'Häufige Fragen',
      to: FAQ,
      id: 'link-to-faq',
    },
    {
      name: 'Kontakt',
      to: CONTACT,
      id: 'link-to-contakt',
    },
  ];

  if (isLoggedIn) {
    items.push({
      name: 'Neuigkeiten',
      to: WHATS_NEW,
      id: 'link-to-whats-new',
    });
  }

  return items;
};

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ERROR_BOUNDARY, PAGE_TITLE_APP } from '../../constants/component-labels';
import { ErrorPanel, Section } from 'digit.commons.ui-components';
import * as Sentry from '@sentry/react';

export interface IErrorBoundaryState {
  hasError: boolean;
  type: number;
}

/*
Why don't we use hooks in error Boundary?

Currently, there is no equivalent to componentDidCatch and getDerivedStateFormError, as state here
https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes

As of August 2020, there are also no timeframes for substitutes for the API
https://github.com/facebook/react/issues/19630#issuecomment-675390931
 */
class ErrorBoundary extends Component<any, IErrorBoundaryState> {
  state: IErrorBoundaryState = { hasError: false, type: 0 };

  constructor(props) {
    super(props);
    const { history } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  static getDerivedStateFromError(type) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, type: Number(type.message) };
  }

  componentDidCatch = type => {
    const syntheticError = new Error(type.message);
    syntheticError.name = 'Uncaught error caught by ErrorBoundary';
    syntheticError.stack = type.stack;
    Sentry.captureException(syntheticError);
    // You can also log the error to an error reporting service
    this.setState({ hasError: true, type: Number(type.message) });
  };

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Section id={'error-boundary'} title={PAGE_TITLE_APP}>
          <ErrorPanel
            id={'error-boundary'}
            title={ERROR_BOUNDARY.title}
            errorText={ERROR_BOUNDARY.errorText}
            descriptionText={ERROR_BOUNDARY.description}
          />
        </Section>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);

import React from 'react';
import { newMoment } from '../../commons/utility/DateFunctions';

class DateFormatter {
  formatTime(date: number, displayFormat: string): JSX.Element {
    if (date === null) {
      return <></>;
    }
    let moment = newMoment(date);
    return <time dateTime={moment.format('HH:mm')}>{moment.format(displayFormat)}</time>;
  }

  formatDateTime(date: number, displayFormat: string): JSX.Element {
    if (date === null) {
      return <></>;
    }
    let moment = newMoment(date);
    return <time dateTime={moment.format('YYYY-M-D')}>{moment.format(displayFormat)}</time>;
  }

  dateFormat(date: number): string | JSX.Element {
    return date === null ? '' : this.formatDateTime(date, 'DD.MM.YYYY');
  }

  monthDateFormat(date: number): string | JSX.Element {
    return date === null ? '' : this.formatDateTime(date, 'MM.YYYY');
  }
}

export const DATE_FORMATTER = new DateFormatter();

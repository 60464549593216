export const queryRentalAccount = (): string => {
  return `query {operationName}($withDetails : Boolean!) {
          rentalAccounts {
            vKont 
            rentalObjectText 
            validTo
            overdue
            detail @include(if: $withDetails) {
              gepartId
              iban
              swift
              usageTypeText
              vKont
              openAmount
              balanceAmount
              balanceAmountDate
              balanceAmount2
              balanceAmount2Date
              lastPaymentAmount
              lastPaymentBudat
              lastPaymentType
              lastPaymentTypeDesc
              lastAidAmount
              lastAidBudat
              lastAidType
              lastAidTypeDesc
            }
          }
        }`;
};

import * as Cookies from 'js-cookie';

export const setVideoAsSeen = (videoCookieName: string, setSeenCallback?: (boolean) => void) => {
  Cookies.set(videoCookieName, 'true', { expires: 10 * 365, sameSite: 'strict' });
  if (setSeenCallback) setSeenCallback(true);
};

export const checkVideoSeen = (videoCookieName: string): boolean => {
  return Cookies.get(videoCookieName) === 'true';
};

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import './FeedbackStars.scss';

import { Icon, SVG } from 'digit.commons.ui-components';
import { FEEDBACK_STARS } from '../../../../constants/containers/feedback-labels';

interface IFeedbackStarsProps {
  id: string;
  className?: string;
  count?: number;
  onChange?: any;
  onClick?: any;
  isStarRatingEmpty?: any;
  name?: string;
  onKeyDown?: React.KeyboardEventHandler;
  question?: string;
  ref?: any;
}

export interface IFeedbackStarsHandles {
  setChosenState: (chosenValue: number) => void;
  scrollIntoViewAndFocus: () => void;
  resetState: () => void;
}

const FeedbackStars: React.ForwardRefRenderFunction<IFeedbackStarsHandles, IFeedbackStarsProps> = (props, ref) => {
  const [highlightUpTo, setHighlightUpTo] = useState<number>(0);
  const [chosenValue, setChosenValue] = useState<number>(0);

  const legendRef = useRef<HTMLLegendElement>(null);
  const firstInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    setChosenState: (chosenValue: number) => {
      setHighlightUpTo(chosenValue);
      setChosenValue(chosenValue);
    },
    scrollIntoViewAndFocus: () => {
      legendRef.current.scrollIntoView();
      firstInputRef.current.focus();
      window.scrollBy(0, -50);
    },
    resetState: () => reset(),
  }));

  const onCheckedHandler = (event: any) => {
    setHighlightUpTo(event.currentTarget.value);
    /**
     * this is needed so the feedback can be reset after closing it
     * only invoke click method when the value has changed - so it basically works like onChange
     */
    if (chosenValue !== event.currentTarget.value && props.onClick) {
      props.onClick(event);
    }
    setHighlightUpTo(event.currentTarget.value);
    setChosenValue(event.currentTarget.value);
  };

  const isChecked = (value: number) => value === chosenValue;

  const highlightHandler = (event: any, currentStar: number) => {
    setHighlightUpTo(currentStar);
  };

  const reset = () => {
    if (highlightUpTo !== chosenValue) {
      setHighlightUpTo(chosenValue);
    }
  };

  const renderHelperStars = () => {
    return (
      <div className={'FeedbackStars__stars FeedbackStars__stars--flex'} onMouseLeave={reset}>
        <input
          id={`${props.id}-one`}
          name={props.name}
          className={'sr-only'}
          type={'radio'}
          value={1}
          checked={isChecked(1)}
          aria-describedby={`legend-${props.id}`}
          onClick={onCheckedHandler}
          onChange={onCheckedHandler}
          ref={firstInputRef}
        />
        <label
          className={'FeedbackStars__label'}
          htmlFor={`${props.id}-one`}
          onMouseEnter={e => highlightHandler(e, 1)}
        >
          {highlightUpTo >= 1 ? <Icon icon={SVG.star_filled} /> : <Icon icon={SVG.star_empty} />}
          <span className={'sr-only'}>1 Stern</span>
        </label>
        <input
          id={`${props.id}-two`}
          name={props.name}
          className={'sr-only'}
          type={'radio'}
          value={2}
          checked={isChecked(2)}
          aria-describedby={`legend-${props.id}`}
          onClick={onCheckedHandler}
          onChange={onCheckedHandler}
        />
        <label
          className={'FeedbackStars__label'}
          htmlFor={`${props.id}-two`}
          onMouseEnter={e => highlightHandler(e, 2)}
        >
          {highlightUpTo >= 2 ? <Icon icon={SVG.star_filled} /> : <Icon icon={SVG.star_empty} />}
          <span className={'sr-only'}>2 Sterne</span>
        </label>

        <input
          id={`${props.id}-three`}
          name={props.name}
          className={'sr-only'}
          type={'radio'}
          value={3}
          checked={isChecked(3)}
          aria-describedby={`legend-${props.id}`}
          onClick={onCheckedHandler}
          onChange={onCheckedHandler}
        />
        <label
          className={'FeedbackStars__label'}
          htmlFor={`${props.id}-three`}
          onMouseEnter={e => highlightHandler(e, 3)}
        >
          {highlightUpTo >= 3 ? <Icon icon={SVG.star_filled} /> : <Icon icon={SVG.star_empty} />}
          <span className={'sr-only'}>3 Sterne</span>
        </label>
        <input
          id={`${props.id}-four`}
          name={props.name}
          className={'sr-only'}
          type={'radio'}
          value={4}
          checked={isChecked(4)}
          aria-describedby={`legend-${props.id}`}
          onClick={onCheckedHandler}
          onChange={onCheckedHandler}
        />
        <label
          className={'FeedbackStars__label'}
          htmlFor={`${props.id}-four`}
          onMouseEnter={e => highlightHandler(e, 4)}
        >
          {highlightUpTo >= 4 ? <Icon icon={SVG.star_filled} /> : <Icon icon={SVG.star_empty} />}
          <span className={'sr-only'}>4 Sterne</span>
        </label>
        <input
          id={`${props.id}-five`}
          name={props.name}
          className={'sr-only'}
          type={'radio'}
          value={5}
          checked={isChecked(5)}
          aria-describedby={`legend-${props.id}`}
          onClick={onCheckedHandler}
          onChange={onCheckedHandler}
        />
        <label
          className={'FeedbackStars__label'}
          htmlFor={`${props.id}-five`}
          onMouseEnter={e => highlightHandler(e, 5)}
        >
          {highlightUpTo >= 5 ? <Icon icon={SVG.star_filled} /> : <Icon icon={SVG.star_empty} />}
          <span className={'sr-only'}>5 Sterne</span>
        </label>
      </div>
    );
  };

  return (
    <div className={`FeedbackStars ${props.className}`}>
      <fieldset className={'FeedbackStars__group'}>
        <legend id={`legend-${props.id}`} className={'FeedbackStars__legend'} onMouseOver={reset} ref={legendRef}>
          {props.question}
        </legend>
        {renderHelperStars()}
      </fieldset>
      {props.isStarRatingEmpty && (
        <div className={`FeedbackStars__error`} role={'alert'}>
          {FEEDBACK_STARS.emptyError}
        </div>
      )}
    </div>
  );
};

export default forwardRef(FeedbackStars);

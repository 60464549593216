export const LANDING = '/';
export const LAUNDRY_DAY = '/waschtage';
export const REGISTRATION = '/registrierungwienerwohnen';
export const REGISTRATION_CONFIRMATION = '/registrierungwienerwohnen/stadtwienerstellt';
export const NEW_MDM = '/mdm-anfordern';
export const NEW_PIN = '/pin-anfordern';
export const PROFILE = '/profil';
export const PROFILE_CHANGE_NAME = '/profil/name-ändern';
export const PROFILE_CHANGE_NAME_CONFIRMATION = '/profil/name-ändern/bestätigung';
export const RENTAL_ACCOUNT = '/mietzinskonto';
export const COMPLEX_INFORMATION = '/hof-informationen';
export const COMPLEX_INFORMATION_DETAILS = '/hof-informationen/details';
export const IMPRESSUM = '/impressum';
export const DATA_PROTECTION = '/datenschutz';
export const DATA_PROTECTION_APP = '/datenschutz-app';
export const COOKIE_SETTINGS = '/cookie-einstellungen';
export const CONTACT = '/kontakt';
export const FAQ = '/fragen';
export const PRELANDING = '/anmelden';
export const REQUESTS_AND_DOCUMENTS = '/dokumente';
export const DOCUMENT_ARCHIVE = '/dokumentenarchiv';
export const LOGIN_REDIRECT = '/loginredirect';
export const INVALID_CONTRACT = '/abgelaufen';
export const WHATS_NEW = '/neuigkeiten';

import { Hyperlink, SVG } from 'digit.commons.ui-components';
import { CONTACT } from '../routes';
import React from 'react';
import { SERVICECARD_LINK } from '../links';
import { TENANT_PORTAL_SERVICE_NUMBER } from '../component-labels';

export const RENTAL_ACCOUNT_LABELS = {
  title: 'Mietzinskonto',
  pageTitle: 'Mietzinskonto - Wiener Wohnen Mieter*innenportal',
  meta: 'Mietzinskonto - Alle Informationen zum Thema Mietzins auf einem Blick',
  dropDown: { label: 'Mietobjekt', errorMessage: 'Bitte wählen Sie ein Mietobjekt aus' },
  cashMachine: {
    title: 'Barzahlung am Kassenautomaten',
    descr: 'Einzahlungen beim Kassenautomaten werden sofort im Mietzinskonto berücksichtigt.',
    info: (
      <>
        Während der{' '}
        <Hyperlink id="rental-account-cash-machine-opening-hours" to={CONTACT}>
          regulären Öffnungszeiten
        </Hyperlink>{' '}
        des Service-Centers können Sie alle 4 Kassenautomaten verwenden. Außerhalb dieser Zeiten brauchen Sie Ihre{' '}
        <Hyperlink
          id="rental-account-cash-machine-service-card"
          isExternal={true}
          iconAfter={SVG.external_link}
          to={SERVICECARD_LINK}
        >
          Service-Karte
        </Hyperlink>
        , um Zutritt zum Gebäude zu bekommen. Einer der Kassenautomaten steht Ihnen Montag - Sonntag,{' '}
        <time dateTime="PT24H">24 Stunden</time>
        am Tag zur Verfügung.
      </>
    ),
  },
  generalErrorPanel: {
    title: 'Oh nein!',
    text: 'Leider kann zur Zeit nicht auf Ihre Daten zugegriffen werden. Bitte versuchen Sie es später noch einmal.',
    description: (
      <>
        {' '}
        Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
        <Hyperlink id="rental-account-service-number" to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
        .
      </>
    ),
  },
  noActiveContractErrorPanel: {
    title: 'Oh nein!',
    text: 'Es konnten keine aktiven Mietverhältnisse gefunden werden.',
    description: (
      <>
        {' '}
        Bei dringenden Fragen oder Problemen wenden Sie sich bitte an unsere Service-Nummer unter{' '}
        <Hyperlink id="rental-account-service-number" to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`} customHref={true}>
          {TENANT_PORTAL_SERVICE_NUMBER}
        </Hyperlink>
        .
      </>
    ),
  },
  bankInfo: {
    title: 'Bankverbindung Wiener Wohnen',
    bic: 'BIC',
    iban: 'IBAN',
    rentalObject: 'Mietobjekt:',
    vkont: 'Vertragskontonummer:',
    info: 'Die Bankverbindung unterscheidet sich je Mietobjekt. Überweisen Sie Ihre Miete für dieses Objekt bitte an',
    info2:
      'Die Zahlungsreferenz finden Sie auf Ihrem Zahlschein bzw. sollten Sie keinen Zahlschein haben, verwenden Sie bitte ihr Vertragskontonummer als Zahlungsreferenz.',
  },
  details: {
    vKontLbl: 'Vertragskontonummer',
    gepartIdLbl: 'Kundennummer',
    showBankInfoLbl: 'Bankverbindung anzeigen',
    whathappensLbl: 'Was passiert mit dem Guthaben?',
    onLbl: ' am ',
    depositeWithcheckLbl: 'Einzahlung per ',
    residentialHelpLbl: ' eingelangt am ',
    balanceAmountLbl: {
      title: 'Aktueller Stand',
      descr: 'Überweisungen und Einzahlungen bei der Stadthauptkasse der letzten 3 Werktage sind nicht berücksichtigt',
      paytoday: 'heute zu bezahlen',
      paymentcredit: 'Guthaben',
      noPaymentsToDo: 'Sie haben aktuell keine zu bezahlenden Beträge',
      lastUpdateLbl: 'Letzte Aktualisierung:',
      helpAid: 'Hilfe bei finanziellen Schwierigkeiten',
      adjustmentsDescription: (
        <>
          <p>
            Bitte beachten Sie, dass Ihr aktueller Stand bereits inklusive der Betriebskosten-Anpassung für 2023
            angezeigt wird. Da es sich um eine rückwirkende Adaptierung handelt, liegt die Fälligkeit in der
            Vergangenheit, weshalb auch ein „Rückstand“ aufscheint.
          </p>
          <p>
            Eine detaillierte Information der Betriebskonto-Anpassung für das Jahr 2023 erfolgt zusammen mit dem Versand
            der Mai Vorschreibung im April.
          </p>
        </>
      ),
    },
    billCurrentMonthLbl: {
      title: 'Rechnung aktueller Monat',
      descr:
        'Diese setzt sich aus dem Gesamtzins und zusätzlichen Kosten (z.B. Waschküchenkosten, Spesen, Instandsetzungskosten oder Entrümperlungskosten) zusammen',
      payUntil: 'Zu bezahlen bis ',
      yourBalance: 'Ihr Guthaben beträgt ',
    },
    billNextMonthLbl: {
      title: 'Rechnung nächster Monat',
      descr:
        'Diese setzt sich aus dem Gesamtzins und zusätzlichen Kosten (z.B. Waschküchenkosten, Spesen, Instandsetzungskosten oder Entrümperlungskosten) zusammen',
      payUntil: 'Zu bezahlen bis ',
      yourBalance: 'Ihr Guthaben beträgt ',
    },
    lastConsideredPaymentLbl: { title: 'Bei Wiener Wohnen eingelangt' },
    rentArrearsSupportLabel: 'Hilfe bei Mietzinsrückstand',
  },
  documents: {
    rent: {
      title: 'Mietzinskonto-Informationen',
      description: 'Eine Zusammenfassung zum aktuellen Stand Ihres Mietzinkontos',
      linkText: 'Mietzinskonto-Information',
    },
    interestInformation: {
      title: 'Zinszettel',
      description: 'Zeigt Ihnen alle Beträge aus denen sich Ihre Miete zusammensetzt',
      linkText: 'Details meiner monatlichen Miete (Zinszettel)',
    },
  },
  video: {
    badgeText: 'Aufgepasst!',
    title: 'Sie haben Fragen zu Ihrem Mietzins?',
    subtitle: '"Mein Mietzins" - Informationen zu Hauptmietzins & Betriebskosten',
    permanentVideoTitle: '"Mein Mietzins" - Video',
  },
};

export const RENTAL_OBJECT_ICON = {
  Wohnung: SVG.rental_object_flat,
  Parkplatz: SVG.rental_object_car_parking,
  Lokal: SVG.rental_object_restaurant,
  Magazin: SVG.rental_object_magazin,
};

import React from 'react';
import { Hyperlink, SVG } from 'digit.commons.ui-components';
import {
  COMPLEX_INFORMATION,
  CONTACT,
  DATA_PROTECTION,
  LAUNDRY_DAY,
  NEW_MDM,
  NEW_PIN,
  PROFILE,
  RENTAL_ACCOUNT,
} from '../routes';
import {
  AVAILABLE_BIKE_BOX_INFO_LINK,
  AVAILABLE_FLATS_INFO_LINK,
  AVAILABLE_PARKING_INFO_LINK,
  AVAILABLE_RESTAURANT_INFO_LINK,
  AVAILABLE_RESTAURANT_SEARCH_LINK,
  BUILDINGCOMPLEX_DESCRIPTION_LINK,
  DIRECT_ALLOCATION_LINK,
  DIRECT_DEBIT_LINK,
  EMPTY_DIRECT_DEBIT_LINK,
  FLAT_TRANSFER_LINK,
  FLATSWAP_LINK,
  FORGOT_PASSWORD_LINK,
  HOUSE_RULES_LINK,
  HOUSINGBENEFITS_LINK,
  INTERESTED_PERSON_LINK,
  MEIN_WIEN_LINK,
  OPERATING_COST_LAW_LINK,
  OPERATING_COSTS_LINK,
  PRECONDITIONS_FLATSWAP_LINK,
  SERVICECARD_REGISTER_LINK,
  UTILITY_BILL_LINK,
  WIEN_LINK,
  WOHNBERATUNG_LINK,
  WOHNPARTNER_LINK,
} from '../links';
import {
  CUSTOMER_SERVICE_MAIL,
  CUSTOMER_SERVICE_NUMBER,
  HAUSBETREUUNG_MAIL,
  LAUNDRY_CUSTOMER_NUMBER,
  TENANT_PORTAL_SERVICE_NUMBER,
} from '../component-labels';
import { FORMATTER } from '../../utility/formatHandler';
import { Configuration } from '../../../Configuration';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { rentalVideoOptions } from '../../containers/RentalAccount/RentalAccount';

export const FAQ_LABELS = {
  linkText: 'Zurück zur Anmeldung',
  title: 'Häufige Fragen',
  pageTitle: 'Häufige Fragen - Wiener Wohnen Mieter*innenportal',
  meta: 'Häufige Fragen - Die Antworten auf Ihre Fragen',
  introduction: 'Zu welchem Thema haben Sie Fragen?',
  contactQuestion: 'Ihre Frage ist nicht beantwortet?',
  contactInfo: 'Rufen Sie uns an unter: ',
  general: {
    accordionHeading: 'Allgemein',
    accordionQandA: [
      {
        accordionTitle: 'Wie geht Wiener Wohnen mit meinen Daten um?',
        accordionBody: (
          <p>
            Wiener Wohnen verwendet Ihre Daten nur im Rahmen der Vermietung Ihres Mietobjektes. Die Basis dafür ist der
            mit Ihnen abgeschlossene Mietvertrag. Alle Informationen finden Sie unter dem Menüpunkt{' '}
            <Hyperlink id={'faq-general-internal-dataprotection'} to={DATA_PROTECTION}>
              Datenschutz
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe meinen Mietvertrag verloren. Was ist zu tun?',
        accordionBody: (
          <p>
            Bitte suchen Sie per Post oder{' '}
            <Hyperlink id="faq-ww-service-mail-1" customHref={true} to={`mailto:${CUSTOMER_SERVICE_MAIL}`}>
              per E-Mail
            </Hyperlink>{' '}
            um eine Vertragskopie an. Die Vertragskopie wird Ihnen per Post zugestellt.
          </p>
        ),
      },
      {
        accordionTitle: 'Wo bekomme ich Informationen über meinen Gemeindebau?',
        accordionBody: (
          <p>
            Sämtliche Informationen zu Ihrer Wohnhausanlage finden Sie unter dem Menüpunkt{' '}
            <Hyperlink id={'faq-internal-building-complex-1'} to={COMPLEX_INFORMATION}>
              Hof-Informationen
            </Hyperlink>{' '}
            im Mieter*innenportal oder hier:{' '}
            <Hyperlink
              id={'faq-external-building-complex-1'}
              to={BUILDINGCOMPLEX_DESCRIPTION_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Gemeindebaubeschreibung
            </Hyperlink>
          </p>
        ),
      },
      {
        accordionTitle: 'Welche Adressen hat mein Gemeindebau noch (Identadressen)?',
        accordionBody: (
          <p>
            Die Identadressen Ihres Gemeindebaus finden Sie entweder in den{' '}
            <Hyperlink id={'faq-internal-building-complex-2'} to={COMPLEX_INFORMATION}>
              Hof-Informationen
            </Hyperlink>{' '}
            im Mieter*innenportal oder hier:{' '}
            <Hyperlink
              id={'faq-external-building-complex-2'}
              to={BUILDINGCOMPLEX_DESCRIPTION_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Gemeindebaubeschreibung
            </Hyperlink>
          </p>
        ),
      },
      {
        accordionTitle: 'Wo kann ich mich für ein Geschäftslokal oder ein Magazin anmelden?',
        accordionBody: (
          <>
            <h4>Freie Lokale finden</h4>
            <p>
              Auf der Wiener Wohnen Webseite finden Sie unter{' '}
              <Hyperlink
                id={'faq-available-restaurant-search'}
                isExternal={true}
                to={AVAILABLE_RESTAURANT_SEARCH_LINK}
                iconAfter={SVG.external_link}
              >
                freie Geschäftslokale
              </Hyperlink>{' '}
              einen Auszug der aktuell verfügbaren Lokale. Über die Schaltfläche „Zur Anmeldung“ im jeweiligen Inserat
              können Sie direkt alle erforderlichen Informationen sowie Wünsche an Wiener Wohnen übermitteln. Wir
              kontaktieren Sie daraufhin und vereinbaren einen Besichtigungstermin.
            </p>
            <h4>Voraussetzungen</h4>
            <p>
              Die Grundvoraussetzungen für eine Lokalvergabe finden Sie hier gesammelt:{' '}
              <Hyperlink
                id="faq-atelier"
                to={AVAILABLE_RESTAURANT_INFO_LINK}
                isExternal={true}
                iconAfter={SVG.external_link}
              >
                Geschäftslokale
              </Hyperlink>
            </p>
          </>
        ),
      },
      {
        accordionTitle:
          'Was ist der Unterschied zwischen einem Wohnungstausch und einem Wohnungswechsel? Wo beantrage ich diese?',
        accordionBody: (
          <>
            <h4>Wohnungstausch</h4>
            <p>
              Sie können Ihre Gemeindewohnung gegen eine andere Gemeinde-, Genossenschaft- oder Privatwohnung tauschen.
              Hierfür benötigen Sie eine Tauschpartnerin oder einen Tauschpartner (andere Hauptmieterin oder anderer
              Hauptmieter). Nähere Informationen finden Sie hier:{' '}
              <Hyperlink id={'faq-ww-flat-swap'} to={FLATSWAP_LINK} isExternal={true} iconAfter={SVG.external_link}>
                Wohnungstausch
              </Hyperlink>
            </p>
            <h4>Wohnungswechsel</h4>
            <p>
              Beim Wohnungswechsel können Sie in eine andere Gemeindewohnung wechseln, wenn dafür besondere Gründe
              vorliegen. Zum Beispiel wenn Ihre jetzige Gemeindewohnung zu klein ist oder Sie in einen anderen Teil der
              Stadt ziehen möchten. Alles Wissenswerte dazu finden Sie hier:{' '}
              <Hyperlink
                id={'faq-ww-precondition-flat-swap'}
                to={PRECONDITIONS_FLATSWAP_LINK}
                isExternal={true}
                iconAfter={SVG.external_link}
              >
                Grundvoraussetzungen für Wohnungswechsel
              </Hyperlink>
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Kann ich meine Wohnung an Verwandte weitergeben?',
        accordionBody: (
          <p>
            Dazu sind bestimmte Voraussetzungen nötig. Diese finden Sie auf der Wiener Wohnen Webseite unter{' '}
            <Hyperlink
              id={'faq-ww-flat-transfer'}
              to={FLAT_TRANSFER_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Wohnungsweitergabe
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Was ist die Direktvergabe? Wie funktioniert sie?',
        accordionBody: (
          <p>
            Als Hauptmieter*in können Sie ihre Wohnung direkt an eine Nachmieterin oder einen Nachmieter mit gültigem
            Wiener Wohn-Ticket weitergeben. Das heißt, Sie können sich die Person aussuchen, die Ihre Wohnung nach Ihnen
            beziehen soll. Alle Informationen finden Sie hier:{' '}
            <Hyperlink
              id={'faq-ww-flat-direct-allocation'}
              to={DIRECT_ALLOCATION_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Direktvergabe
            </Hyperlink>
          </p>
        ),
      },
      {
        accordionTitle: 'Darf eine Gemeindewohnung auch zur Untermiete angeboten werden?',
        accordionBody: (
          <>
            <h4>Für Mieter*innen</h4>
            <p>
              Nein! Die Untervermietung von Gemeindewohnungen ist generell untersagt und hat ernsthafte Konsequenzen für
              alle Beteiligten – bis zur gerichtlichen Aufkündigung der Wohnung.
            </p>
            <h4>Für Wohnungssuchende</h4>
            <p>
              Wenn Ihnen eine Gemeindewohnung zur Untermiete angeboten wird, lehnen Sie unbedingt ab und melden Sie
              bitte den Vorfall an Wiener Wohnen.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie kann ich meine Wohnung tauschen?',
        accordionBody: (
          <p>
            Wenn Sie Ihre Wohnung tauschen möchten, brauchen Sie eine Tauschpartnerin oder einen Tauschpartner (andere
            Hauptmieterin oder anderer Hauptmieter). Alle Informationen rund um den Wohnungstausch finden Sie hier:{' '}
            <Hyperlink id={'faq-ww-flat-swap-1'} to={FLATSWAP_LINK} isExternal={true} iconAfter={SVG.external_link}>
              Wohnungstausch
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe Schimmel in der Wohnung. Was muss ich tun?',
        accordionBody: (
          <p>
            Bei bestehenden Schimmelbefall wenden Sie sich bitte an unsere <em>Service-Nummer unter</em>{' '}
            <Hyperlink id="faq-molds-info-tel" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
              {TENANT_PORTAL_SERVICE_NUMBER}
            </Hyperlink>
            . Unsere Expert*innen melden sich daraufhin für eine Terminvereinbarung zur Besichtigung in Ihrer Wohnung.
            Tipps zu einem guten Wohnklima finden Sie hier als{' '}
            <Hyperlink
              id={'faq-molds-info-download-id'}
              onClick={e => (window.location.href = Configuration.downloads().MOLDS_INFO_DOWNLOAD)}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Download
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Wann hat die Wohnberatung Wien geöffnet?',
        accordionBody: (
          <>
            <p>
              Die Wohnberatung Wien finden Sie in der Guglgasse 7–9, Ecke Paragonstraße – gegenüber der U3-Station
              Gasometer.
            </p>
            <dl>
              <dt>Öffnungszeiten:</dt>
              <dd>
                Mo., Di., Do., Fr.: {FORMATTER.timeTagExtension('08:00')}–{FORMATTER.timeTagExtension('19:00')} Uhr
              </dd>
              <dd>
                Mi.: {FORMATTER.timeTagExtension('08:00')}–{FORMATTER.timeTagExtension('12:00')} Uhr
              </dd>
            </dl>
            <p>
              <strong>
                Um Wartezeiten zu vermeiden, vereinbaren Sie bitte ein persönliches Beratungsgespräch telefonisch unter{' '}
                <Hyperlink id="faq-advice-tel" customHref={true} to={'tel:+43124111'}>
                  +43 1 24 111
                </Hyperlink>{' '}
                (Mo.–Fr. {FORMATTER.timeTagExtension('07:00')}–{FORMATTER.timeTagExtension('20:00')}).
              </strong>
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wann hat das Wiener Wohnen Service-Center geöffnet?',
        accordionBody: (
          <>
            <p>
              Das Wiener Wohnen Service-Center finden Sie in der Rosa-Fischer-Gasse 2, 1030 Wien – direkt neben der
              U3-Station Gasometer.
            </p>
            <strong>
              <dl>
                <dt>Öffnungszeiten:</dt>
                <dd>
                  Mo., Di.: {FORMATTER.timeTagExtension('08:00')}–{FORMATTER.timeTagExtension('16:00')} Uhr
                </dd>
                <dd>
                  Do.: {FORMATTER.timeTagExtension('08:00')}–{FORMATTER.timeTagExtension('18:00')} Uhr
                </dd>
                <dd>
                  Mi., Fr.: {FORMATTER.timeTagExtension('08:00')}–{FORMATTER.timeTagExtension('12:00')} Uhr
                </dd>
              </dl>
            </strong>
            <p>
              Seit {FORMATTER.dateTimeTagExtension('15.06.2020')} müssen Termine im Service-Center zuvor über{' '}
              <Hyperlink id="faq-service-center-tel" customHref={true} to={`tel:${CUSTOMER_SERVICE_NUMBER}`}>
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              vereinbart werden. Besuche ohne Termine sind nicht mehr möglich (ausgenommen Einzahlungen am
              Kassenautomaten). Eine Terminvereinbarung ist nur zwischen {FORMATTER.timeTagExtension('07:00')} und{' '}
              {FORMATTER.timeTagExtension('20:00')} Uhr möglich.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie funktioniert das Kundenservice von Wiener Wohnen?',
        accordionBody: (
          <p>
            Für alle Anliegen stehen wir Ihnen unter der Service-Nummer{' '}
            <Hyperlink id="faq-customer-service-tel" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
              {TENANT_PORTAL_SERVICE_NUMBER}
            </Hyperlink>{' '}
            rund um die Uhr für Auskünfte oder Schadensmeldungen zur Verfügung. Allgemeine Informationen erhalten Sie
            meist sofort, für ausführlichere Gespräche rufen wir Sie auch gerne innerhalb von 24 Stunden zurück.
          </p>
        ),
      },
      {
        accordionTitle: 'Was ist in der Miete inkludiert?',
        accordionBody: (
          <p>
            In der monatlichen Miete sind der Mietzins, die Betriebskosten (zum Beispiel Müll und Wasser) sowie die
            Umsatzsteuer inkludiert. Verbraucherkosten wie Strom und Gas werden extra berechnet. Bei manchen Wohnungen
            (z.B. Dachgeschosswohnung) fallen auch einmalige Finanzierungsbeiträge an.
          </p>
        ),
      },
      {
        accordionTitle: 'Welche Arten von Mietobjekten gibt es?',
        accordionBody: (
          <>
            <p>Wiener Wohnen bietet folgende Mietobjekte an:</p>
            <ul className={'List'}>
              <li>
                <Hyperlink
                  id={'faq-available-flats-info'}
                  to={AVAILABLE_FLATS_INFO_LINK}
                  isExternal={true}
                  iconAfter={SVG.external_link}
                >
                  Gemeindewohnungen
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  id={'faq-available-bike-box-info'}
                  to={AVAILABLE_BIKE_BOX_INFO_LINK}
                  isExternal={true}
                  iconAfter={SVG.external_link}
                >
                  Fahrradboxen
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  id={'faq-available-parking-info'}
                  to={AVAILABLE_PARKING_INFO_LINK}
                  isExternal={true}
                  iconAfter={SVG.external_link}
                >
                  Garagen und Parkplätze
                </Hyperlink>
              </li>
              <li>
                <Hyperlink
                  id={'faq-available-restaurant-info'}
                  to={AVAILABLE_RESTAURANT_INFO_LINK}
                  isExternal={true}
                  iconAfter={SVG.external_link}
                >
                  Geschäftslokale und Magazine
                </Hyperlink>
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  registration: {
    accordionHeading: 'Registrierung',
    accordionQandA: [
      {
        accordionTitle: 'Ich habe keine Wiener Wohnen ID oder keinen Wiener Wohnen PIN. Was kann ich tun?',
        accordionBody: (
          <>
            <p>
              Ohne Ihre Wiener Wohnen ID und Ihren Wiener Wohnen PIN können Sie sich nicht im Mieter*innenportal
              registrieren.
            </p>
            <p className={'Faq__p'}>
              <em>Achtung:</em> Ihre Kundennummer (z.B. auf Rechnungen) oder Service-Karten-Nummer können <em>nicht</em>{' '}
              für die Registrierung im Mieter*innenportal verwendet werden.
            </p>
            <h4 className={'Faq__subHeading'}>Wiener Wohnen PIN</h4>
            <p>
              Ihren Wiener Wohnen PIN haben Sie per Post erhalten. Sollten Sie Ihren PIN verloren oder vergessen haben,
              folgen Sie bitte dem Link{' '}
              <Hyperlink id={'registration-pin-faq'} to={NEW_PIN}>
                Wiener Wohnen PIN neu anfordern
              </Hyperlink>{' '}
              oder rufen Sie uns unter{' '}
              <Hyperlink id="faq-registration-customer-service-1" customHref to={`tel:${CUSTOMER_SERVICE_NUMBER}`}>
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              an.
            </p>
            <h4 className={'Faq__subHeading'}>Wiener Wohnen ID</h4>
            <p>
              Ihre Wiener Wohnen ID wird Ihnen per Post zugesendet. Sollten Sie kein Schreiben mit Ihrer Wiener Wohnen
              ID erhalten haben, folgen Sie bitte dem Link{' '}
              <Hyperlink id={'registration-customer-number-faq'} to={NEW_MDM}>
                Wiener Wohnen ID neu anfordern
              </Hyperlink>{' '}
              oder rufen Sie uns unter{' '}
              <Hyperlink id="faq-registration-customer-service-2" customHref to={`tel:${CUSTOMER_SERVICE_NUMBER}`}>
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              an.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie geht Wiener Wohnen mit meinen Daten um?',
        accordionBody: (
          <p>
            Wiener Wohnen verwendet Ihre Daten nur im Rahmen der Vermietung Ihres Mietobjektes. Die Basis dafür ist der
            mit Ihnen abgeschlossene Mietvertrag. Alle Informationen finden Sie unter dem Menüpunkt{' '}
            <Hyperlink id={'faq-registration-internal-dataprotection'} to={DATA_PROTECTION}>
              Datenschutz
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Was ist das Mieter*innenportal?',
        accordionBody: (
          <p>
            Das Mieter*innenportal von Wiener Wohnen bietet Ihnen Überblick über alle Ihre Mietobjekte. Sie können
            online alle wichtigen Informationen zu Ihrem Mietzinskonto erhalten, Waschtage buchen, Anträge stellen und
            Wissenswertes über Ihren Hof erfahren. Dieser Service ist für alle unsere Hauptmieter*innen kostenlos
            zugänglich.
          </p>
        ),
      },
      {
        accordionTitle: 'Was ist ein Stadt Wien Konto?',
        accordionBody: (
          <>
            <p>
              Das Stadt Wien Konto bietet Ihnen die Möglichkeit, mit nur einem Online-Konto auf mehrere Services der
              Stadt Wien zugreifen zu können. Sie müssen sich nur einmal registrieren und können dann auf verschiedenen
              Stadt Wien Webseiten und Apps mit dem gleichen Benutzernamen und Passwort anmelden.
            </p>
            <p>Sie können Ihr Stadt Wien Konto derzeit für folgende Services nutzen:</p>
            <ul className={'List'}>
              <li>Wiener Wohnen Mieter*innenportal</li>
              <li>Wiener Wohnen App</li>
              <li>
                <Hyperlink id={'faq-mein-wien'} to={MEIN_WIEN_LINK} isExternal={true} iconAfter={SVG.external_link}>
                  mein.wien.gv.at
                </Hyperlink>
              </li>
              <li>
                <Hyperlink id={'faq-wien'} to={WIEN_LINK} isExternal={true} iconAfter={SVG.external_link}>
                  wien.gv.at
                </Hyperlink>
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  newCredentials: {
    accordionHeading: 'Neue Wiener Wohnen ID',
    accordionQandA: [
      {
        accordionTitle: 'Was ist meine Wiener Wohnen ID?',
        accordionBody:
          'Ihre Wiener Wohnen ID wurde Ihnen ursprünglich per Brief zugesandt. Es handelt sich dabei um eine 7-12-stellige Zahl.',
      },
      {
        accordionTitle: 'Was ist der Wiener Wohnen PIN?',
        accordionBody:
          'Ihr Wiener Wohnen PIN wurde Ihnen ursprünglich per Brief zugesandt. Es handelt sich dabei um einen 4-stelligen PIN aus Zahlen und Buchstaben.',
      },
      /* {
              accordionTitle: 'Warum muss auch mein PIN neu verschickt werden?',
              accordionBody:
                'Da Ihre Wiener Wohnen ID in Kombination mit Ihrem PIN als vollständige Identifikation gilt, ' +
                'ist es aus Sicherheitsgründen erforderlich, bei diesem Vorgang auch den PIN in einem separaten Schreiben ' +
                'neu auszustellen.',
            }, */
      {
        accordionTitle: 'Warum wird mein PIN nur per Post verschickt?',
        accordionBody:
          'Der Wiener Wohnen PIN erlaubt es Ihnen, sich beim Mieter*innenportal, aber auch ' +
          'der Service-Nummer eindeutig zu identifizieren. Daher ist es aus Sicherheitsgründen erforderlich, ' +
          'dass wir den PIN per Post an die Adresse ihres Mietobjekts schicken.',
      },
    ],
  },
  profile: {
    accordionHeading: 'Profil',
    accordionQandA: [
      {
        accordionTitle: 'Wo kann ich meine Adresse oder meinen Namen ändern?',
        accordionBody: (
          <p>
            Zukünftig wird es Ihnen möglich sein, Ihren Namen in Ihrem Profil im Wiener Wohnen Mieter*innenportal zu
            ändern. Eine Änderung der Adresse erfolgt automatisch beim Wechsel des Hauptwohnsitzes.
          </p>
        ),
      },
      {
        accordionTitle: 'Was sind meine Stammdaten?',
        accordionBody: (
          <p>
            Unter Stammdaten verstehen wir alle persönlichen Daten, wie Kontaktdaten, Anmeldedaten sowie Daten zu Ihren
            Mietobjekten. Ihre Stammdaten finden Sie unter dem Menüpunkt{' '}
            <Hyperlink id={'faq-profile-1'} to={PROFILE}>
              Profil
            </Hyperlink>{' '}
            im Mieter*innenportal und der Wiener Wohnen App.
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe eine neue Telefonnummer oder E-Mail-Adresse. Was ist zu tun?',
        accordionBody: (
          <p>
            Sie können uns jederzeit unter Kontaktdaten ändern im Menüpunkt{' '}
            <Hyperlink id={'faq-profile-2'} to={PROFILE}>
              Profil
            </Hyperlink>{' '}
            Ihre neue Telefonnummer oder E-Mail-Adresse mitteilen, damit wir Sie auch immer erreichen können.
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe meinen Namen geändert. Was ist zu tun?',
        accordionBody: (
          <p>
            Bitte übermitteln Sie einen Nachweis über die Namensänderung (Heiratsurkunde, Scheidungsurkunde, etc.) per
            Post oder{' '}
            <Hyperlink id="faq-ww-service-mail-2" customHref={true} to={`mailto:${CUSTOMER_SERVICE_MAIL}`}>
              per E-Mail
            </Hyperlink>{' '}
            an Wiener Wohnen. Ihr Mietvertrag bleibt gültig und muss nicht neu ausgestellt werden.
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe meinen Mietvertrag verloren. Was ist zu tun?',
        accordionBody: (
          <p>
            Bitte suchen Sie per Post oder{' '}
            <Hyperlink id="faq-ww-service-mail-3" customHref={true} to={`mailto:${CUSTOMER_SERVICE_MAIL}`}>
              per E-Mail
            </Hyperlink>{' '}
            bei Wiener Wohnen um eine Vertragskopie an. Die Vertragskopie wird Ihnen per Post zugestellt.
          </p>
        ),
      },
      {
        accordionTitle: 'Wo kann ich meinen Benutzernamen ändern?',
        accordionBody: (
          <p>
            Leider ist es Ihnen nicht möglich Ihren Benutzernamen zu ändern, da dieser einmalig ist. Wollen Sie einen
            anderen Benutzernamen wählen, müssen Sie zuerst Ihr aktuelles Konto deaktivieren und anschließend den
            gesamten Registrierungsprozess 1 und 2 nochmal durchlaufen.
          </p>
        ),
      },
    ],
  },
  login: {
    accordionHeading: 'Anmeldung',
    accordionQandA: [
      {
        accordionTitle: 'Ich kann mich nicht ins Mieter*innenportal einloggen. Was kann ich tun?',
        accordionBody: (
          <>
            <p>Wenn Sie sich nicht im Mieter*innenportal einloggen können, kann dies mehrere Gründe haben:</p>
            <ul className={'Faq__list--no-bullets'}>
              <li>
                <h4>Technische Probleme:</h4>
                „Der Service ist derzeit nicht verfügbar.“ und eine Fehlermeldung mit Beschreibung der Ursache wird
                angezeigt. In diesem Fall bitten wir Sie um etwas Geduld, bis die Störung behoben ist.
              </li>
              <li>
                <h4>Anmeldungsfehler:</h4>
                Sie haben die falschen Benutzerdaten beim Login angegeben. Folgen Sie einfach den Hinweisen direkt auf
                der Seite.
              </li>
              <li>
                <h4>Benutzer-Account fehlt:</h4>
                Sie haben noch keinen Benutzer-Account der Stadt Wien. Bitte registrieren Sie sich vorab, um das
                Mieter*innenportal nutzen zu können.
              </li>
            </ul>
            <p>
              Wir helfen Ihnen auch jederzeit gerne telefonisch unter unserer Service-Nummer{' '}
              <Hyperlink id="faq-ww-service-tel-login-1" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>{' '}
              weiter.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Ich habe mein Passwort vergessen. Was kann ich tun?',
        accordionBody: (
          <p>
            Unter dem Link{' '}
            <Hyperlink
              id="faq-forgot-password"
              to={FORGOT_PASSWORD_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Zugangsdaten vergessen
            </Hyperlink>{' '}
            auf der Anmeldemaske finden Sie weitere Anweisungen.
          </p>
        ),
      },
      {
        accordionTitle: 'Werden Wartungsarbeiten angezeigt?',
        accordionBody: (
          <>
            <p>Ja, wenn wir Wartungsarbeiten am Mieter*innenportal durchführen, wird das gesondert angezeigt.</p>
            <p>
              Wir helfen Ihnen auch jederzeit gerne telefonisch unter unserer Service-Nummer{' '}
              <Hyperlink id="faq-ww-service-tel-login-2" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>{' '}
              weiter.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie bekomme ich einen Zugang, wenn ich keine Mieterin oder kein Mieter bin?',
        accordionBody: (
          <>
            <h4>Für Mieter*innen:</h4>
            <p>Unser Mieter*innenportal steht allen Mieterinnen und Mietern von Wiener Wohnen zur Verfügung.</p>
            <h4>Für Wohnungssuchende:</h4>
            <p>
              Möchten Sie eine Gemeindewohnung mieten? Alle Informationen zur Anmeldung für ein Wiener Wohn-Ticket
              finden Sie auf der Webseite der{' '}
              <Hyperlink id={'faq-wohnberatung'} to={WOHNBERATUNG_LINK} isExternal={true} iconAfter={SVG.external_link}>
                Wohnberatung Wien
              </Hyperlink>
              .
            </p>
          </>
        ),
      },
    ],
  },
  buildingComplex: {
    accordionHeading: 'Hof-Informationen',
    accordionQandA: [
      {
        accordionTitle: 'Welche Ruhezeiten gelten?',
        accordionBody: (
          <>
            <p>
              Grundsätzlich gilt die von Ihnen beim Vertragsschluss unterschriebene und bestätigte Hausordnung von
              Wiener Wohnen.
            </p>
            <p>
              <strong>
                Die Ruhezeiten gelten von {FORMATTER.timeTagExtension('22:00')} Uhr abends bis{' '}
                {FORMATTER.timeTagExtension('06:00')} Uhr früh.
              </strong>
            </p>
            <p>
              Als Hauptmieter*in tragen Sie die volle Verantwortung der Einhaltung der Hausordnung. Sie können auch für
              Verstöße gegen die Hausordnung durch Mitbewohner*innen oder Besucher*innen haftbar gemacht werden.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Was mache ich bei Lärmbelästigung?',
        accordionBody: (
          <>
            <p>
              Als Lärmbelästigung gelten alle Geräusche, die für andere störend sein können (Beispiel: Türen zuschlagen,
              laute Musik oder laute Maschinen). Sprechen Sie Ihre Nachbar*innen auf das Problem an – womöglich ist
              ihnen gar nicht bewusst, dass sie damit gestört haben.
            </p>
            <p>
              Sollten Sie darüber hinaus Unterstützung benötigen, steht Ihnen{' '}
              <Hyperlink id={'faq-wohnpartner'} isExternal={true} to={WOHNPARTNER_LINK} iconAfter={SVG.external_link}>
                wohnpartner
              </Hyperlink>{' '}
              mit seinen Vermittlungstätigkeiten zur Verfügung. Wenn sich die Lärmbelästigung dadurch nicht bessern
              sollte, kann Wiener Wohnen Verwaltungsmaßnahmen setzen.
            </p>
          </>
        ),
      },
      {
        accordionTitle:
          'Woher bekomme ich einen Schlüssel für einen Gemeinschaftsraum, Hobbyraum oder Kinderspielraum?',
        accordionBody: (
          <p>
            Sofern Ihre Wohnhausanlage über Gemeinschaftsräume verfügt, erhalten Sie den Schlüssel entweder bei den
            Hausbetreuer*innen, Hausbesorger*innen oder Mieterbeirät*innen. Die jeweilige Kontaktperson ist in den
            Stiegen am schwarzen Brett ausgehängt. Die Kontaktdaten finden Sie am schwarzen Brett im Stiegenhaus.
          </p>
        ),
      },
      {
        accordionTitle: 'Was muss ich bei einem dringenden Gebrechen (Wasserrohrbruch, Ausfall der Therme, usw.) tun?',
        accordionBody: (
          <>
            <h4>Schnell handeln:</h4>
            <p>
              Verlieren Sie keine Zeit und kontaktieren Sie unsere Service-Nummer unter{' '}
              <Hyperlink
                id="faq-building-complex-service-number-1"
                to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`}
                customHref={true}
              >
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>
              . Sie können uns täglich rund um die Uhr erreichen. Wir helfen Ihnen, damit das Problem so schnell wie
              möglich behoben werden kann.
            </p>
            <p>
              <strong>
                Bei Notfällen wie Gasgeruch, Feuer, Diebstahl etc., wenden Sie sich bitte sofort an den jeweiligen
                Notruf.
              </strong>
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie kann ich Magazine oder Kellerabteile mieten?',
        accordionBody: (
          <p>
            Alle Informationen zur Anmietung von weiteren Einheiten finden Sie auf der Wiener Wohnen Webseite unter{' '}
            <Hyperlink
              id={'faq-ww-interested-person-1'}
              to={INTERESTED_PERSON_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              InteressentIn
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Wo finde ich die Hausordnung?',
        accordionBody: (
          <p>
            Die aktuelle Hausordnung finden Sie auf der Wiener Wohnen Webseite unter{' '}
            <Hyperlink
              id={'faq-ww-houserules'}
              className={'Faq__hyperlink'}
              to={HOUSE_RULES_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Hausordnung
            </Hyperlink>{' '}
            – oder direkt hier als{' '}
            <Hyperlink
              id={'faq-ww-houserules-download'}
              className={'Faq__hyperlink'}
              onClick={e => (window.location.href = Configuration.downloads().HOUSE_RULES_DOWNLOAD)}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Download
            </Hyperlink>
            . Außerdem finden Sie einen Ausdruck in Ihrer Willkommensmappe, die Sie bei Unterzeichnung Ihres
            Mietvertrages erhalten haben. Die wichtigsten Punkte der Hausordnung sind zusätzlich in jedem Stiegenhaus
            ausgehängt.
          </p>
        ),
      },
      {
        accordionTitle: 'Wo befinden sich die Waschküchen in meiner Wohnhausanlage?',
        accordionBody: (
          <p>
            Die Hausbetreuer*innen oder Hausbesorger*innen Ihrer Wohnhausanlage zeigen Ihnen gerne den Weg zur
            Waschküche und erklären Ihnen die Benützung. Die Kontaktdaten finden Sie am schwarzen Brett im Stiegenhaus.
          </p>
        ),
      },
      {
        accordionTitle: 'Gibt es Gemeinschaftsräume in meiner Wohnhausanlage? Wenn ja, wie kann ich diese nutzen?',
        accordionBody: (
          <>
            <h4>Nutzung</h4>
            <p>
              Sollte Ihre Wohnhausanlage über Gemeinschaftsräume verfügen, stehen diese allen Mieterinnen und Mietern
              zur Verfügung. Nehmen Sie bitte trotzdem Rücksicht auf die eventuell entstehende Lärmentwicklung.
            </p>
            <h4>Informationen</h4>
            <p>
              Für mehr Informationen wenden Sie sich bitte an Ihre Hausbetreuer*innen oder Hausbesorger*innen. Deren
              Kontaktdaten finden Sie am schwarzen Brett im Stiegenhaus.
            </p>
          </>
        ),
      },
      {
        accordionTitle:
          'Mit welchen Anliegen können Sie sich an Hausbesorger*in, Hausbetreuer*in wenden? Wann sind Mietervertreter*innen für Sie da?',
        accordionBody: (
          <>
            <h4>Hausbesorger*innen und -betreuer*innen</h4>
            <p>
              An unsere Hausbetreuer*innen und Hausbesorger*innen können Sie sich wenden, wenn Sie Fragen rund um die
              Reinigung oder kleinere Gebrechensmeldungen haben.
            </p>
            <h4>Mietervertreter*innen</h4>
            <p>
              Ihre gewählten Mietervertreter*innen sind für Sie da, wenn mehrere Hausparteien ein gemeinsames Anliegen
              haben.
            </p>
          </>
        ),
      },
    ],
  },
  rental: {
    accordionHeading: 'Mietzins',
    accordionQandA: [
      {
        accordionTitle: 'Wie setzt sich mein Mietzins und die Betriebskosten zusammen?',
        accordionBody: <VideoPlayer options={rentalVideoOptions} />,
      },
      {
        accordionTitle: 'Was ist alles in den Betriebskosten inkludiert?',
        accordionBody: (
          <p>
            Eine Auflistung der einzelnen Betriebskosten finden Sie auf der Wiener Wohnen Webseite unter{' '}
            <Hyperlink
              id={'faq-ww-operating-cost'}
              isExternal={true}
              to={OPERATING_COSTS_LINK}
              iconAfter={SVG.external_link}
            >
              Betriebskosten
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Ich möchte meine Miete mittels Einziehungsauftrag zahlen. Was ist zu tun?',
        accordionBody: (
          <>
            <ol className={'List'}>
              <li>
                <h4>Online-Formular ausfüllen</h4>
                Der Einziehungsauftrag (SEPA-Lastschrift Mandat) erleichtert das Bezahlen der Miete. Um ein
                SEPA-Lastschrift Mandat anzulegen, können Sie{' '}
                <Hyperlink
                  id={'faq-ww-direct-debit-document-1'}
                  isExternal={true}
                  to={DIRECT_DEBIT_LINK}
                  iconAfter={SVG.external_link}
                >
                  hier das Online-Formular
                </Hyperlink>{' '}
                ausfüllen.
              </li>
              <li>
                <h4>Formular ausdrucken, unterschreiben, einsenden</h4>
                Im Anschluss drucken Sie das Formular aus, unterschreiben es und lassen es uns per Post oder Fax
                zukommen. Sie können auch eine{' '}
                <Hyperlink
                  id={'faq-ww-empty-direct-debit-document'}
                  isExternal={true}
                  to={EMPTY_DIRECT_DEBIT_LINK}
                  iconAfter={SVG.external_link}
                >
                  SEPA Lastschrift Mandat Leerformular Vorlage
                </Hyperlink>{' '}
                herunterladen und per Hand ausfüllen. Diese lassen Sie uns bitte unterschrieben per Post oder Fax
                zukommen.
              </li>
            </ol>
            <p>
              <em>Bitte beachten Sie, dass die Aktivierung Ihres Einziehungsauftrags bis zu 6 Wochen dauern kann.</em>{' '}
              Überprüfen Sie daher Ihr Konto und zahlen Sie gegebenenfalls den aktuell offenen Betrag noch mit
              Erlagschein ein, sollte der Einziehungsauftrag zur nächsten Mietfälligkeit noch nicht aktiv sein.
            </p>
            <p>
              <em>Hinweis:</em> Wenn Sie im Formular den Punkt „Erweiterung des Umfanges der Lastschrift auf alle
              Anforderungen von Wiener Wohnen“ ankreuzen, bedeutet dies <em>nicht</em>, dass Sie Wiener Wohnen
              automatisch ermächtigen, auch bei zukünftig angemieteten Objekten (z.B. Garage, Magazin, Parkplatz) die
              Miete automatisch einzuziehen. Ein Anruf oder eine E-Mail genügen allerdings, um die Lastschrift auf Ihre
              neu angemieteten Mietobjekte zu erweitern.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Was mache ich, wenn ich meinen Zahlschein verloren habe?',
        accordionBody: (
          <>
            <h4>Zahlung via Online-Banking</h4>
            <p>
              Informationen zu den Zahlungsoptionen für das jeweilige Mietobjekt finden Sie unter dem Menüpunkt{' '}
              <Hyperlink id={'faq-rental-account'} to={RENTAL_ACCOUNT}>
                Mietzinskonto
              </Hyperlink>
              .
            </p>
            <h4>Zahlung am Kassenautomaten</h4>
            <p>
              Sie können die Miete auch an den Kassenautomaten im Wiener Wohnen Service-Center bar bezahlen.
              Zugangszeiten und Adresse finden Sie unter dem Menüpunkt{' '}
              <Hyperlink id="faq-rental-contact-1" to={CONTACT}>
                Kontakt
              </Hyperlink>
              .
            </p>
            <p>
              <em>Hinweis:</em> Wenn Sie die Zahlungsfunktion Ihrer Service-Karte freischalten haben lassen, können Sie
              auch außerhalb der Zugangszeiten rund um die Uhr auf einen unsere Kassenautomaten zugreifen.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Meine Bankdaten haben sich geändert. Was ist zu tun?',
        accordionBody: (
          <p>
            Für einen Bankeinzug können mit den geänderten Daten ein neues SEPA Lastschrift Mandat erteilen. Das nötige
            Formular dazu finden Sie{' '}
            <Hyperlink
              id={'faq-ww-direct-debit-document-2'}
              isExternal={true}
              to={DIRECT_DEBIT_LINK}
              iconAfter={SVG.external_link}
            >
              hier
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Bis wann muss ich meine Miete bezahlen?',
        accordionBody: (
          <p>
            Nach aktuellem Zahlungsverzugsgesetz ist Ihre Miete mit dem 5. Kalendertag jedes Monats fällig. Diese
            Regelung gilt auch für Parkplätze, Garagen, Lokale, Magazine und sonstige Mietobjekte.
          </p>
        ),
      },
      {
        accordionTitle: 'Was ist eine Delogierung?',
        accordionBody: (
          <p>
            Eine Delogierung ist die Zwangsräumung einer Wohnung. Diese findet statt, wenn ein Gericht der Aufkündigung
            eines Mietverhältnisses zugestimmt hat. Gründe dafür können sein: Nichtbezahlung der Miete, Nichtbenützung,
            Untervermietung, etc.
          </p>
        ),
      },
      {
        accordionTitle: 'Was passiert, wenn ich die Miete nicht bezahlen kann?',
        accordionBody: (
          <>
            <h4>Gerichtliche Räumungsklage</h4>
            <p>
              Wenn die Miete nach zwei Monaten trotz Zahlungserinnerung und Mahnung nicht bzw. nur teilweise bezahlt
              wurde, kann Wiener Wohnen eine Räumungsklage beim Gericht einbringen.
            </p>
            <h4>Bei Zahlung vor dem Gerichtstermin</h4>
            <p>
              Bezahlen Sie <em>vor</em> diesem Termin den gesamten Rückstand inkl. der nach der Klagseinbringung
              anfallenden Mieten sowie die Gerichtskosten, wird bei der Verhandlung ein „Ruhen“ vereinbart. Das
              bedeutet: Die Räumungsklage ist damit abgewendet.
            </p>
            <h4>Bei Nichtzahlung</h4>
            <p>
              Werden die offenen Mieten nicht bezahlt, begehrt Wiener Wohnen ein Urteil oder einen Vergleich vom
              Gericht. Danach kann Wiener Wohnen bei Nichtzahlung bzw. beim Nichtzustandekommen einer anderen
              Zahlungsvereinbarung einen Antrag auf die zwangsweise Räumung (Delogierung) stellen. Dies kann zum Verlust
              des Mietobjektes (Wohnung, Lokal, Magazin, Garage) führen.
            </p>
            <h4>Hilfe zum Thema</h4>
            <p>
              Unsere Mitarbeiter*innen können Ihnen Hilfestellung zum Thema geben. Wenden Sie sich dazu bitte an unsere
              Service-Nummer unter{' '}
              <Hyperlink
                id="faq-rental-account-service-number-1"
                to={`tel: ${CUSTOMER_SERVICE_NUMBER}`}
                customHref={true}
              >
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              und vereinbaren Sie einen persönlichen Beratungstermin.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Ich habe ein Guthaben erhalten. Was passiert damit?',
        accordionBody: (
          <>
            <h4>Guthaben aus der Betriebskostenabrechnung:</h4>
            <p>
              Das Guthaben wird automatisch von der nächsten Monatsmiete abgezogen. Sollte das Guthaben höher als die
              nächste Monatsmiete sein, wird der Rest automatisch an Sie ausbezahlt.
            </p>
            <h4>Guthaben aus anderen Gründen:</h4>
            <p>
              Ihr Guthaben wird automatisch von der nächsten Monatsmiete abgezogen. Sollten Sie eine Auszahlung
              wünschen, melden Sie sich bitte rechtzeitig bei Wiener Wohnen. Sie können den Betrag auf Ihr angegebenes
              Konto überweisen lassen.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie lange gilt meine SEPA Lastschrift?',
        accordionBody: (
          <>
            <h4>Gültigkeit</h4>
            <p>
              Eine SEPA-Lastschrift ist gültig, solange die monatliche Miete vollständig von Ihrem Konto abgebucht
              werden kann.
            </p>
            <h4>Lastschrift stornieren</h4>
            <p>
              Falls Sie die Einzugsermächtigung beenden möchten, können Sie uns das schriftlich, telefonisch oder
              persönlich mitteilen. Je nach dem Zeitpunkt Ihrer Mitteilung kann es in Einzelfällen vorkommen, dass
              systembedingt noch eine Lastschrift letztmalig Ihr Konto belastet.
            </p>
            <h4>Bitte beachten Sie:</h4>
            <p>
              Wenn Sie die Miete zweimal hintereinander wieder auf Ihr Konto rückbuchen, stellt die Bank aufgrund der
              dabei anfallenden Spesen den Einzug automatisch ein.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Ab wann kann ich auf die Betriebskostenabrechnung zugreifen?',
        accordionBody: (
          <>
            <p>
              Die Betriebskosten- und Hauptmietzinsabrechnung des Vorjahres senden wir Ihnen bis 30. Juni des aktuellen
              Jahres per Post.
            </p>
            <p>
              Guthaben oder Nachforderungen aus der Betriebskostenabrechnung beziehen sich immer auf die derzeitige
              Mieterin bzw. den derzeitigen Mieter. Stichtag hierfür ist der 1. August des laufenden Jahres.
            </p>
            <p>
              Mehr Informationen finden Sie auf der{' '}
              <Hyperlink
                id={'faq-ww-utility-bill'}
                to={UTILITY_BILL_LINK}
                isExternal={true}
                iconAfter={SVG.external_link}
              >
                Betriebskosten- und Hauptmietzinsabrechnung
              </Hyperlink>
              .
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wo finde ich gesetzliche Informationen zum Thema Betriebskosten?',
        accordionBody: (
          <p>
            Alle wichtigen Informationen bzw. Rechtsvorschriften zum Thema Mietzinsgesetz finden Sie unter{' '}
            <Hyperlink
              id={'faq-operating-cost-law'}
              to={OPERATING_COST_LAW_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              § 21 Betriebskosten und laufende öffentliche Abgaben, § 22 Auslagen für die Verwaltung, § 23 Beitrag für
              Hausbesorgerarbeiten sowie § 24 Anteil an besonderen Aufwendungen
            </Hyperlink>{' '}
            des Mietrechtsgesetzes (Stand: {FORMATTER.dateTimeTagExtension('17.03.2020')}).
          </p>
        ),
      },
      {
        accordionTitle: 'Wie kann ich eine Ratenvereinbarung beantragen?',
        accordionBody: (
          <>
            <p>
              Um eine Ratenvereinbarung zu beantragen, wenden Sie sich bitte an unsere Service-Nummer unter{' '}
              <Hyperlink
                id="faq-rental-account-service-number-2"
                customHref={true}
                to={`tel:${CUSTOMER_SERVICE_NUMBER}`}
              >
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>{' '}
              und vereinbaren einen persönlichen Beratungstermin.
            </p>
            <p>
              <strong>Bitte beachten Sie: Ein Beratungstermin erfolgt erst nach telefonischer Rücksprache.</strong>
            </p>
          </>
        ),
      },
      {
        accordionTitle:
          'An welche Stellen kann ich mich wenden, wenn ich um Beihilfen für meine Miete anfragen möchte?',
        accordionBody: (
          <>
            <p>
              Die Gründe für einen Mietzinsrückstand können sehr unterschiedlich sein. Folgende Ansprechpartner*innen
              können Ihnen weiterhelfen:
            </p>
            <ul className="Faq__list--no-bullets">
              <li>
                <h4>MA 40 – Sozial- und Gesundheitsrecht</h4>
                Die MA 40 bietet Hilfe bei keinem oder einem sehr geringen Einkommen (Mindestsicherung) und hilft auch
                bei bestimmten Voraussetzungen auch durch eine Mietbeihilfe. Des Weiteren besteht die Möglichkeit einer
                Förderung in besonderen Lebenslagen.{'\n'}
                Kontakt:{' '}
                <Hyperlink id="faq-ma-50-social-and-health-tel" customHref={true} to={'tel:+43 1 40 00 80 40'}>
                  +43 1 40 00 80 40
                </Hyperlink>
              </li>
              <li>
                <h4>MA 50 – Wohnbauförderung und Schlichtungsstelle für Mietrechtssachen</h4>
                Je nach Haushaltsgröße, Haushalts- einkommen, Wohnungsgröße und Wohnungsaufwand, bietet die Stadt Wien
                Unterstützung in Form einer Wohnbeihilfe.{'\n'}
                Kontakt:{' '}
                <Hyperlink id="faq-ma-50-rental-tel" customHref={true} to={'tel:+43 1 40 00 74 88 0'}>
                  +43 1 40 00 74 88 0
                </Hyperlink>
              </li>
              <li>
                <h4>Fonds Soziales Wien</h4>
                Eine kostenlose Beratung für in Wien wohnende verschuldete Privatpersonen hilft bei rechtlichen Aspekte
                im Zusammenhang mit Schulden und Lösungsmöglichkeiten.{'\n'}
                Kontakt:{' '}
                <Hyperlink id="faq-advice-fonds-social-tel" customHref={true} to={'tel:+43 1 33 08 73 5'}>
                  +43 1 33 08 73 5
                </Hyperlink>
              </li>
            </ul>
          </>
        ),
      },
      {
        accordionTitle: 'Wie kann ich Wohnbeihilfe beantragen?',
        accordionBody: (
          <p>
            Informationen zur Wohnbeihilfe finden Sie auf der Webseite der Stadt Wien unter{' '}
            <Hyperlink
              id={'faq-ww-housing-benefits'}
              to={HOUSINGBENEFITS_LINK}
              isExternal={true}
              iconAfter={SVG.external_link}
            >
              Wohnbeihilfe-Antrag
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Wie kann ich Geld am Kassenautomaten einzahlen?',
        accordionBody: (
          <>
            <h4>Wo kann ich einzahlen?</h4>
            <p>
              Es befinden sich 4 Kassenautomaten im{' '}
              <Hyperlink id="faq-rental-contact-2" to={CONTACT}>
                Wiener Wohnen Service-Center
              </Hyperlink>
              . Während der regulären Öffnungszeiten des Service-Centers können Sie alle 4 Kassenautomaten benutzen.
              Außerhalb der Öffnungszeiten steht Ihnen einer der Automaten 24 Stunden, 7 Tage die Woche zur Verfügung.
            </p>
            <h4>Wie funktioniert die Einzahlung?</h4>
            <p>Es gibt drei Möglichkeiten, wie Sie dort Geld einzahlen können:</p>
            <ol className={'List'}>
              <li>Mit einer freigeschalteten Wiener Wohnen Service-Karte</li>
              <li>Mit Ihrem Erlagschein mit QR-Code</li>
              <li>
                Mit einer Kassenkarte – diese ist zu den Öffnungszeiten des Wiener Wohnen Service-Centers am Empfang
                erhältlich
              </li>
            </ol>
          </>
        ),
      },
    ],
  },
  laundry: {
    accordionHeading: 'Waschtage',
    accordionQandA: [
      {
        accordionTitle: 'Wie viele Waschtage kann ich reservieren?',
        accordionBody: (
          <>
            <p>Sie können, zusätzlich zu Ihrem Regelwaschtag, in folgendem Umfang Reservierungen vornehmen:</p>
            <ul className={'List'}>
              <li>
                Pro Monat, in dem die Reservierung erfolgt, können maximal vier Termine zusätzlich gebucht werden.
              </li>
              <li>
                Pro Kalendertag und pro Anmeldung können maximal zwei Reservierungen zusätzlich vorgenommen werden.
              </li>
            </ul>
            <p>Reservierungen sind nur für die nächsten 30 Tage möglich.</p>
          </>
        ),
      },
      {
        accordionTitle: 'Was, wenn ich doch nicht an meinen reservierten Waschtag waschen kann?',
        accordionBody: (
          <>
            <p>
              Sollten Sie Ihren reservierten Waschtag nicht wahrnehmen können, bitten wir Sie darum diesen rechtzeitig
              (vor Start der reservierten Zeit) zu stornieren. Sie finden alle reservierten Waschtage unter dem
              Menüpunkt{' '}
              <Hyperlink id={'faq-laundry'} to={LAUNDRY_DAY}>
                Waschtage
              </Hyperlink>
              . Um einen Waschtag zu stornieren, wählen Sie zuerst den gewünschten Waschtag aus und klicken anschließend
              auf „Waschtag stornieren“. Sie können stattdessen einen anderen Waschtag reservieren. Sollte der
              reservierte Zeitabschnitt bereits begonnen haben, gilt der Waschtag als verbraucht und kann nicht mehr
              storniert werden.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Ein Waschgerät wurde defekt gemeldet. Was passiert nun?',
        accordionBody: (
          <p>
            Sollten Sie eine Benachrichtigung bekommen haben, dass ein Waschgerät in Ihrer reservierten Waschküche
            defekt gemeldet wurde, bleibt Ihre Reservierung trotzdem bestehen. Wir versuchen den Schaden so schnell wie
            möglich zu beheben. Sollten Sie einen Ersatztermin haben wollen, müssen Sie den reservierten Waschtag zuerst
            stornieren.
          </p>
        ),
      },
      {
        accordionTitle: 'Wie kann ich in einer der Waschküchen waschen?',
        accordionBody: (
          <>
            <p>
              Bevor Sie eine der Waschküchen verwenden können, müssen Sie einen Waschtag reservieren. Das können Sie
              unter dem Menüpunkt{' '}
              <Hyperlink id={'faq-laundry-1'} to={LAUNDRY_DAY}>
                Waschtage
              </Hyperlink>{' '}
              tun. Um in die Waschküche zu kommen, brauchen Sie Ihre Service-Karte oder den Schlüssel-Chip.
            </p>
            <p>
              Sollten die Waschküchen in Ihrem Gemeindebau noch nicht mit einem „naTÜRlich-sicher“-System ausgestattet
              sein, können die Waschtage nur über die Hausbesorgerin oder den Hausbesorger reserviert werden. In diesem
              Fall erhalten sie auch den Schlüssel zur Waschküche von der Hausbesorgerin oder dem Hausbesorger.
            </p>
            <p>
              Sie können die Waschgeräte innerhalb Ihrer reservierten Zeit verwenden. Die Kosten werden mit Ihrer
              nächsten Rechnung automatisch abgerechnet.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie komme ich in die Waschküche?',
        accordionBody: (
          <>
            <p>Um in die Waschküche zu kommen, brauchen Sie Ihre Service-Karte oder den Schlüssel-Chip.</p>
            <p>
              Sollten die Waschküchen in Ihrem Gemeindebau noch nicht mit einem „naTÜRlich-sicher“-System ausgestattet
              sein, erhalten sie den Schlüssel für die Waschküche von der Hausbesorgerin oder dem Hausbesorger.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie kann ich meinen Regelwaschtag ändern?',
        accordionBody: (
          <>
            <p>Der Regelwaschtag ist jener Waschtag, der jedes Monat automatisch für Sie reserviert wird.</p>
            <p>
              Wenn Sie diesen ändern möchten, wenden Sie sich bitte an die „naTÜRlich sicher”-Service-Nummer unter{' '}
              <Hyperlink id="faq-laundry-customer-number" customHref={true} to={`tel:${LAUNDRY_CUSTOMER_NUMBER}`}>
                {LAUNDRY_CUSTOMER_NUMBER}
              </Hyperlink>
              , oder schicken Sie eine E-Mail an{' '}
              <Hyperlink id="faq-hausbetreuung-mail" customHref={true} to={`mailto:${HAUSBETREUUNG_MAIL}`}>
                {HAUSBETREUUNG_MAIL}
              </Hyperlink>
              .
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Gibt es Waschküchen, die nicht online reserviert werden können?',
        accordionBody: (
          <p>
            Für Waschküchen mit besonders hoher Auslastung bieten wir kein Online-Service an. Bei Fragen dazu, wenden
            Sie sich bitte an die „naTÜRlich sicher”-Service-Nummer unter{' '}
            <Hyperlink id="faq-laundry-customer-number-1" customHref={true} to={`tel:${LAUNDRY_CUSTOMER_NUMBER}`}>
              {LAUNDRY_CUSTOMER_NUMBER}
            </Hyperlink>{' '}
            oder per E-Mail an{' '}
            <Hyperlink id="faq-hausbetreuung-mail-1" customHref={true} to={`mailto:${HAUSBETREUUNG_MAIL}`}>
              {HAUSBETREUUNG_MAIL}
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Warum sind die Kosten bei der Waschküche gestiegen?',
        accordionBody: (
          <p>
            Die Strom- und Gaspreise sind seit Jahresbeginn 2021 sehr stark gestiegen. Das heißt, auch die Kosten für
            die Benützung der Waschküche sind gestiegen. Der Arbeitspreis wird einmal jährlich am Beginn des
            Kalenderjahres festgelegt. Für die Arbeitspreise im Jahr 2024 werden wieder am Anfang des Kalenderjahres die
            Preisentwicklungen des Vorjahres (2023) herangezogen werden.
          </p>
        ),
      },
      {
        accordionTitle: 'Kann ich die Tür zur Waschküche auch ohne elektronischen Schlüssel öffnen?',
        accordionBody: (
          <>
            <p>
              Die Tür zur Waschküche kann während eines Waschtermins auch über eine Funktion im Mieter*innen-Portal oder
              im Online-Service von „naTÜRlich sicher“ geöffnet werden. Dafür müssen Sie in der Erinnerung „Sie können
              jetzt waschen“ oder unter „Meine Reservierung“ lediglich auf den Link zum Fernöffnen klicken.
            </p>
            <p>
              Die Tür sollte innerhalb von 60 Sekunden geöffnet werden können. In dieser Zeit darf der elektronische
              Schlüssel nicht benutzt werden. Ist die Tür nach einer Minute noch nicht offen, probieren Sie es erneut
              oder rufen Sie bei der Servicenummer{' '}
              <Hyperlink
                id="faq-laundry-customer-number-open-without-key"
                customHref={true}
                to={`tel:${LAUNDRY_CUSTOMER_NUMBER}`}
              >
                {LAUNDRY_CUSTOMER_NUMBER}
              </Hyperlink>{' '}
              an.
            </p>
            <p>
              Bitte beachten Sie, dass über diese Funktion lediglich die Waschküchentür geöffnet werden kann (keine
              andere Tür). Zur Aktivierung der Stromversorgen benötigen Sie Ihre Service-Karte.
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Was kann ich tun, wenn ich meine Wäsche in der Waschküche vergessen haben?',
        accordionBody: (
          <>
            <p>
              Wenn Sie Ihre Wäsche vergessen haben, können Sie auch nach Ablauf Ihrer Waschtag-Reservierung innerhalb
              einer Stunde die Waschküche betreten. Allerdings nur, wenn die Waschküche danach nicht sofort wieder
              besetzt ist.
            </p>
            <p>
              Die Tür zur Waschküche kann in diesem Fall über eine Funktion im Mieter*innen-Portal oder im
              Online-Service von „naTÜRlich sicher“ geöffnet werden Dafür müssen Sie in der Erinnerung „Sie können jetzt
              waschen“ oder unter „Meine Reservierung“ lediglich auf den Link zum Fernöffnen klicken. Die Tür sollte
              innerhalb von 60 Sekunden geöffnet werden können. Ist die Tür nach einer Minute noch nicht offen,
              probieren Sie es erneut oder rufen Sie bei der Servicenummer{' '}
              <Hyperlink
                id="faq-laundry-customer-forgot-something"
                customHref={true}
                to={`tel:${LAUNDRY_CUSTOMER_NUMBER}`}
              >
                {LAUNDRY_CUSTOMER_NUMBER}
              </Hyperlink>{' '}
              an.
            </p>
            <p>Bitte beachten Sie, dass in dieser Zeit der elektronische Schlüssel nicht benutzt werden darf.</p>
          </>
        ),
      },
    ],
  },
  servicecard: {
    accordionHeading: 'Service-Karte',
    accordionQandA: [
      {
        accordionTitle: 'Was ist die Wiener Wohnen Service-Karte?',
        accordionBody: (
          <>
            <p>
              Die Wiener Wohnen Service-Karte ist Ihre Kundenkarte von Wiener Wohnen. Sie wird nur an Mieter*innen von
              Wiener Wohnen ausgegeben.
            </p>
            <p>
              Es gibt eine physische Service-Karte im Scheckkarten-Format und eine digitale Version der Service-Karte in
              der Wiener Wohnen App.
            </p>
            <h4>Rabatte bei Partnerunternehmen</h4>
            <p>
              Mieter*innen von Wiener Wohnen können bei Vorzeigen der Service-Karte exklusive Rabatte und Vorteile bei
              unseren Kooperationspartner*innen beziehen.
            </p>
            <h4>Schlüsselfunktion</h4>
            <p>
              Sie können mit Ihrer physischen Service-Karte das Wiener Wohnen Service-Center und die Waschküchen
              aufsperren. Halten Sie dazu Ihre physische Service-Karte an den Sensor neben der Tür. Nach Aktivierung der
              Zahlungsfunktion können Sie mit Ihrer physischen Service-Karte das Einzahlungsfoyer des Service-Centers
              aufsperren und haben somit 24h Zugang zu den Kassenautomaten.
            </p>
            <h4>Hinweis zur digitalen Service-Karte</h4>
            <p>
              Bitte beachten Sie, dass die digitale Service-Karte in der Wiener Wohnen App derzeit nur bei den
              Kooperationspartner*innen, und nicht als Zugangsschlüssel für Waschküchen und Service-Center verwendet
              werden kann
            </p>
          </>
        ),
      },
      {
        accordionTitle:
          'Kann ich mit der digitalen Service-Karte in der Wiener Wohnen App auch die Waschküchen und das Service-Center aufsperren?',
        accordionBody: <p>Das ist derzeit leider noch nicht möglich.</p>,
      },
      {
        accordionTitle: 'Wer kann die Wiener Wohnen Service-Karte beantragen?',
        accordionBody: (
          <>
            <p>
              Die Wiener Wohnen Service-Karte ist die Kundenkarte von Wiener Wohnen. Sie wird daher nur an Mieter*innen
              von Wiener Wohnen ausgegeben.
            </p>
            <p>
              Alle Hauptmieter*innen von Wiener Wohnen haben ihre Service-Karte per Post erhalten. Wenn Sie noch keine
              Service-Karte haben oder eine zusätzliche Karte benötigen, können Sie diese über das{' '}
              <Hyperlink
                id="faq-service-card-register-1"
                isExternal={true}
                to={SERVICECARD_REGISTER_LINK}
                iconAfter={SVG.external_link}
              >
                Anmeldeformular
              </Hyperlink>{' '}
              bestellen.
            </p>
            <h4>Bei Verlust der physischen Karte</h4>
            <p>
              Die erste Karte ist für Sie völlig kostenlos. Sollten Sie eine neue Karte bei Wiener Wohnen anfordern
              (Diebstahl, Bruch, Zusatzkarte etc.), wird ein Kostenersatz (7,20 Euro) verrechnet. Der Betrag wird
              automatisch mit der Miete vorgeschrieben.
            </p>
            <p>
              <strong>Bitte melden Sie uns in jedem Fall den Verlust, damit die Karte gesperrt werden kann</strong> (
              <Hyperlink id="faq-ww-service-mail-1" customHref={true} to={`mailto:${CUSTOMER_SERVICE_MAIL}`}>
                per E-Mail
              </Hyperlink>{' '}
              oder über die Service-Nummer unter{' '}
              <Hyperlink id="faq-service-card-info-tel-1" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>
              )
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie erhalte ich Zutritt zu den Kassenautomaten?',
        accordionBody: (
          <>
            <p>
              Die Außentüre des getrennt begehbaren Einzahlungsfoyers im Service-Center ist mit Ihrer Service-Karte auch
              an Wochenenden und Feiertagen rund um die Uhr für Sie geöffnet. Halten Sie einfach Ihre Karte vor das
              Lesegerät.
            </p>
            <p>
              <em>Achtung:</em> Diese Funktion der Service-Karte ist derzeit nur für die physische Karte verfügbar und
              nicht automatisch aktiviert. Wenn Sie diese Funktion nutzen wollen wenden Sie sich bitte an die
              Service-Nummer:{' '}
              <Hyperlink id="faq-service-card-info-tel-2" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie funktionieren die Kassenautomaten mit der Service-Karte?',
        accordionBody: (
          <>
            <p>
              Stecken sie Ihre Service-Karte in den Kassenautomaten. Es werden automatisch alle von Ihnen gemieteten
              Mietobjekte angezeigt. Nach Auswahl eines von Ihnen gemieteten Mietgegenstandes erhalten Sie Informationen
              über die Höhe des monatlichen Mietzinses bzw. über eventuell bestehende offene Forderungen. Sie können den
              monatlichen Mietzins, eine eventuell noch nicht beglichene Forderung oder einen frei wählbaren Betrag
              einzahlen. Sie können in bar oder mit Bankomatkarte zahlen.
            </p>
            <p>
              <em>Achtung:</em> Diese Funktion der Service-Karte ist derzeit nur für die physische Karte verfügbar und
              nicht automatisch aktiviert. Wenn Sie diese Funktion nutzen wollen wenden Sie sich bitte an die
              Service-Nummer:{' '}
              <Hyperlink id="faq-service-card-info-tel-3" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>
            </p>
          </>
        ),
      },
      {
        accordionTitle: 'Wie kann ich meine Service-Karte für Einzahlungen aktivieren?',
        accordionBody: (
          <p>
            Bitte wenden Sie sich dafür an unsere Service-Nummer unter{' '}
            <Hyperlink id="faq-service-card-info-tel-4" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
              {TENANT_PORTAL_SERVICE_NUMBER}
            </Hyperlink>
            .
          </p>
        ),
      },
      {
        accordionTitle: 'Ich habe meine Service-Karte verloren. Was kann ich tun?',
        accordionBody: (
          <>
            <p>
              Die erste Karte ist für Sie völlig kostenlos. Sollten Sie eine neue Karte bei Wiener Wohnen anfordern
              (Diebstahl, Bruch, Zusatzkarte etc.), wird ein Kostenersatz (7,20 Euro) verrechnet. Der Betrag wird
              automatisch mit der Miete vorgeschrieben.
            </p>
            <p>
              <strong>Bitte melden Sie uns in jedem Fall den Verlust, damit die Karte gesperrt werden kann</strong> ({' '}
              <Hyperlink id="faq-ww-service-mail-1" customHref={true} to={`mailto:${CUSTOMER_SERVICE_MAIL}`}>
                per E-Mail
              </Hyperlink>{' '}
              oder über die Service-Nummer unter{' '}
              <Hyperlink id="faq-service-card-info-tel-5" customHref={true} to={`tel:${TENANT_PORTAL_SERVICE_NUMBER}`}>
                {TENANT_PORTAL_SERVICE_NUMBER}
              </Hyperlink>
              )
            </p>
          </>
        ),
      },
    ],
  },
};

import { IBooking, IBookingResult } from '../../commons/api/laundryDay/LaundryDay.interface';
import { IAnnouncementBooking, IReservation } from '../../laundryday/containers/LaundryDayInterface';
import { isFutureDateTime, parseISO8601, toTimeOfDay } from '../../commons/utility/DateFunctions';

/**
 * transforms the HAB structure into an array of IReservation. This makes everything easier to deal with. Additionally, old entries are filtered, so
 * only entries that have an end date in the future are displayed. Exception: using offsetForBookingsInThePast allows you to display bookings up to the
 * set offset.
 *
 * @param bookingResults results from HAB
 * @param offsetForBookingsInThePast an offset in case you are interested into bookings from the past (default: 0)
 */
export function transformBookingResults(
  bookingResults: IBookingResult[],
  offsetForBookingsInThePast: number = 0
): IReservation[] {
  let reservationList: IReservation[] = [];
  bookingResults.forEach((bookingResult: IBookingResult) => {
    const reservations = bookingResult.bookings
      .filter((booking: IBooking) => isFutureDateTime(booking.date, booking.end, offsetForBookingsInThePast))
      .map(
        (booking: IBooking) =>
          ({
            date: booking.date,
            timeslotCode: booking.accessModelCode,
            start: booking.start,
            end: booking.end,
            message: booking.message,
            washerBroken: booking.washerBroken,
            dryerBroken: booking.dryerBroken,
            defectMessage: booking.defectMessage,
            remoteOpening: booking.remoteOpening,
            laundry: {
              id: booking.laundryNo,
              street: booking.street,
              houseNumber: booking.houseNumber,
              stair: booking.stair,
              laundryNumber: booking.laundry,
            },
            rentalObject: {
              street: bookingResult.street,
              houseNumber: bookingResult.houseNumber,
              stair: bookingResult.stair,
              door: bookingResult.door,
              city: bookingResult.city,
              zip: bookingResult.zip,
            },
          } as IReservation)
      );
    reservationList = reservationList.concat(reservations);
  });
  return reservationList.sort((a, b) => a.date - b.date || a.start.localeCompare(b.start));
}

export function compareReservations(reservation1: IReservation, reservation2: IReservation) {
  return (
    reservation1.laundry.id === reservation2.laundry.id &&
    reservation1.date === reservation2.date &&
    reservation1.start === reservation2.start
  );
}

export function compareReservationAndAnnouncementBooking(
  reservation: IReservation,
  announcementBooking: IAnnouncementBooking
) {
  return (
    reservation.laundry.id === announcementBooking.laundryNumber &&
    toTimeOfDay(reservation.date, reservation.start) === parseISO8601(announcementBooking.startingAt)
  );
}

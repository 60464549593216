import React, { Component } from 'react';
import { piwikEvents } from '../../commons/utility/piwikEvents';
import { TrackingContext } from 'digit.commons.ui-components';

class TrackingContextProvider extends Component<{}, {}> {
  render() {
    return <TrackingContext.Provider value={piwikEvents}>{this.props.children}</TrackingContext.Provider>;
  }
}

export { TrackingContextProvider };

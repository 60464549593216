import React from 'react';
import { Badge, Card, CardBody, Hyperlink, SVG } from 'digit.commons.ui-components';
import '../../../portal/components/Announcement/Announcement.scss';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';
import { ANNOUNCEMENT } from '../../constants/components/announcement';
import { DETAILS_ROUTE } from '../../constants/routes';
import { ApplicationFormUtility } from '../../utility/ApplicationFormUtility';
import { IProcessData } from '../../decisionTree/interfaces/DecisionTree.interface';

interface IFormAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  type: string;
  showDetails?: boolean;
  onClose?: () => void;
}

const FormAnnouncement: React.FC<IFormAnnouncementProps> = props => {
  const { id, announcement } = props;
  const { actions, badge, title, body, badgeModifier } = ANNOUNCEMENT[props.type];
  const formAnnouncementType = props.type.replace('_', '-').toLowerCase();

  const close = async () => {
    await announcement.closeFunction();
    props.onClose && props.onClose();
  };

  return (
    <Card
      id={`${id}-form-${formAnnouncementType}-announcement`}
      className="Announcement Announcement__form"
      onDismiss={close}
      dismissDescribedById={`${id}-form-${formAnnouncementType}-announcement-header-id`}
    >
      <header id={`${id}-form-${formAnnouncementType}-announcement-header-id`}>
        <Badge
          id={`${id}-form-${formAnnouncementType}-announcement`}
          modifier={badgeModifier}
          iconBefore={SVG.edit_doc}
        >
          {badge}
        </Badge>
        <h3>{title}</h3>
      </header>
      <CardBody>
        <>
          {body(formName(announcement), announcement.data.processCode)}
          <Hyperlink
            id={`${id}-form-${formAnnouncementType}-announcement`}
            to={formDetailRoute(announcement)}
            iconAfter="arrow-right"
          >
            {actions.showDetails}
          </Hyperlink>
        </>
      </CardBody>
    </Card>
  );
};

const formName = (announcement: IAnnouncement): String => {
  const name = ApplicationFormUtility.getFormDescription(
    announcement.data.form.theme,
    announcement.data.form.workflow,
    JSON.parse(announcement.data.form.json) as IProcessData,
    null
  );
  if (name.toLowerCase().startsWith('antrag ')) return name.substr(7);
  else return name;
};

const formDetailRoute = (announcement: IAnnouncement): String =>
  DETAILS_ROUTE.replace(':applicationFormId', announcement.data.form.shortId);

export default FormAnnouncement;

import React, { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, ErrorBox, Icon, ITextAreaHandles, SVG, TextArea } from 'digit.commons.ui-components';
import FeedbackHeading from '../FeedbackHeading/FeedbackHeading';
import FeedbackStars, { IFeedbackStarsHandles } from './FeedbackStars/FeedbackStars';
import './Feedback.scss';
import { piwikEvents } from '../../../../commons/utility/piwikEvents';

import FeedbackApi from '../../../../commons/api/feedback/Feedback.api';
import { FEEDBACK } from '../../../constants/containers/feedback-labels';
import { FEEDBACK_ERROR } from '../../../constants/errors';

export interface IRating {
  isSubmitted: boolean;
  optionalText: string;
  questions: IQuestion;
}

interface IQuestion {
  [question: string]: number;
}

interface IError {
  formError: boolean;
  rateLimitError: boolean;
  apiError: boolean;
}

export interface IFeedbackHandles {
  resetFeedbackHandler: () => void;
  getRating: () => IRating;
}

interface IFeedbackProps {
  idPrefix: string;
  className?: string;
  larger?: boolean;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  existingRating: IRating;
  isMobile?: boolean;
}

const Feedback: React.ForwardRefRenderFunction<IFeedbackHandles, IFeedbackProps> = (props, ref) => {
  const [rating, setRating] = useState<IRating>({
    isSubmitted: false,
    questions: {},
    optionalText: '',
  });
  const [error, setError] = useState<IError>({ formError: false, rateLimitError: false, apiError: false });

  const feedbackRef = useRef<HTMLDivElement>();
  const starRefs = useRef<RefObject<IFeedbackStarsHandles>[]>(
    FEEDBACK.questions.map(() => React.createRef<IFeedbackStarsHandles>())
  );
  const textAreaRef = useRef<ITextAreaHandles>();

  useImperativeHandle(ref, () => ({
    resetFeedbackHandler: () => {
      if (starRefs.current) {
        starRefs.current.forEach(starRef => starRef.current.setChosenState(0));
      }
      if (textAreaRef.current) {
        textAreaRef.current.resetState();
      }
      setRating({
        isSubmitted: false,
        questions: {},
        optionalText: '',
      });
      setError({
        apiError: false,
        rateLimitError: false,
        formError: false,
      });
    },
    getRating: () => rating,
  }));

  useEffect(() => {
    if (props.existingRating) {
      setRating(props.existingRating);
      FEEDBACK.questions.forEach((question, index) => {
        if (props.existingRating.questions.hasOwnProperty(question.text)) {
          starRefs.current[index].current.setChosenState(props.existingRating.questions[question.text]);
        }
      });
    }
  }, []);

  /**
   * After submitting confirmation box renders for both mobile and tablet version. Confirmation box can
   * render only in case of no errors. Tablet version has an X close icon
   */
  const renderConfirmationBox = (): JSX.Element => {
    return (
      <div className={'Feedback__confirmation'} onClick={props.onClick}>
        <button className={'Feedback__icon-close'} autoFocus={true}>
          <span className="sr-only">Feedback schließen</span>
          <Icon icon={SVG.close} />
        </button>
        <span role="alert" className="sr-only" aria-live="polite">
          {FEEDBACK.thankYou}
        </span>
        <div className={'Feedback__icon-letter'}>
          <Icon icon={SVG.letter} />
          <p className={'Feedback__finish--inline'}> {FEEDBACK.thankYou} </p>
        </div>
        <div>
          <div className={'Feedback__overlay-container'}>
            <Icon icon={SVG.letter} className={'Feedback__icon-letter Feedback__icon-letter--large'} />
            <p className={'Feedback__finish--overlay'}> {FEEDBACK.thankYou} </p>
          </div>
        </div>
      </div>
    );
  };

  const renderQuestions = FEEDBACK.questions.map((question: any, index) => {
    return (
      <div key={question.id} className={props.className}>
        <FeedbackStars
          id={props.idPrefix + '-' + question.id}
          className={'Feedback__stars'}
          name={question.text}
          question={question.text}
          isStarRatingEmpty={error.formError && !rating.questions.hasOwnProperty(question.text)}
          onClick={e => onClickStarHandler(e)}
          onKeyDown={props.onKeyDown}
          ref={starRefs.current[index]}
        />
      </div>
    );
  });

  /** Handler that updates the state with the current star selected */
  const onClickStarHandler = event => {
    setRating({
      ...rating,
      questions: {
        ...rating.questions,
        [event.target.name]: event.target.value,
      },
    });
  };

  /** Since onMouseLeave doesn't work properly, this mechanism resets the star ratings (hovering) state at certain points of the feedback formular */
  const resetFeedbackStarsHandler = (e: any) => {
    e.preventDefault();
    starRefs.current.forEach(starRef => starRef.current.resetState());
  };

  /** Handler that updates the state with value of the TextArea Component */
  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRating({
      ...rating,
      optionalText: event.target.value,
    });
  };

  /**
   * After each star onClick event, onSubmit is called which sends an HTTP POST request to the server
   * with the data contained currentQuestion state
   * Counter is incremented by 1 after each submit so the next question in the array is set to be the
   * currentQuestion
   */
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let scrollToIndex;
    FEEDBACK.questions.forEach((question, index) => {
      if (!scrollToIndex && !rating.questions.hasOwnProperty(question.text)) {
        scrollToIndex = index;
      }
    });
    const hasFormErrors = scrollToIndex >= 0;

    if (!hasFormErrors) {
      try {
        await FeedbackApi.postSubmitFeedback(rating);
        piwikEvents.trackEvent('Feedback', 'Feedback', 'Feedback wurde erfolgreich abgesendet');
        setRating({
          ...rating,
          isSubmitted: true,
        });
        setError({
          apiError: false,
          rateLimitError: false,
          formError: false,
        });
      } catch (error) {
        if (error.code === 429) {
          setError({
            ...error,
            rateLimitError: true,
          });
        } else {
          setError({
            ...error,
            apiError: true,
          });
          piwikEvents.trackEvent('Feedback', 'Feedback', 'Feedback konnte nicht erfolgreich abgesendet werden');
        }
      }
      feedbackRef.current.scrollIntoView();
    } else {
      setError({
        ...error,
        formError: true,
      });
      starRefs.current[scrollToIndex].current.scrollIntoViewAndFocus();
    }
  };

  return (
    <div className={'Feedback'} ref={feedbackRef}>
      {rating.isSubmitted ? (
        renderConfirmationBox()
      ) : (
        <>
          <div className={'Feedback__wrapper-desktop'} onKeyDown={props.onKeyDown}>
            {!props.isMobile && (
              <div id={`${props.idPrefix}-heading-id`} className={'Feedback__feedbackheading--desktop'}>
                <FeedbackHeading
                  isMobile={false}
                  className={'Feedback__feedbackheading-desktop'}
                  onClick={props.onClick}
                />
              </div>
            )}
            {error.apiError ? (
              <ErrorBox
                id={'feedback-error'}
                title={FEEDBACK_ERROR.submitError.title}
                description={FEEDBACK_ERROR.submitError.description}
              />
            ) : (
              <form className={'Feedback__form'} onSubmit={onSubmitHandler}>
                {renderQuestions}
                <p
                  id={`${props.idPrefix}-textarea-label-id`}
                  className={'FeedbackStars__legend'}
                  onMouseOver={resetFeedbackStarsHandler}
                >
                  {FEEDBACK.textAreaTitle}
                </p>

                <TextArea
                  id={props.idPrefix}
                  aria-describedby={`${props.idPrefix}-textarea-label-id`}
                  label={FEEDBACK.textArea}
                  maxLength={500}
                  required={false}
                  value={(rating.optionalText as string) || ''}
                  onChange={onChangeHandler}
                  ref={textAreaRef}
                />
                {error.rateLimitError && (
                  <div id={`${props.idPrefix}-div-ratelimit-id`} className={`Feedback__error`} role={'alert'}>
                    {FEEDBACK.error.rateLimit}
                  </div>
                )}
                <p className={'Feedback__info'} onMouseOver={resetFeedbackStarsHandler}>
                  {FEEDBACK.hint}
                </p>
                <Button
                  id={`${props.idPrefix}-submit`}
                  className={'Feedback__button-submit'}
                  disabled={error.rateLimitError}
                >
                  {FEEDBACK.submitButtonText}
                </Button>
              </form>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default forwardRef(Feedback);

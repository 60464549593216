import React, { ReactElement } from 'react';
import { ITenantData } from '../../commons/api/tenant/Tenant.interface';

export const TenantDataContext = React.createContext<ITenantData>(null);

export const TenantDataContextProvider: React.FC<{
  tenantData: ITenantData;
  children: ReactElement;
}> = props => {
  return <TenantDataContext.Provider value={props.tenantData}>{props.children}</TenantDataContext.Provider>;
};

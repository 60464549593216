import React from 'react';
import { Helmet } from 'react-helmet';
import { DATA_PROTECTION_LABELS_APP } from '../../constants/containers/data-protection-app-labels';
import { Accordion, AccordionItem, Section } from 'digit.commons.ui-components';
import '../DataProtection/DataProtection.scss';

const DataProtectionApp: React.FC = () => {
  const { title, pageTitle, info, accordion, meta } = DATA_PROTECTION_LABELS_APP;

  const renderAccordion = (accordion: object[]) => {
    return accordion.map((element: any, index: number) => {
      return (
        <AccordionItem key={index} index={index} title={element.accordionTitle}>
          {element.accordionBody}
        </AccordionItem>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charSet={'utf-8'} />
        <meta name={'description'} content={meta} />
      </Helmet>
      <Section id={'data-protection-app'} className={'DataProtection'} title={title} removePaddingBottom>
        <span className={'sr-only'} role={'alert'} aria-live={'assertive'}>
          <em>{title}</em> wurde geladen
        </span>
        <p className={'DataProtection__p'}>{info}</p>
        <Accordion id={'data-protection-app'} name={'faq'}>
          {renderAccordion(accordion)}
        </Accordion>
      </Section>
    </>
  );
};

export default DataProtectionApp;

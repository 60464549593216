import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

import './Header.scss';
import { IHeaderDBState, IHeaderProps, IHeaderState } from './Header.interface';
import { IState } from '../../containers/State.interface';
import { Hyperlink, Icon, SVG } from 'digit.commons.ui-components';

import BypassMenu from '../BypassMenu/BypassMenu';
import { DATA_PROTECTION_APP, LANDING, PRELANDING } from '../../constants/routes';
import { HEADER } from '../../constants/component-labels';

import { HEADER_MENU } from './header-menu';
import { piwikEvents } from '../../../commons/utility/piwikEvents';
import { Configuration } from '../../../Configuration';
import { AuthenticationState } from '../../../AuthenticationState';
import Popover from '../Popover/Popover';
import { FORMATTER } from '../../utility/formatHandler';
import {
  APPLICATION_FORM_BASE_ROUTE,
  DETAILS_BASE_ROUTE,
  WIZARD_ROUTE,
} from '../../../applicationform/constants/routes';
import { LAUNDRY_DAY_BOOKING_ROUTE } from '../../../laundryday/constants/routes';
import { DOCUMENT_ARCHIVE_BASE_ROUTE } from '../../../documentarchive/constants/routes';
import { TenantDataContext } from '../../context/TenantDataContext';
import { ITenantData } from '../../../commons/api/tenant/Tenant.interface';

const Header: React.FC<IHeaderProps> = props => {
  const [headerState, setHeaderState] = useState<IState<IHeaderDBState> & IHeaderState>({
    fields: {},
    errors: [],
    mobileMenuOpen: false,
    updateHeader: false,
    isLoadingTenantData: false,
  });
  const [hideHeader, setHideHeader] = useState<boolean>(false);
  const history = useHistory();
  const { mobileMenuOpen } = headerState;
  const { isLoggedIn, isOtfuLoggedIn, isContractInvalid, isInDownTime, focusOnTitleHandler } = props;
  const { sr_menu, burgerClose, burgerMenu, myProfile, logout, login } = HEADER;
  const { tenant } = useContext<ITenantData>(TenantDataContext);

  const { firstName, lastName } = headerState.fields;

  useEffect(() => {
    if (AuthenticationState.isLoggedIn) {
      getTenantById();
    } else {
      setHeaderState(headerState => ({
        ...headerState,
        errors: [...headerState.errors, 'getTenantById'],
        isLoadingTenantData: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!headerState.isLoadingTenantData) {
      if (AuthenticationState.isLoggedIn) {
        getTenantById();
      } else {
        setHeaderState(headerState => ({
          ...headerState,
          errors: [...headerState.errors, 'getTenantById'],
          isLoadingTenantData: false,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerState.fields]);

  useEffect(() => {
    if (headerState.fields && headerState.isLoadingTenantData) {
      setHeaderState(headerState => ({
        ...headerState,
        isLoadingTenantData: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerState.fields]);

  useEffect(() => shouldShowHeader(history.location.pathname), [history.location.pathname]);

  /**
   * KUN-2473 - in some cases we don't want to show the header, i.e., full screen dialogs,
   * these are shown during the application forms and booking a laundry day.
   *
   * @param pathname the current URL in the application
   */
  const shouldShowHeader = (pathname: string) => {
    let actualWizardRoute = WIZARD_ROUTE;
    if (pathname !== APPLICATION_FORM_BASE_ROUTE && !pathname.startsWith(DOCUMENT_ARCHIVE_BASE_ROUTE)) {
      actualWizardRoute = WIZARD_ROUTE.replace('/:wizard', pathname.replace('/dokumente', ''));
    }
    setHideHeader(
      pathname === LAUNDRY_DAY_BOOKING_ROUTE ||
        pathname === DATA_PROTECTION_APP ||
        (!pathname.includes(DETAILS_BASE_ROUTE) && pathname === actualWizardRoute)
    );
  };

  const scrollToTop = () => window.scrollTo(0, 0);

  const onClickMenuHandler = () => {
    setHeaderState(headerState => ({
      ...headerState,
      mobileMenuOpen: !headerState.mobileMenuOpen,
    }));
    scrollToTop();
    if (focusOnTitleHandler) {
      focusOnTitleHandler();
    }
  };

  const renderHelperCreateMenu = () =>
    HEADER_MENU.items.map(item => (
      <li className={'Header__menu-item'} key={item.id}>
        <NavLink
          to={item.to}
          id={item.id}
          exact={true}
          className={'Header__link'}
          activeClassName={'Header__link Header__link--selected'}
          onClick={onClickMenuHandler}
        >
          {item.name}
        </NavLink>
      </li>
    ));

  const renderHelperNavBarSearchBar = () => {
    let menuStatus: string;
    if (mobileMenuOpen) {
      menuStatus = '';
    } else {
      menuStatus = 'Header__menu Header__menu--closed';
    }

    if (isLoggedIn) {
      return (
        <nav className={`Header__menu ${menuStatus}`} aria-labelledby={'main-menu'} id={'header-menu-id'}>
          <h2 id={'main-menu'} className={'sr-only'}>
            {sr_menu}
          </h2>
          <ul className={'Header__menu-list'}>{renderHelperCreateMenu()}</ul>
        </nav>
      );
    }
    return null;
  };

  const getTenantById = async () => {
    setHeaderState(headerState => ({ ...headerState, isLoadingTenantData: true }));
    try {
      if (tenant) {
        setHeaderState(headerState => ({
          ...headerState,
          fields: {
            academicTitle: tenant.details.academicTitle,
            gender: tenant.details.gender,
            firstName: tenant.details.firstName,
            lastName: tenant.details.lastName,
            gepartId: tenant.details.gepartId,
          },
        }));
      }
    } catch (error) {
      if (error.message !== null) {
        setHeaderState(headerState => ({
          ...headerState,
          errors: [...headerState.errors, 'getTenantById'],
          isLoadingTenantData: false,
        }));
        piwikEvents.trackEvent('technischer Fehler', 'Header', 'Fehler beim Laden des Headers');
      }
    }
  };

  return hideHeader ? (
    <></>
  ) : (
    <header id={'header-id'} className={'Header'}>
      {isLoggedIn && <BypassMenu triggerNav={onClickMenuHandler} />}
      <div className={'Header__content-area'}>
        <div className={'Header__content'}>
          <div className={'Header__container'}>
            <h1 className={'Header__logo-box'}>
              <Link to={isLoggedIn ? LANDING : PRELANDING} className={'Header__logo'}>
                <Icon className={'Header__wwlogo--small'} icon={SVG.stadt_wien_logo} alt={'wiener wohnen logo'} />
                <Icon className={'Header__wwlogo'} icon={SVG.ww_logo} alt={'wiener wohnen logo'} />
                <span className={'sr-only'}>Wiener Wohnen Mieter*innenportal</span>
              </Link>
            </h1>
            {isLoggedIn && (
              <button
                className={'Header__mobile-menu-button'}
                onClick={onClickMenuHandler}
                aria-expanded={mobileMenuOpen}
              >
                <span className="Header__mobile-menu-button-icon">
                  {mobileMenuOpen ? <Icon icon={SVG.close} /> : <Icon icon={SVG.burger_menu} />}
                </span>
                <span className={'Header__mobile-menu-button--text'}>{mobileMenuOpen ? burgerClose : burgerMenu}</span>
              </button>
            )}
          </div>
          {isInDownTime ? null : isLoggedIn ? (
            <div className={'Header__icons-wrapper'}>
              <div className={'Header__svg-preload'} aria-hidden={true}>
                <Icon icon={SVG.profile} />
                <Icon icon={SVG.logout} />
              </div>
              <Popover id={'header'} user={`${FORMATTER.firstNameLastNameLabel(firstName, lastName)}`}>
                <li>
                  <Hyperlink
                    id={'header-profile'}
                    className={'Header__link--grey'}
                    to={'/profil'}
                    iconBefore={SVG.profile}
                  >
                    {myProfile}
                  </Hyperlink>
                </li>
                <li>
                  <Hyperlink
                    id={'header-popover-logout'}
                    className={'Header__link--grey'}
                    to={Configuration.idpConfig().logoutUrl}
                    isExternal={true}
                    iconBefore={SVG.logout}
                    openSameTab={true}
                  >
                    {logout}
                  </Hyperlink>
                </li>
              </Popover>
            </div>
          ) : (
            !isOtfuLoggedIn &&
            !isContractInvalid && (
              <Hyperlink
                id={'header-login'}
                className={'Header__link--grey'}
                to={Configuration.idpConfig().loginWithPasswordUrl}
                iconBefore={SVG.logout}
                isExternal
                openSameTab
              >
                {login}
              </Hyperlink>
            )
          )}
        </div>
      </div>
      {isInDownTime ? <></> : renderHelperNavBarSearchBar()}
      <div className={'Header__decoration'} />
    </header>
  );
};

export default Header;

export default class LaundryDaySCSInitializer {
  private static configuration: ILDConfiguration;
  private static DEFAULT_FETCH_TIMEOUT = 15000;
  private static authRequiredHandler: () => void;

  static apiConfig(): ILDApiConfig {
    return LaundryDaySCSInitializer.configuration.api;
  }

  static queryConfig(): ILDQueryConfig {
    return LaundryDaySCSInitializer.configuration.query
      ? LaundryDaySCSInitializer.configuration.query
      : { timeoutInMillis: this.DEFAULT_FETCH_TIMEOUT };
  }

  static init(configuration: ILDConfiguration, authenticationRequiredHandler: () => void) {
    LaundryDaySCSInitializer.configuration = configuration;
    LaundryDaySCSInitializer.authRequiredHandler = authenticationRequiredHandler;
  }

  static piwikConfig(): IPiwikConfig {
    return LaundryDaySCSInitializer.configuration.piwik;
  }

  static authenticationRequiredHandler(): () => void {
    return LaundryDaySCSInitializer.authRequiredHandler;
  }
}

export interface ILDConfiguration {
  api: ILDApiConfig;
  query: ILDQueryConfig;
  piwik: IPiwikConfig;
}

interface ILDApiConfig {
  tenantApi: string;
  laundryDayApi: string;
  paths: ILDPaths;
}

interface ILDPaths {
  pathToGraphQL: string;
}

export interface ILDQueryConfig {
  timeoutInMillis: number;
}

interface IPiwikConfig {
  PIWIK: string;
  PIWIK_SITE_ID: number;
  enableHeatMapSessionRecording: boolean;
}

import { Hyperlink } from 'digit.commons.ui-components';
import { DATA_PROTECTION, IMPRESSUM } from '../routes';
import React from 'react';

export const COOKIE_LAYOVER_LABELS = {
  title: 'Cookie-Einstellungen',
  contentInfo: (
    <>
      Wir verwenden eigene und fremde Cookies um unsere Services zu verbessern. Mehr Infos zur Verwendung sowie zur
      (De)Aktivierung von Cookies erhalten Sie in unseren Informationen zum{' '}
      <Hyperlink id={'cookie-layover-dataprotection-1'} to={DATA_PROTECTION}>
        Datenschutz.
      </Hyperlink>
      <span className="CookieLayover__impressumLink">
        <Hyperlink id={'cookie-layover-impressum'} to={IMPRESSUM}>
          Impressum
        </Hyperlink>
      </span>
    </>
  ),
  cookiesWhichAreRequired: 'Unbedingt notwendige Cookies',
  statisticalCookies: 'Statistik-Cookies',
  aboutCookies: 'Über Cookies',
  aboutCookiesArticleContent: (
    <>
      <section>
        <p>
          Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu
          gestalten.
        </p>
        <p>
          Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt
          notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis.
        </p>
        <p>
          Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die
          auf unseren Seiten erscheinen. Sie können Ihre Einwilligung jederzeit über Cookie-Einstellungen auf unserer
          Website ändern oder widerrufen. Erfahren Sie in unseren Informationen zum{' '}
          <Hyperlink id={'cookie-layover-dataprotection-2'} to={DATA_PROTECTION}>
            Datenschutz.
          </Hyperlink>{' '}
          mehr darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten.
        </p>
      </section>
      <section>
        <header>Unbedingt notwendige Cookies</header>
        <p>
          Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen wie
          Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite kann ohne diese
          Cookies nicht richtig funktionieren.
        </p>
      </section>
      <section>
        <header>Statistik-Cookies</header>
        <p>
          Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem
          Informationen anonym gesammelt und gemeldet werden.
        </p>
      </section>
      <footer>
        <p>
          Eine detaillierte Auflistung aller verwendeten Cookies finden Sie in unseren Informationen zum{' '}
          <Hyperlink id={'cookie-layover-dataprotection-3'} to={DATA_PROTECTION}>
            Datenschutz.
          </Hyperlink>
        </p>
      </footer>
    </>
  ),
  confirmChoice: 'Auswahl bestätigen',
  confirmAll: 'Alle auswählen',
};

import React from 'react';
import './FeedbackHeading.scss';
import { IFeedbackHeadingProps } from './FeedbackHeading.interface';
import { Icon, SVG } from 'digit.commons.ui-components';
import { FEEDBACK_HEADER } from '../../../constants/containers/feedback-labels';

const FeedbackHeading = ({ onClick, isMobile }: IFeedbackHeadingProps) => {
  const { headerText, descriptionText, descriptionText2 } = FEEDBACK_HEADER;

  return (
    <header className={'FeedbackHeading'}>
      <div className={'FeedbackHeading__close-box'}>
        <button
          className={'FeedbackHeading__close'}
          onKeyPress={onClick}
          onClick={onClick}
          aria-label={'Feedback schließen'}
        >
          <Icon className={'FeedbackHeading__icon'} icon={SVG.close} />
        </button>
      </div>
      <h2 className={'FeedbackHeading__h3'}>{headerText}</h2>
      <div className={'FeedbackHeading__paragraph'}>{descriptionText}</div>
      <div className={'FeedbackHeading__paragraph'}>{descriptionText2(isMobile)}</div>
    </header>
  );
};

export default FeedbackHeading;

export {};
declare global {
  interface Array<T> {
    isNullOrEmpty(): boolean;
  }
}

if (!Array.prototype.isNullOrEmpty) {
  Array.prototype.isNullOrEmpty = function <T>(this: T[]): boolean {
    // @ts-ignore
    return this == null || this == undefined || this.length == 0;
  };
}

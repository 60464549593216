import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { mutationAcknowledgeContent, queryContent } from './Content.graphql';
import { IContent } from './Content.interface';
import { IExtendedError } from '../ExtendedError.interface';
import { IGraphQL } from '../../interfaces/GraphQL.interface';
import AbstractRequestApi from '../AbstractRequestApi';

interface IRawContentNode {
  json: string;
  publishDate: Date;
  tags: string[];
}

interface IRawContent {
  content: IRawContentNode[];
}

class ContentApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().contentApi,
      'ContentApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  fetchContent<T>(category: string, history: boolean = false): Promise<IGraphQL<IContent<T>>> {
    return this.makeCacheDisabledGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryContent(category, history),
      'queryContent'
    )
      .then((response: IGraphQL<IRawContent>) => {
        return {
          data: {
            contentNodes: response.data.content.map(raw => {
              return {
                content: JSON.parse(raw.json),
                publishDate: new Date(raw.publishDate),
                tags: raw.tags,
              };
            }),
          },
          extensions: response.extensions,
        } as IGraphQL<IContent<T>>;
      })
      .catch((error: IExtendedError) => {
        return this.handleResponse(error);
      });
  }

  acknowledgeContent(category: string): Promise<boolean> {
    const query = {
      query: mutationAcknowledgeContent(category),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'acknowledgeContent', query)
      .then<boolean>((response: any) => response.data.ackContent)
      .catch((error: IExtendedError) => {
        this.handleResponse(error);
        return null;
      });
  }
}

export default new ContentApi();

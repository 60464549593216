import React from 'react';

export const ANNOUNCEMENT = {
  FORM_COMPLETED: {
    badge: 'Neuer Status',
    badgeModifier: 'GREEN',
    title: 'Antrag wurde fertig bearbeitet',
    body: (name: String, code: String): JSX.Element => {
      return (
        <p className={'Announcement__description'}>
          Ihr Antrag <em>{name}</em> ({code}) wurde von uns fertig bearbeitet. Das Ergebnis finden Sie auf der
          Detailseite.
        </p>
      );
    },
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
  INFORMATION_PENDING: {
    badge: 'Neuer Status',
    badgeModifier: 'RED',
    title: 'Informationen ausständig',
    body: (name: String, code: String): JSX.Element => {
      return (
        <p className={'Announcement__description'}>
          Bei Ihrem Antrag <em>{name}</em> ({code}) sind Informationen ausständig, ohne diese Ihr Antrag nicht genehmigt
          werden kann. Bitte übermitteln Sie uns diese so rasch wie möglich.
        </p>
      );
    },
    actions: {
      showDetails: 'Informationen nachreichen',
      closeReminder: 'Erinnerung schließen',
    },
  },
  FURTHER_REVIEW: {
    badge: 'Neuer Status',
    badgeModifier: 'RED',
    title: 'Weitere Prüfung',
    body: (name: String, code: String): JSX.Element => {
      return (
        <p className={'Announcement__description'}>
          Bei Ihrem Antrag <em>{name}</em> ({code}) sind weitere Überprüfungen notwendig, ohne diese Ihr Antrag nicht
          genehmigt werden kann. Die weiteren Schritte finden Sie auf der Detailseite.
        </p>
      );
    },
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
  FORM_REJECTED: {
    badge: 'Neuer Status',
    badgeModifier: 'RED',
    title: 'Antrag wurde abgelehnt',
    body: (name: String, code: String): JSX.Element => {
      return (
        <p className={'Announcement__description'}>
          Ihr Antrag <em>{name}</em> ({code}) konnte von uns leider nicht genehmigt werden. Informationen zu der
          Begründung finden Sie auf der Detailseite.
        </p>
      );
    },
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
  ANSWER_PENDING: {
    badge: 'Antwort ausstehend',
    badgeModifier: 'RED',
    title: 'Sie haben eine ausstehende Bestellung',
    body: (name: String, code: String): JSX.Element => (
      <p className={'Announcement__description'}>
        Sie haben eine neue <em>{name}</em> ({code}), klicken Sie hier um zu den Details zu gelangen und die Bestellung
        zu akzeptieren oder abzulehnen.
      </p>
    ),
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
  FINISH_PENDING: {
    badge: 'Fertigstellung ausstehend',
    badgeModifier: 'RED',
    title: 'Sie haben eine ausstehende Fertigstellung',
    body: (name: String, code: String): JSX.Element => (
      <p className={'Announcement__description'}>
        Die Fertigstellung zu <em>{name}</em> ({code}) ist ausstehend, klicken Sie hier um zu den Details zu gelangen
        und die Bestellung fertigzustellen.
      </p>
    ),
    actions: {
      showDetails: 'Details anzeigen',
      closeReminder: 'Erinnerung schließen',
    },
  },
};

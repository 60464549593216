import { Method } from './ApiMethodEnum';
import ExtendedError from './ExtendedError';
import { IExtendedError } from './ExtendedError.interface';
import { API_ERROR_MESSAGES } from '../constants/errors';
import * as Sentry from '@sentry/react';
import { IGraphQL } from '../interfaces/GraphQL.interface';
import { IAnnouncement, IAnnouncements } from './announcement/Announcement.interface';
import { Configuration } from '../../Configuration';
import { mutationCloseAnnouncement, queryAnnouncements } from './announcement/Announcement.graphql';

const STD_PORTAL_ERROR_HEADER = 'X-STDPORTAL-ERROR';
const STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
const ANTI_CSRF_TOKEN_HEADER = 'X-CSRF-Token';
const INVALID_CSRF_TOKEN_SC = 490; // Custom HTTP Status code
const NO_CONTENT = 204;

export default abstract class AbstractRequestApi {
  protected constructor(
    hostResolver: () => string,
    orig: string,
    queryConfigResolver: Function,
    onSdtpAuthenticationRequired: () => () => void = () => () => {}
  ) {
    this.orig = orig;
    this.hostResolver = hostResolver;
    this.queryConfigResolver = queryConfigResolver;
    this.onSdtpAuthenticationRequired = onSdtpAuthenticationRequired;
  }

  defaultHeader = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  readonly orig: string;
  readonly hostResolver: () => string;
  queryConfigResolver: Function;
  readonly onSdtpAuthenticationRequired: () => () => void;
  lastUsedQueryUrl: string;
  antiCsrfTokenFetchTryCount: number = 0;

  makeCacheableGraphQlRequest(url: string, query: string, operationName: string, variables?: any): Promise<any> {
    const queryStr = query
      .replace(/{\\n}+/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/{operationName}/g, operationName);
    const variablesStr = variables ? `&variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
    return this._makeRequest(
      `${url}?operationName=${operationName}&query=${encodeURIComponent(queryStr)}${variablesStr}`,
      Method.GET
    );
  }

  makeCacheDisabledGraphQlRequest(url: string, query: string, operationName: string, variables?: any): Promise<any> {
    const queryStr = query
      .replace(/{\\n}+/g, ' ')
      .replace(/\s+/g, ' ')
      .replace(/{operationName}/g, operationName);
    const variablesStr = variables ? `&variables=${encodeURIComponent(JSON.stringify(variables))}` : '';
    return this._makeRequest(
      `${url}?operationName=${operationName}&query=${encodeURIComponent(queryStr)}${variablesStr}`,
      Method.GET,
      undefined,
      {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      }
    );
  }

  async postGraphQlRequest<T>(url: string, operationName: String, body?: any, headers?: any): Promise<T> {
    const newBody = {
      ...body,
      query: body.query.replace(/{operationName}/g, operationName),
      operationName: operationName,
    };
    try {
      const response: T = await this._makeRequest(
        `${url}?operationName=${operationName}`,
        Method.POST,
        newBody,
        headers
      );
      return Promise.resolve(response);
    } catch (err) {
      if (err.code === INVALID_CSRF_TOKEN_SC) {
        // Anti-CSRF token missing or invalid
        window.sessionStorage.removeItem(ANTI_CSRF_TOKEN_HEADER);

        if (this.antiCsrfTokenFetchTryCount === 0) {
          this.antiCsrfTokenFetchTryCount++;
          // Replay the last GraphQL Query to obtain a new token
          await this._makeRequest(this.lastUsedQueryUrl, Method.GET);
          // Retry the POST
          return this.postGraphQlRequest(url, operationName, body, headers);
        } else {
          return Promise.reject(err);
        }
      } else {
        return Promise.reject(err);
      }
    }
  }

  async _makeRequest<T>(url: string, method: Method, body?: any, headers?: any): Promise<T> {
    const newHeaders = this._addHeader(headers);
    const formData = new FormData();

    if (body instanceof File) {
      formData.append('file', body);
    }

    try {
      const response = await this.timeoutWrapper(
        this.getTimeout(),
        fetch(this.hostResolver() + url, {
          headers: body instanceof File ? {} : newHeaders,
          body: body instanceof File ? formData : JSON.stringify(body) || undefined,
          credentials: 'same-origin',
          method,
        })
      );

      let stdpError = response.headers ? response.headers.get(STD_PORTAL_ERROR_HEADER) : '';
      let stdpAuthenticationRequired = stdpError && stdpError === STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED;
      if (stdpAuthenticationRequired) {
        this.onSdtpAuthenticationRequired()();
        throw new ExtendedError(403, STD_PORTAL_ERROR_AUTHENTICATION_REQUIRED, this.orig);
      }
      if (response.status === INVALID_CSRF_TOKEN_SC) {
        throw new ExtendedError(response.status, 'Anti-CSRF token invalid or missing', this.orig);
      }
      this.saveAntiCsrfToken(url, method, response);

      if (!response.ok) {
        const errorMsg: string = await response.text();
        throw new ExtendedError(response.status, errorMsg, this.orig);
      }

      return response.status === NO_CONTENT
        ? response
        : response.json().then((data: any) => this.checkForErrorData(data));
    } catch (error) {
      let actualError = error;
      if (!(error instanceof ExtendedError)) {
        actualError = new ExtendedError(
          503,
          error.message,
          this.orig,
          `fetch failed for ${url.split('&query=')[0]}`,
          error.stack
        );
      }
      Sentry.captureException(actualError);
      throw actualError;
    }
  }

  getTimeout(): number {
    return this.queryConfigResolver().timeoutInMillis;
  }

  _addHeader(optionalHeaders?: string[]): any {
    const headers = this.defaultHeader;

    if (window.sessionStorage.getItem(ANTI_CSRF_TOKEN_HEADER)) {
      headers[ANTI_CSRF_TOKEN_HEADER] = window.sessionStorage.getItem(ANTI_CSRF_TOKEN_HEADER);
    }
    if (optionalHeaders) {
      Object.keys(optionalHeaders).forEach(key => (headers[key] = optionalHeaders[key]));
    }
    return headers;
  }

  /**
   * If the response returned the Anti-CSRF token in the header, these methods saves it in browser's sessionStorage
   */
  saveAntiCsrfToken(url: string, method: Method, response: Response) {
    if (method === Method.GET && response.headers.get(ANTI_CSRF_TOKEN_HEADER)) {
      window.sessionStorage.setItem(ANTI_CSRF_TOKEN_HEADER, response.headers.get(ANTI_CSRF_TOKEN_HEADER));
      this.antiCsrfTokenFetchTryCount = 0;
      this.lastUsedQueryUrl = url;
    }
  }

  timeoutWrapper(millis: number, promise): Promise<any> {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new ExtendedError(503, `Timeout of ${millis} reached while contacting service.`, 'GeneralError'));
      }, millis);
      promise.then(
        res => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        err => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  }

  checkForErrorData(data: any) {
    if (data.errors) {
      return Promise.reject(new ExtendedError(500, `FOUND ${data.errors.length} ERRORS`, this.orig));
    }
    return data;
  }

  handleResponse(error: IExtendedError, contextInfo?: string): never {
    error.message = API_ERROR_MESSAGES.notAvailable;
    error.contextInfo = contextInfo;

    if (error.code === 404) {
      error.message = API_ERROR_MESSAGES.dataNotFound;
    } else if (error.code === 500 && error.orig === 'LaundryDayApi') {
      //HAB Error
      error.message = API_ERROR_MESSAGES.habError;
    } else if (error.code === 503) {
      //Service Down
      error.message = API_ERROR_MESSAGES.serviceError;
    } else if (error.code === 403 || error.code === 429) {
      throw error;
    }

    throw error;
  }

  fetchAnnouncements(): Promise<IGraphQL<IAnnouncements>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryAnnouncements(),
      'fetchAnnouncements'
    ).then(response => {
      response.data.getAnnouncements.forEach(it => (it.closeFunction = () => this.closeAnnouncement(it.id)));
      return {
        data: {
          announcements: response.data.getAnnouncements,
        },
        extensions: response.data.extensions,
      } as IGraphQL<IAnnouncements>;
    });
  }

  fetchAndSortAnnouncementsByPriority(): Promise<IGraphQL<IAnnouncements>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      queryAnnouncements(),
      'fetchAnnouncements'
    ).then(response => {
      return {
        data: {
          announcements: response.data.getAnnouncements
            .map(
              announcement =>
                ({
                  ...announcement,
                  closeFunction: () => this.closeAnnouncement(announcement.id),
                  data: announcement.data,
                } as IAnnouncement)
            )
            .sort((a, b) => a.priority - b.priority),
        },
        ...response.extensions,
      };
    });
  }

  closeAnnouncement(id: string): Promise<boolean> {
    const query = {
      query: mutationCloseAnnouncement(id),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'closeAnnouncement', query)
      .then<boolean>((response: any) => response.data.closeAnnouncement)
      .catch((error: IExtendedError) => {
        this.handleResponse(error);
        return null;
      });
  }
}

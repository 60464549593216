export interface IContentNode<T> {
  content: T;
  publishDate: Date;
  tags: string[];
}

export interface IContent<T> {
  contentNodes: IContentNode<T>[];
}

export enum ContentCategory {
  whatsNewFeature = 'release-notes',
}

export enum ContentTag {
  topFeature = 'topFeature',
  portalOnly = 'portalOnly',
}

import { useEffect, useState } from 'react';
import { IExtendedError } from '../api/ExtendedError.interface';
import { IGraphQL, IGraphQLExtensions } from '../interfaces/GraphQL.interface';
import { IAnnouncements } from '../api/announcement/Announcement.interface';

interface fetchResponse<T> {
  data: T;
  extensions: Map<string, IGraphQLExtensions>;
  fetchErrors: IExtendedError[];
}

export function useFetch<T>(queries: {
  [key: string]: (any?) => Promise<any>;
}): fetchResponse<T> & { isFetchInProgress: boolean } {
  const [fetchResponse, setFetchResponse] = useState<fetchResponse<T>>({
    data: null,
    extensions: null,
    fetchErrors: [],
  });
  const [isFetchInProgress, setIsFetchInProgress] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let extendedErrors: IExtendedError[] = [];
      const entries = Object.entries(queries);
      await Promise.all(entries.map(e => e[1]().catch(error => extendedErrors.push(error)))).then(
        (responses: IGraphQL<any>[]) => {
          let aggregatedDataObject: T = {} as T;
          let aggregatedExtensionsObject: Map<string, IGraphQLExtensions> = new Map<string, IGraphQLExtensions>();

          responses.forEach((response: IGraphQL<any>, index) => {
            aggregatedDataObject = assignObject(aggregatedDataObject, response?.data);
            if (response?.extensions) {
              aggregatedExtensionsObject.set(entries[index][0], response.extensions);
            }
          });

          setFetchResponse({
            data: aggregatedDataObject,
            extensions: aggregatedExtensionsObject,
            fetchErrors: extendedErrors,
          });
        }
      );
    };

    fetchData().then(() => setIsFetchInProgress(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: fetchResponse.data,
    extensions: fetchResponse.extensions,
    fetchErrors: fetchResponse.fetchErrors,
    isFetchInProgress,
  };
}

const assignObject = (existingObject, newValues): any => {
  if (existingObject?.announcements !== undefined && newValues !== undefined && isAnnouncements(newValues)) {
    return Object.assign(existingObject, {
      announcements: [...existingObject.announcements, ...newValues.announcements],
    });
  } else {
    return Object.assign(existingObject, newValues);
  }
};

const isAnnouncements = (object: any): object is IAnnouncements => {
  return Object.prototype.hasOwnProperty.call(object, 'announcements');
};

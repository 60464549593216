import ReactPiwik from 'react-piwik';
import { Configuration } from '../../Configuration';
import { ITrackingMethods } from 'digit.commons.ui-components/dist/context/TrackingContext';

class PiwikEvents implements ITrackingMethods {
  pushEvent(arr: Array<any>) {
    try {
      ReactPiwik.push(arr);
    } catch {}
  }

  enableHeartBeatTimer() {
    this.pushEvent(['enableHeartBeatTimer']);
  }

  enableLinkTracking() {
    this.pushEvent(['enableLinkTracking']);
  }

  trackPageView() {
    this.pushEvent(['trackPageView']);
  }

  trackInternalSearch(keyword: string, category: string, searchResultCount: number) {
    this.pushEvent(['trackSiteSearch', keyword, category, searchResultCount]);
  }

  trackAllContentImpressions(contentName: string, contentPiece: string, target: string) {
    this.pushEvent(['trackContentImpression', contentName, contentPiece, target]);
  }

  trackEvent(category: string, action: string, name?: string, value?: number) {
    this.pushEvent(['trackEvent', category, action, name, value]);
  }

  trackDownload(url: string) {
    this.pushEvent(['trackLink', url, 'download']);
  }

  trackLink(url: string) {
    this.pushEvent(['trackLink', url, 'link']);
  }

  enableHeatMapSessionRecording() {
    if (Configuration.piwikConfig().enableHeatMapSessionRecording) {
      const aScript = document.createElement('script');
      aScript.type = 'text/javascript';
      aScript.src = Configuration.piwikConfig().PIWIK + '/plugins/HeatmapSessionRecording/tracker.min.js';
      document.head.appendChild(aScript);
    }
  }
}

export const piwikEvents = new PiwikEvents();

import React from 'react';
import './Announcement.scss';
import { ICovidSupport } from '../../containers/Landing/Landing.interface';
import { Badge, Card, CardBody, Hyperlink, SVG } from 'digit.commons.ui-components';
import { RENTAL_ACCOUNT } from '../../constants/routes';
import { COVID_SUPPORT_ANNOUNCEMENT } from '../../constants/component-labels';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';

interface RentalAnnouncement extends IAnnouncement {
  data: IRentalAccountAnnouncementData;
}

export interface IProps {
  id: string;
  announcement: RentalAnnouncement;
  onClose: () => any;
  hideLinkToDigitPage?: boolean;
}

interface IRentalAccountAnnouncementData {
  RentalObjects: ICovidSupport[];
}

export function CovidSupportAnnouncement(props: IProps) {
  const close = () => {
    props.announcement.closeFunction().then(props.onClose());
  };

  return (
    <Card id={props.id} className="Announcement Announcement--reminder" background={true} onDismiss={close}>
      <header>
        <Badge id={props.id} modifier="GREEN" iconBefore={SVG.support_hand}>
          {COVID_SUPPORT_ANNOUNCEMENT.iconTitle}
        </Badge>
        <h3>{COVID_SUPPORT_ANNOUNCEMENT.title}</h3>
      </header>
      <CardBody>
        {COVID_SUPPORT_ANNOUNCEMENT.description}
        {!props.hideLinkToDigitPage && (
          <Hyperlink id={props.id + '-landing-rental'} to={RENTAL_ACCOUNT} iconAfter={SVG.arrow_right}>
            {COVID_SUPPORT_ANNOUNCEMENT.linkTitle}
          </Hyperlink>
        )}
      </CardBody>
    </Card>
  );
}

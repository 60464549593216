import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

import './Footer.scss';
import { FOOTER } from '../../constants/component-labels';
import { FACEBOOK_LINK, INSTAGRAM_LINK } from '../../constants/links';

import { footerMenu } from './footer-menu';
import { DATA_PROTECTION_APP, LANDING, PRELANDING } from '../../constants/routes';
import { Icon, SVG } from 'digit.commons.ui-components';
import { IFooterProps } from './Footer.interface';
import {
  APPLICATION_FORM_BASE_ROUTE,
  DETAILS_BASE_ROUTE,
  WIZARD_ROUTE,
} from '../../../applicationform/constants/routes';
import { LAUNDRY_DAY_BOOKING_ROUTE } from '../../../laundryday/constants/routes';
import { DOCUMENT_ARCHIVE_BASE_ROUTE } from '../../../documentarchive/constants/routes';

const Footer: React.FC<IFooterProps> = props => {
  const [hideFooter, setHideFooter] = useState<boolean>(false);
  const history = useHistory();

  const { facebook, instagram } = FOOTER;
  const { isLoggedIn, focusOnTitleHandler } = props;

  useEffect(() => shouldShowFooter(history.location.pathname), [history.location.pathname]);

  /**
   * KUN-2473 - in some cases we don't want to show the footer, i.e., full screen dialogs,
   * these are shown during the application forms and booking a laundry day.
   *
   * @param pathname the current URL in the application
   */
  const shouldShowFooter = (pathname: string) => {
    let actualWizardRoute = WIZARD_ROUTE;
    if (pathname !== APPLICATION_FORM_BASE_ROUTE && !pathname.startsWith(DOCUMENT_ARCHIVE_BASE_ROUTE)) {
      actualWizardRoute = WIZARD_ROUTE.replace('/:wizard', pathname.replace('/dokumente', ''));
    }
    setHideFooter(
      pathname === LAUNDRY_DAY_BOOKING_ROUTE ||
        pathname === DATA_PROTECTION_APP ||
        (!pathname.includes(DETAILS_BASE_ROUTE) && pathname === actualWizardRoute)
    );
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onClickMenuHandler = () => {
    scrollToTop();
    if (focusOnTitleHandler) {
      focusOnTitleHandler();
    }
  };

  const createMenu = () => {
    return footerMenu(isLoggedIn).map(item => {
      return (
        <li className="Footer__menu-item" key={item.id + '-list-item-key'}>
          <NavLink
            to={item.to}
            id={item.id}
            key={item.id}
            className="Footer__link"
            activeClassName="Footer__link--selected"
            onClick={onClickMenuHandler}
          >
            {item.name}
          </NavLink>
        </li>
      );
    });
  };

  return hideFooter ? (
    <></>
  ) : (
    <footer id="footer-id" className={'Footer'}>
      <nav className="Footer__menu" id="footer-nav-id">
        <ul className="Footer__menu-list">{createMenu()}</ul>
      </nav>
      <div className="Footer__container">
        <div className="Footer__wwlogo">
          <Link className="Footer__wwlogo__link" to={isLoggedIn ? LANDING : PRELANDING} onClick={() => scrollToTop()}>
            <span className="sr-only">Wiener Wohnen Mieter*innenportal</span>
            <Icon className={'Footer__wwlogo'} icon={SVG.ww_logo} alt={'wiener wohnen logo'} />
          </Link>
        </div>
        <div className="Footer__icons">
          <a id="footer-facebook-id" href={FACEBOOK_LINK} target={'_blank'} rel="noopener noreferrer">
            <span className="sr-only">{facebook}</span>
            <Icon icon={SVG.facebook} />
          </a>
          <a id="footer-instagram-id" href={INSTAGRAM_LINK} target={'_blank'} rel="noopener noreferrer">
            <span className="sr-only">{instagram}</span>
            <Icon icon={SVG.instagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

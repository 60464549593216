import { IExtendedError } from '../ExtendedError.interface';
import {
  mutationTriggerChangeName,
  mutationUpdateContactData,
  queryRentalObjectType,
  queryTenancyContracts,
  queryTenantNameAndFeatures,
  queryTenantWithDetailsAndFeatures,
  queryTenantWithEmail,
} from './Tenant.graphql';
import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { ITenancyContractsData, ITenantData } from './Tenant.interface';
import { IGraphQL } from '../../interfaces/GraphQL.interface';
import AbstractRequestApi from '../AbstractRequestApi';

class TenantApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().tenantApi,
      'TenantApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  fetchTenant(gepart?: string): Promise<IGraphQL<ITenantData>> {
    return this.getTenant(gepart).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  private getTenant(gepart?: string): Promise<IGraphQL<ITenantData>> {
    const query = {
      query: queryTenantWithDetailsAndFeatures(gepart),
    };
    return this.makeCacheableGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, query.query, 'getTenant');
  }

  fetchTenantContracts(gepart?: string, rentalObjectType?: boolean): Promise<IGraphQL<ITenancyContractsData>> {
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      rentalObjectType ? queryRentalObjectType(gepart) : queryTenancyContracts(gepart),
      'getTenantContracts'
    ).catch((error: IExtendedError) => {
      this.handleResponse(error);
    });
  }

  fetchTenantNameAndFeatures(gepart?: string): Promise<IGraphQL<ITenantData>> {
    return this.getTenantNameAndFeatures(gepart).catch((error: IExtendedError) => this.handleResponse(error));
  }

  private getTenantNameAndFeatures(gepart?: string): Promise<IGraphQL<ITenantData>> {
    const query = {
      query: queryTenantNameAndFeatures(gepart),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'getTenantName'
    );
  }

  fetchTenantMail(gepart?: string): Promise<IGraphQL<ITenantData>> {
    return this.getTenantMail(gepart).catch((error: IExtendedError) => this.handleResponse(error));
  }

  private getTenantMail(gepart?: string): Promise<IGraphQL<ITenantData>> {
    const query = {
      query: queryTenantWithEmail(gepart),
    };
    return this.makeCacheableGraphQlRequest(
      Configuration.apiConfig().paths.pathToGraphQL,
      query.query,
      'getTenantMail'
    );
  }

  updateContactData(email: string, telNumber: string, telNumberMobile: string): Promise<any> {
    return this.updateContactDataMutation(email, telNumber, telNumberMobile).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  updateContactDataMutation(email: string, telNumber: string, telNumberMobile: string): Promise<Response> {
    const query = {
      query: mutationUpdateContactData(email, telNumber, telNumberMobile),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'updateContactDataMutation', query);
  }

  triggerChangeName(): Promise<any> {
    return this.triggerChangeNameMutation().catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  triggerChangeNameMutation(): Promise<Response> {
    const query = {
      query: mutationTriggerChangeName(),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'triggerChangeNameMutation', query);
  }
}

export default new TenantApi();

import { IAFConfiguration } from './applicationform/ApplicationFormSCSInitializer';
import { ILDConfiguration } from './laundryday/LaundryDaySCSInitializer';
import { IDAConfiguration } from './documentarchive/DocumentArchiveSCSInitializer';

export class Configuration {
  static configuration: IConfiguration;
  static DEFAULT_FETCH_TIMEOUT = 15000;

  static serverSentEvents(): IServerSentEventsConfigs {
    return Configuration.configuration.serverSentEvents;
  }

  static apiConfig(): IApiConfig {
    return Configuration.configuration.api;
  }

  static idpConfig(): IIdpConfig {
    return Configuration.configuration.idp;
  }

  static piwikConfig(): IPiwikConfig {
    return Configuration.configuration.piwik;
  }

  static sentryConfig(): ISentryConfig {
    return Configuration.configuration.sentry;
  }

  static applicationConfig(): IApplicationConfig {
    return Configuration.configuration.application;
  }

  static queryConfig(): IQueryConfig {
    return Configuration.configuration.query
      ? Configuration.configuration.query
      : { timeoutInMillis: this.DEFAULT_FETCH_TIMEOUT };
  }

  static applicationFormConfig(): IAFConfiguration {
    return Configuration.configuration['application-form-scs'];
  }

  static laundryDayConfig(): ILDConfiguration {
    return Configuration.configuration['laundry-day-scs'];
  }

  static documentArchiveConfig(): IDAConfiguration {
    return Configuration.configuration['document-archive-scs'];
  }

  static announcementApi(): IAnnouncementApiConfig {
    return Configuration.configuration.announcementApi;
  }

  static downloads(): any {
    return Configuration.configuration.externalDownloads;
  }

  static async init(): Promise<any> {
    const conf = (window as any).conf;
    if (!conf || conf === '!!configuration!!') {
      const configurationPromise = await fetch('/configuration.json');
      return configurationPromise.json().then(json => {
        Configuration.configuration = json;
      });
    } else {
      return (Configuration.configuration = conf);
    }
  }
}

export interface IConfiguration {
  application: IApplicationConfig;
  api: IApiConfig;
  idp: IIdpConfig;
  piwik: IPiwikConfig;
  sentry: ISentryConfig;
  query: IQueryConfig;
  announcementApi: IAnnouncementApiConfig;
  externalDownloads: any;
  serverSentEvents: IServerSentEventsConfigs;
}

interface IApiConfig {
  feedbackApi: string;
  tenantApi: string;
  registrationApi: string;
  rentalApi: string;
  buildingComplexApi: string;
  authenticationStateApi: string;
  contentApi: string;
  serviceCardApi: string;
  paths: IPaths;
}

interface IPaths {
  pathToGraphQL: string;
  pathToRegistration: string;
  pathToCheckMDM: string;
  pathToNewMDM: string;
  pathToNewPin: string;
  pathToDeactivation: string;
  pathToRequestPin: string;
  pathToStateCheck: string;
  pathToTriggerChangeName: string;
}

interface IIdpConfig {
  registrationUrl: string;
  loginWithPasswordUrl: string;
  logoutUrl: string;
  mobileSignatureActivationUrl: string;
}

interface IPiwikConfig {
  PIWIK: string;
  PIWIK_SITE_ID: number;
  enableHeatMapSessionRecording: boolean;
}

interface ISentryConfig {
  DSN: string;
  env: string;
}

export interface IQueryConfig {
  registrationTimoutInMillis?: number;
  timeoutInMillis: number;
}

export interface IApplicationConfig {
  applicationProxyCacheTimeout: number;
}

export interface IAnnouncementApiConfig {
  provider: [string];
}

export interface IServerSentEventsConfigs {
  endpointUrl: string;
  transports: string[];
  connectionRetryTimeout: number;
}

import React, { ReactElement, useEffect, useState } from 'react';
import { Badge, Button, Card, CardBody, Hyperlink, SVG } from 'digit.commons.ui-components';
import {
  LAUNDRY_DAY_BASE_ROUTE,
  LAUNDRY_DAY_BOOKING_ROUTE,
  LAUNDRY_DAY_CANCELLATION_ROUTE,
  LAUNDRY_DAY_REMOTE_OPENING_ROUTE,
} from '../../constants/routes';
import { FORMATTER } from '../../utility/formatHandler';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';
import { ANNOUNCEMENT } from '../../constants/components/announcement';
import { parseISO8601 } from '../../../commons/utility/DateFunctions';
import { DATE_FORMATTER } from '../../../commons/utility/DateFormatter';
import '../../../portal/components/Announcement/Announcement.scss';
import { IBookingResultData } from '../../../commons/api/laundryDay/LaundryDay.interface';
import {
  compareReservationAndAnnouncementBooking,
  transformBookingResults,
} from '../../../portal/utility/BookingsConverter';
import { useClientSidePollingConsumer } from '../../../commons/hooks/useClientSidePollingConsumer';
import {
  LAUNDRY_DAY_REMOTE_OPENING,
  LAUNDRY_DAY_REMOTE_OPENING_POLLING_EVENT_TYPE,
  LAUNDRY_DAY_REMOTE_OPENING_SENT_AT_LOCAL_STORAGE,
} from '../../constants/containers/laundry-day-remote-opening';
import { MINUTE } from '../../constants/general';
import { IGraphQL } from '../../../commons/interfaces/GraphQL.interface';
import { useHistory } from 'react-router-dom';

interface IReminderAnnouncementProps {
  id: string;
  announcement: IAnnouncement;
  showDetails?: boolean;
  onClose?: () => void;
}

const ReminderAnnouncement: React.FC<IReminderAnnouncementProps> = ({ id, announcement, showDetails, onClose }) => {
  const { booking } = announcement.data;
  const [remoteOpening, setRemoteOpening] = useState<boolean>(announcement.data.remoteOpening);
  const [heading, setHeading] = useState<string>();
  const [info, setInfo] = useState<any>();
  const [bodyDetails, setBodyDetails] = useState<ReactElement[]>();
  const history = useHistory();

  useClientSidePollingConsumer(
    LAUNDRY_DAY_REMOTE_OPENING_POLLING_EVENT_TYPE,
    (data: IGraphQL<IBookingResultData>) => {
      if (data?.data.getBookingsResult) {
        const reservations = transformBookingResults(data.data.getBookingsResult);
        const potentialReservation = reservations.find(res => compareReservationAndAnnouncementBooking(res, booking));
        if (potentialReservation && potentialReservation.remoteOpening) {
          setRemoteOpening(true);
        }
      }
    },
    announcement.body === 'NOW'
  );

  useEffect(() => {
    switch (announcement.type) {
      case 'NOW':
        renderNowBody();
        break;
      case 'TODAY':
        setHeading(ANNOUNCEMENT.reminder.title.today);
        setInfo(
          <ul className="Announcement__list Announcement__list--inline">
            <li>
              <em>
                {FORMATTER.formatTimeSlotFromDates(
                  parseISO8601(booking.startingAt),
                  parseISO8601(booking.endingAt),
                  'HH.mm'
                )}
              </em>
            </li>
            <li>Waschkabine {booking.laundryName}</li>
            <li>{booking.laundryAddress}</li>
          </ul>
        );
        setBodyDetails([
          ANNOUNCEMENT.reminder.body.details.today(
            booking.laundryAddress || booking.laundryName,
            FORMATTER.formatTimeSlotFromDates(parseISO8601(booking.startingAt), parseISO8601(booking.endingAt), 'HH.mm')
          ),
        ]);
        break;
      case 'TOMORROW':
        setHeading(ANNOUNCEMENT.reminder.title.tomorrow);
        setInfo(
          <p>
            <em>
              {DATE_FORMATTER.formatDateTime(parseISO8601(booking.startingAt), 'dd., D. MMMM')}
              {', '}
            </em>
            {FORMATTER.formatTimeSlotFromDates(
              parseISO8601(booking.startingAt),
              parseISO8601(booking.endingAt),
              'HH.mm'
            )}
          </p>
        );
        setBodyDetails([
          <ul className="Announcement__list Announcement__list--classic">
            <li>
              <em>
                {DATE_FORMATTER.formatDateTime(parseISO8601(booking.startingAt), 'dd., D. MMMM')}
                {', '}
              </em>
              <span>
                {FORMATTER.formatTimeSlotFromDates(
                  parseISO8601(booking.startingAt),
                  parseISO8601(booking.endingAt),
                  'HH.mm'
                )}
              </span>
            </li>
            <li>Waschkabine {booking.laundryName}</li>
            <li>{booking.laundryAddress}</li>
          </ul>,
        ]);
        break;
    }
  }, []);

  useEffect(() => {
    if (announcement.type === 'NOW') {
      renderNowBody();
    }
  }, [remoteOpening]);

  const renderNowBody = () => {
    setHeading(ANNOUNCEMENT.reminder.title.now);
    setInfo(
      <ul className="Announcement__list Announcement__list--inline">
        <li>
          <em>Noch bis {DATE_FORMATTER.formatTime(parseISO8601(booking.endingAt), 'HH.mm')}</em>
        </li>
        <li>Waschkabine {booking.laundryName}</li>
        <li>{booking.laundryAddress}</li>
      </ul>
    );
    let nowAnnouncementBodyDetails: ReactElement[] = [];
    nowAnnouncementBodyDetails.push(
      ANNOUNCEMENT.reminder.body.details.now(
        booking,
        DATE_FORMATTER.formatTime(parseISO8601(booking.endingAt), 'HH.mm')
      )
    );
    const laundryDayRemoteOpeningSentAt = localStorage.getItem(LAUNDRY_DAY_REMOTE_OPENING_SENT_AT_LOCAL_STORAGE);
    if (remoteOpening && !laundryDayRemoteOpeningSentAt) {
      nowAnnouncementBodyDetails.push(
        LAUNDRY_DAY_REMOTE_OPENING.linkToRemoteOpening(
          { pathname: LAUNDRY_DAY_REMOTE_OPENING_ROUTE, state: { booking: booking } },
          'reminder-announcement'
        )
      );
    } else if (Date.now() - Number(laundryDayRemoteOpeningSentAt) < MINUTE) {
      nowAnnouncementBodyDetails.push(LAUNDRY_DAY_REMOTE_OPENING.notPossibleRightNow);
      setTimeout(() => {
        let reRenderedBody: ReactElement[] = [];
        reRenderedBody.push(
          ANNOUNCEMENT.reminder.body.details.now(
            booking,
            DATE_FORMATTER.formatTime(parseISO8601(booking.endingAt), 'HH.mm')
          )
        );
        reRenderedBody.push(
          LAUNDRY_DAY_REMOTE_OPENING.linkToRemoteOpening(
            { pathname: LAUNDRY_DAY_REMOTE_OPENING_ROUTE, state: { booking: booking } },
            'reminder-announcement'
          )
        );
        localStorage.removeItem(LAUNDRY_DAY_REMOTE_OPENING_SENT_AT_LOCAL_STORAGE);
        setBodyDetails(reRenderedBody);
      }, MINUTE - (Date.now() - Number(laundryDayRemoteOpeningSentAt)));
    }
    setBodyDetails(nowAnnouncementBodyDetails);
  };

  const close = async () => {
    await announcement.closeFunction();
    onClose && onClose();
  };

  const onLaundryDayCancelClick = () => {
    history.push({
      pathname: LAUNDRY_DAY_CANCELLATION_ROUTE,
      state: { booking: booking, backRoute: LAUNDRY_DAY_BOOKING_ROUTE },
    });
  };

  return (
    <Card
      id={`${id}-reminder-announcement`}
      className="Announcement Announcement--reminder"
      onDismiss={close}
      dismissDescribedById={`${id}-reminder-announcement-header-id`}
    >
      <header id={`${id}-reminder-announcement-header-id`}>
        <Badge id={`${id}-reminder-announcement`} modifier="GREEN" iconBefore={SVG.laundry}>
          {ANNOUNCEMENT.reminder.badge}
        </Badge>
        <h3>{heading}</h3>
      </header>
      <CardBody>
        {showDetails ? (
          announcement.type !== 'NOW' ? (
            <>
              {bodyDetails}
              <p>{ANNOUNCEMENT.reminder.description.cancelInfo}</p>
              <Button id={`${id}-reminder-announcement`} modifier="secondary" onClick={onLaundryDayCancelClick}>
                {ANNOUNCEMENT.actions.cancelReservation}
              </Button>{' '}
            </>
          ) : (
            bodyDetails
          )
        ) : (
          <>
            {info}
            <Hyperlink id={`${id}-reminder-announcement`} to={LAUNDRY_DAY_BASE_ROUTE} iconAfter={SVG.arrow_right}>
              {ANNOUNCEMENT.actions.showDetails}
            </Hyperlink>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ReminderAnnouncement;

import React, { useEffect, useRef, useState } from 'react';
import './FeedbackBox.scss';
import { Button, Collapsible2, ICollapsibleHandle } from 'digit.commons.ui-components';
import Feedback, { IFeedbackHandles, IRating } from './Feedback/Feedback';
import FeedbackHeading from './FeedbackHeading/FeedbackHeading';
import FocusTrap from 'focus-trap-react';

const FeedbackBox: React.FC<{ id: string }> = ({ id }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [rating, setRating] = useState<IRating>(null);

  const feedbackModalRef = useRef<IFeedbackHandles>(null);
  const feedbackMobileRef = useRef<IFeedbackHandles>(null);
  const collapsibleRef = useRef<ICollapsibleHandle>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        document.body.style.overflowY = 'auto';
        /*
         * if the size is less than 768 and the modal is open, then we are right now switching from modal view to
         * phone view for feeback
         */
        if (isModalOpen) {
          //open the mobile collapsible section instead
          if (!collapsibleRef.current.isOpen()) {
            collapsibleRef.current.handleToggle();
          }
          //so switch off the modal dialog
          setModalOpen(false);
        }
      } else {
        /*
         * If the size is greater than mobile width and the collapsible section is open
         * then we are right now switching to model view
         */
        if (collapsibleRef.current.isOpen()) {
          //we are switching right now to modal
          setModalOpen(true);
          //and close the mobile collapsible section instead
          if (collapsibleRef.current.isOpen) {
            collapsibleRef.current.handleToggle();
          }
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);

  /** Opens up modal on tablets and larger viewports when the fixed button on the side is clicked */
  const onClickOpenModal = () => {
    setModalOpen(true);
    document.body.style.overflowY = 'hidden';
  };

  /**
   * Renders feedback only on phones. Feedback is nested inside collapsible show/hide component
   * FeedbackHeading is rendered above Collapsible which differs from tablet version which is a modal window
   */
  const renderMobileFeedback = () => {
    return (
      <div className={'FeedbackBox__feedback FeedbackBox__feedback--mobile'}>
        <FeedbackHeading isMobile={true} />
        <Collapsible2
          id={'feedbackbox-mobile'}
          className={'FeedbackBox__collapsible'}
          title={'Feedback geben'}
          toggleTitle={'Feedback schließen'}
          classNameTitle={'FeedbackBox__collapsible-title'}
          onClick={onMobileCloseHandler}
          ref={collapsibleRef}
        >
          <Feedback idPrefix={'feedback-mobile'} ref={feedbackMobileRef} existingRating={rating} isMobile={true} />
        </Collapsible2>
      </div>
    );
  };

  /** if the mobile collapsible is closed everything that happened in feedback gets reset */
  const onMobileCloseHandler = () => {
    feedbackMobileRef.current.resetFeedbackHandler();
  };

  /**
   * Renders Feedback on tablets and larger viewport devices as a modal. It does not have a collapsible
   * component wrapping it. Feedback Heading is rendered within Feedback component itself
   * Takes a unique prop idPrefix to distinct beteween the two verisons
   */
  const renderModalFeedback = () => {
    return isModalOpen ? (
      <FocusTrap>
        <div>
          <div className={'FeedbackBox__modal-scrim'} onClick={onClickCloseHandler} />
          <div className={'FeedbackBox__modal'} role="dialog" aria-modal="true" aria-label={'Feedback geben'}>
            <Feedback
              idPrefix={'feedbackbox-modal'}
              className={'FeedbackBox__feedback FeedbackBox__feedback--desktop'}
              onKeyDown={onKeyPressHandler}
              onClick={onClickCloseHandler}
              existingRating={rating}
              ref={feedbackModalRef}
            />
          </div>
        </div>
      </FocusTrap>
    ) : null;
  };

  const onClickCloseHandler = () => {
    setRating(feedbackModalRef.current.getRating());
    setModalOpen(false);
    feedbackMobileRef.current.resetFeedbackHandler();
    document.body.style.overflowY = 'auto';
  };

  /** Closes modal on tablets and larger viewports when the escape key is pressed */
  const onKeyPressHandler = ({ key }: React.KeyboardEvent) => {
    if (key === 'Escape') {
      setModalOpen(false);
      document.body.style.overflowY = 'auto';
    }
  };

  return (
    <div id={`${id}-feedbackbox-id`} className={'FeedbackBox'}>
      <Button id={'feedbackbox-modal'} modifier={'fixed'} onClick={onClickOpenModal}>
        Feedback
      </Button>
      {renderModalFeedback()}
      {renderMobileFeedback()}
    </div>
  );
};

export default FeedbackBox;

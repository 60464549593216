import React, { useEffect, useState } from 'react';
import './CookieLayover.scss';
import FocusTrap from 'focus-trap-react';
import { Button, Checkbox, Collapsible2, Icon, SVG } from 'digit.commons.ui-components';
import {
  CONTACT,
  COOKIE_SETTINGS,
  DATA_PROTECTION,
  DATA_PROTECTION_APP,
  FAQ,
  IMPRESSUM,
  LANDING,
} from '../../constants/routes';
import { COOKIE_LAYOVER_LABELS } from '../../constants/containers/cookie-layover-labels';
import { useHistory } from 'react-router-dom';
import * as Cookies from 'js-cookie';

const COOKIE_NAME = 'cdp-v1'; //client data protection
const STATISTICAL = 'statistical';
const TECHNICAL = 'technical';

export const isCookieSetCorrectly = () =>
  Cookies.get(COOKIE_NAME) === TECHNICAL || Cookies.get(COOKIE_NAME) === STATISTICAL;

const isOverlayAllowedOnPath = (pathName: string) =>
  ![DATA_PROTECTION, IMPRESSUM, FAQ, CONTACT, DATA_PROTECTION_APP].includes(pathName);

const isCookieSettingsPath = (pathName: string) => pathName === COOKIE_SETTINGS;

const showOverlay = (pathName: string) =>
  isCookieSettingsPath(pathName) || (!isCookieSetCorrectly() && isOverlayAllowedOnPath(pathName));

export const areStatisticalCookiesAccepted = () => Cookies.get(COOKIE_NAME) === STATISTICAL;

const setTheCookie = (statistical: boolean) => {
  Cookies.set(COOKIE_NAME, statistical ? STATISTICAL : TECHNICAL, { expires: 365 });
};

const hardRedirectOutOfHereToApplyPiwikSettings = () => {
  document.location.href = LANDING;
};

export const CookieLayover = () => {
  //the CookieLayover  is constantly mounted - so we listen to URL changes and reevaluate if we are shown or hidden
  const history = useHistory();
  const [shown, setShown] = useState(showOverlay(history.location.pathname));
  useEffect(() => history.listen(() => setShown(showOverlay(history.location.pathname))), [history.location.pathname]);

  //visual and input state
  const [expanded, setExpanded] = useState();
  const [acceptStatisticCookies, setAcceptStatisticCookies] = useState(areStatisticalCookiesAccepted());

  const {
    title,
    contentInfo,
    cookiesWhichAreRequired,
    statisticalCookies,
    aboutCookies,
    aboutCookiesArticleContent,
    confirmChoice,
    confirmAll,
  } = COOKIE_LAYOVER_LABELS;

  useEffect(() => {
    if (shown && !document.body.classList.contains('CookieLayover__body'))
      document.body.classList.add('CookieLayover__body');
    else if (!shown) document.body.classList.remove('CookieLayover__body');
  });

  return (
    shown && (
      <FocusTrap>
        <div id="cookie-overlay" className="CookieLayover__window">
          <div
            className={`CookieLayover__modal${expanded ? ' CookieLayover__modal--expanded' : ''}`}
            role="dialog"
            aria-modal="true"
            aria-label={title}
          >
            <h2 className="CookieLayover__heading" aria-live="polite" aria-atomic="true">
              {title}
            </h2>
            <section className="CookieLayover__content">
              <p className="CookieLayover__contentInfo">{contentInfo}</p>
              <ul className="CookieLayover__list">
                <li>
                  <div className="CookieLayover__checkIconContainer">
                    <Icon className="CookieLayover__check-icon" icon={SVG.checkbox} />
                  </div>
                  <span className="CookieLayover__cookiesWhichAreRequiredText">{cookiesWhichAreRequired}</span>
                </li>
                <li>
                  <Checkbox
                    id="statistic-cookies"
                    checked={acceptStatisticCookies}
                    onChange={v => setAcceptStatisticCookies(v)}
                  >
                    {statisticalCookies}
                  </Checkbox>
                </li>
              </ul>
              <Collapsible2
                id="over-cookies"
                className="CookieLayover__overCookies"
                title={aboutCookies}
                initialyClosed={!expanded}
                onClick={open => setExpanded(open)}
              >
                <article className="CookieLayover__overCookiesArticle">{aboutCookiesArticleContent}</article>
              </Collapsible2>
              <div className="CookieLayover__buttonContainer">
                <Button
                  id="cookie-layover-confirm"
                  modifier="secondary"
                  onClick={() => {
                    setTheCookie(acceptStatisticCookies);
                    hardRedirectOutOfHereToApplyPiwikSettings();
                  }}
                >
                  {confirmChoice}
                </Button>
                <Button
                  id="cookie-layover-confirm-all"
                  className="CookieLayover__confirmAllButton"
                  autoFocus={true}
                  onClick={() => {
                    setTheCookie(true);
                    hardRedirectOutOfHereToApplyPiwikSettings();
                  }}
                >
                  {confirmAll}
                </Button>
              </div>
            </section>
          </div>
        </div>
      </FocusTrap>
    )
  );
};

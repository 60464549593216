import { Hyperlink } from 'digit.commons.ui-components';
import { TENANT_PORTAL_SERVICE_NUMBER } from '../component-labels';
import React from 'react';

export const FEEDBACK = {
  title: 'Geben Sie uns Ihr Feedback',
  textAreaTitle: 'Wie können wir das Mieter*innenportal besser machen?',
  textArea: 'Ihre Nachricht (optional)',
  submitButtonText: 'Feedback abschicken',
  thankYou: 'Vielen Dank für Ihr Feedback!',
  hint: (
    <>
      <em>Hinweis:</em> Ihr Feedback wird anonym abgeschickt. Wir können deshalb nicht darauf antworten.
    </>
  ),
  request: 'Wenn Sie Anfragen oder ein Anliegen an Wiener Wohnen haben nutzen Sie bitte unser ',
  contact: 'Kontaktformular',
  questions: [
    {
      id: 0,
      text: 'Wie zufrieden sind Sie mit dem Mieter*innenportal?',
    },
    {
      id: 1,
      text: 'Wie gefällt Ihnen das Design des Mieter*innenportals?',
    },
    {
      id: 2,
      text: 'Wie zufrieden sind Sie mit der Bedienbarkeit des Mieter*innenportals?',
    },
  ],
  error: { rateLimit: 'Probieren Sie es bitte zu einem späteren Zeitpunkt erneut' },
};

export const FEEDBACK_HEADER = {
  headerText: 'Geben Sie uns Ihr Feedback',
  additionalHeaderText: 'Geben Sie uns Ihr Feedback',
  descriptionText: (
    <>
      <p>Ihr Feedback hilft uns, das</p> Mieter*innenportal zu verbessern.
    </>
  ),
  descriptionText2: (isMobile: boolean) => (
    <>
      <p>
        <em>Sie haben eine Frage oder ein Anliegen?</em>
      </p>
      Bitte wenden Sie sich dafür an unseren Helpdesk unter{' '}
      <Hyperlink
        id={`rental-account-service-number-${isMobile ? 'mobile' : 'modal'}-feedback`}
        to={`tel: ${TENANT_PORTAL_SERVICE_NUMBER}`}
        customHref={true}
      >
        {TENANT_PORTAL_SERVICE_NUMBER}
      </Hyperlink>
      {'. '}
      Das Feedback, welches Sie uns hier geben, wird anonym abgeschickt. Wir können deshalb nicht darauf antworten.
    </>
  ),
};

export const FEEDBACK_STARS = {
  emptyError: 'Sie müssen eine Bewertung abgeben.',
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';

import { useFetch } from '../../../commons/hooks/useFetch';
import ApplicationFormAnnouncementApi from '../../../commons/api/announcement/ApplicationFormAnnouncement.api';
import ContentApi from '../../../commons/api/content/Content.api';
import LaundryDayAnnouncementApi from '../../../commons/api/announcement/LaundryDayAnnouncement.api';
import RentalAnnouncementApi from '../../../commons/api/announcement/RentalAnnouncement.api';

import { COMPLEX_INFORMATION, LAUNDRY_DAY, RENTAL_ACCOUNT, REQUESTS_AND_DOCUMENTS } from '../../constants/routes';
import {
  Accordion,
  AccordionItem,
  Badge,
  Card,
  CardBody,
  Hyperlink,
  LoadingIndicator,
  Section,
  SubSection,
  SVG,
  TeaserTile,
} from 'digit.commons.ui-components';
import { IAnnouncement, IAnnouncements } from '../../../commons/api/announcement/Announcement.interface';
import { ContentCategory, ContentTag, IContent, IContentNode } from '../../../commons/api/content/Content.interface';
import { IFeature } from '../../components/Announcement/WhatsNewAnnouncement.interface';
import { RentalAccountAnnouncement } from '../../components/Announcement/RentalAccountAnnouncement';
import LockedAnnouncement from '../../../laundryday/components/Announcement/LockedAnnouncement';
import BrokenAnnouncement from '../../../laundryday/components/Announcement/BrokenAnnouncement';
import ReminderAnnouncement from '../../../laundryday/components/Announcement/ReminderAnnouncement';
import FormAnnouncement from '../../../applicationform/components/Announcement/FormAnnouncement';
import { WhatsNewAnnouncement } from '../../components/Announcement/WhatsNewAnnouncement';
import { CovidSupportAnnouncement } from '../../components/Announcement/CovidSupportAnnouncement';
import { FORMATTER } from '../../utility/formatHandler';
import { FAQ_LABELS } from '../../constants/containers/faq-labels';
import { LANDING_LABELS } from '../../constants/containers/landing-labels';
import { WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS } from '../../constants/containers/whats-new-labels';
import { piwikEvents } from '../../../commons/utility/piwikEvents';
import './Landing.scss';
import { TenantDataContext } from '../../context/TenantDataContext';
import ApplicationFormApi from '../../../commons/api/applicationForm/ApplicationForm.api';
import { PipeAnnouncement } from '../../components/Announcement/PipeAnnouncement';
import BuildingComplexAnnouncementApi from '../../../commons/api/announcement/BuildingComplexAnnouncement.api';

const Landing: React.FC = () => {
  const [announcements, setAnnouncements] = useState<IAnnouncement[]>(null);
  const [whatsNewAck, setWhatsNewAck] = useState<boolean>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [hbFormSyncDone, setHbFormSyncDone] = useState<boolean>(false);
  const whatsNew = useRef<IContentNode<IFeature>[]>();
  const { tenant } = useContext(TenantDataContext);

  const { data, isFetchInProgress, fetchErrors } = useFetch<IAnnouncements & IContent<IFeature>>({
    [LaundryDayAnnouncementApi.orig]: () => LaundryDayAnnouncementApi.fetchAnnouncements(),
    [RentalAnnouncementApi.orig]: () => RentalAnnouncementApi.fetchAnnouncements(),
    [ApplicationFormAnnouncementApi.orig]:
      tenant && tenant.details.hb
        ? () => ApplicationFormApi.fetchAllSynchronizedApplicationFormData()
        : () => ApplicationFormAnnouncementApi.fetchAnnouncements(),
    [ContentApi.orig]: () => ContentApi.fetchContent(ContentCategory.whatsNewFeature),
    [BuildingComplexAnnouncementApi.orig]: () => BuildingComplexAnnouncementApi.fetchAnnouncements(),
  });

  useEffect(() => {
    if (!fetchErrors.isNullOrEmpty()) {
      sendMatomoEventsBasedOnErrors();
    }
    if (!isFetchInProgress && !(tenant && tenant.details.hb)) {
      whatsNew.current = filterAndSortWhatsNewContent(data.contentNodes);
      setAnnouncements(sortAnnouncements(data.announcements));
    } else if (!isFetchInProgress) {
      setHbFormSyncDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchInProgress]);

  useEffect(() => {
    if (hbFormSyncDone) {
      /* fetch potentially synced announcements and concat them with the others - in case of an exception we just use the announcements of the other services */
      const fetchSyncedAnnouncements = async () => {
        try {
          const applicationFormAnnouncements = await ApplicationFormAnnouncementApi.fetchAnnouncements();
          if (data.announcements || applicationFormAnnouncements.data.announcements) {
            const aggregatedAnnouncements = data.announcements.concat(applicationFormAnnouncements.data.announcements);
            setAnnouncements(sortAnnouncements(aggregatedAnnouncements));
          } else {
            setAnnouncements([]);
          }
        } catch (e) {
          piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Formular-Announcements');
          setAnnouncements(sortAnnouncements(data.announcements));
        }
      };
      fetchSyncedAnnouncements();
    }
  }, [hbFormSyncDone]);

  const sortAnnouncements = (announcements: IAnnouncement[]): IAnnouncement[] =>
    announcements ? [...announcements].sort((a, b) => a.priority - b.priority) : [];

  useEffect(() => {
    if (announcements) {
      setLoading(false);
    }
  }, [announcements]);

  const sendMatomoEventsBasedOnErrors = () => {
    fetchErrors.some(error => error.orig === 'TenantApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden des Namens des Mieters');
    fetchErrors.some(error => error.orig === 'LaundryDayAnnouncementApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Waschtage-Announcements');
    fetchErrors.some(error => error.orig === 'RentalAnnouncementApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Mietzinskonto-Announcements');
    fetchErrors.some(error => error.orig === 'ApplicationFormAnnouncementApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Formular-Announcements');
    fetchErrors.some(error => error.orig === 'ContentApi') &&
      piwikEvents.trackEvent(
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.category,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.action,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.fetchWhatsNewContent
      );
    fetchErrors.some(error => error.orig === 'TenantDataApi') &&
      piwikEvents.trackEvent('technischer Fehler', 'Startseite', 'Fehler beim Laden der Mietverträge');
  };

  const filterAndSortWhatsNewContent = (contentNodes: IContentNode<IFeature>[]): IContentNode<IFeature>[] => {
    const filteredContent = contentNodes?.filter(
      content =>
        content.tags.length === 0 ||
        (content.tags.length === 1 && content.tags.includes(ContentTag.topFeature)) ||
        (content.tags.length >= 1 && content.tags.includes(ContentTag.portalOnly))
    );
    if (!filteredContent) {
      return null;
    }
    return [
      ...filteredContent
        .filter(content => content.tags.includes(ContentTag.topFeature))
        .sort(contentPublishDateComparator),
      ...filteredContent
        .filter(content => !content.tags.includes(ContentTag.topFeature))
        .sort(contentPublishDateComparator),
    ];
  };

  const contentPublishDateComparator = (a, b) => {
    if (a.publishDate < b.publishDate) {
      return 1;
    } else if (a.publishDate > b.publishDate) {
      return -1;
    }
    return 0;
  };

  const acknowledgeWhatNewFeatures = async () => {
    try {
      setWhatsNewAck(true);
      window.scrollTo(0, 0);
      await ContentApi.acknowledgeContent(ContentCategory.whatsNewFeature);
    } catch (error) {
      piwikEvents.trackEvent(
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.category,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.action,
        WHATS_NEW_ANNOUNCEMENT_MATOMO_LABELS.acknowledgeWhatsNewContent
      );
    }
  };

  const getGreeting = () => {
    if (tenant?.details && (tenant.details.firstName || tenant.details.lastName)) {
      return (
        <>
          <span className="Landing__heading-welcome">{LANDING_LABELS.welcome}</span>
          <span className="Landing__heading-name">
            {FORMATTER.firstNameLastNameLabel(tenant.details.firstName, tenant.details.lastName)}
          </span>
        </>
      );
    } else {
      return <span className="Landing__heading-welcome">{LANDING_LABELS.welcome}</span>;
    }
  };

  const renderAnnouncements = (toRender: IAnnouncement[]) => {
    return toRender.map((announcement, idx) => {
      switch (announcement.type) {
        case 'BLOCKED_USER':
          return (
            <LockedAnnouncement
              id={`laundry-day-${idx}`}
              key={`laundry-day-${announcement.id}`}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'DEFECT_WASHER':
        case 'DEFECT_DRYER':
        case 'DEFECT_BOTH':
          return (
            <BrokenAnnouncement
              id={`laundry-day-${idx}`}
              key={`laundry-day-${announcement.id}`}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'NOW':
        case 'TODAY':
        case 'TOMORROW':
          return (
            <ReminderAnnouncement
              id={`laundry-day-${idx}`}
              key={`laundry-day-${announcement.id}`}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'COVID_FLAT_SUPPORT':
          return (
            <CovidSupportAnnouncement
              id={`rental-announcement-${idx}`}
              key={`rental-announcement-${announcement.id}`}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'NEGATIVE_BALANCE':
          return (
            <RentalAccountAnnouncement
              id={`rental-announcement-${idx}`}
              key={`rental-announcement-${announcement.id}`}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'INFORMATION_PENDING':
        case 'FURTHER_REVIEW':
        case 'FORM_REJECTED':
        case 'FORM_COMPLETED':
        case 'ANSWER_PENDING':
        case 'FINISH_PENDING':
          return (
            <FormAnnouncement
              id={`application-form-announcement-${idx}`}
              key={`application-form-announcement-${announcement.id}`}
              type={announcement.type}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
        case 'NEWS':
        case 'ALERT':
        case 'INFORMATIVE':
        case 'CLEANING':
          return (
            <PipeAnnouncement
              id={`pipe-announcement-${idx}`}
              key={`pipe-announcement-${announcement.id}`}
              announcement={announcement}
              onClose={() => setAnnouncements(announcements.filter(a => a.id !== announcement.id))}
            />
          );
      }
      return null;
    });
  };

  const announcementsToRender = (): IAnnouncement[] => {
    let toRender = announcements;
    if (toRender.find(announcement => announcement.type === 'BLOCKED_USER')) {
      //remove any other laundry announcement
      toRender = toRender.filter(
        a => a.category !== 'LAUNDRY' || (a.category === 'LAUNDRY' && a.type === 'BLOCKED_USER')
      );
    }
    return toRender;
  };

  return isLoading ? (
    <LoadingIndicator id="landing" />
  ) : (
    <>
      <Helmet>
        <title>{LANDING_LABELS.pageTitle}</title>
        <meta charSet="utf-8" />
        <meta name={'description'} content={LANDING_LABELS.meta} />
      </Helmet>

      <Section id="landing" title={LANDING_LABELS.title} srOnly={true} removePaddingBottom>
        <span role="alert" aria-live="assertive" className="sr-only">
          <em>{LANDING_LABELS.title}</em> wurde geladen
        </span>
        <h3 className="Landing__heading">{getGreeting()}</h3>
        <p className="Landing__paragraph">{LANDING_LABELS.info}</p>

        {!whatsNewAck && whatsNew.current && (
          <WhatsNewAnnouncement features={whatsNew.current} onCloseHandler={acknowledgeWhatNewFeatures} />
        )}
        {announcements.length > 0 && <div>{renderAnnouncements(announcementsToRender())}</div>}

        <div className="Landing__tile-grid">
          <TeaserTile
            id="landing-bezirk-news"
            className={'Landing__TeaserTile'}
            title={LANDING_LABELS.tiles.bezirksnews.title}
            icon={SVG.WW_Bezirksnews_Logo}
            link={LANDING_LABELS.tiles.bezirksnews.link}
            href={LANDING_LABELS.tiles.bezirksnews.href}
            isExternal={true}
          >
            {LANDING_LABELS.tiles.bezirksnews.text}
          </TeaserTile>
          <TeaserTile
            id="landing-rental-account"
            className={'Landing__TeaserTile'}
            title={LANDING_LABELS.tiles.rent.title}
            icon={SVG.rental_account}
            link={LANDING_LABELS.tiles.rent.link}
            href={RENTAL_ACCOUNT}
          >
            {LANDING_LABELS.tiles.rent.text}
          </TeaserTile>
          <TeaserTile
            id="landing-laundry-day"
            className={'Landing__TeaserTile'}
            title={LANDING_LABELS.tiles.laundry.title}
            icon={SVG.laundry_day}
            link={LANDING_LABELS.tiles.laundry.link}
            href={LAUNDRY_DAY}
          >
            {LANDING_LABELS.tiles.laundry.text}
          </TeaserTile>
          <TeaserTile
            id="landing-complex-information"
            className={'Landing__TeaserTile'}
            title={LANDING_LABELS.tiles.complexInformation.title}
            icon={SVG.complexinformation}
            link={LANDING_LABELS.tiles.complexInformation.link}
            href={COMPLEX_INFORMATION}
          >
            {LANDING_LABELS.tiles.complexInformation.text}
          </TeaserTile>
          <TeaserTile
            id="landing-application-form"
            className={'Landing__TeaserTile'}
            title={LANDING_LABELS.tiles.applicationForm.title}
            icon={SVG.application_form}
            link={LANDING_LABELS.tiles.applicationForm.link}
            href={REQUESTS_AND_DOCUMENTS}
          >
            {LANDING_LABELS.tiles.applicationForm.text}
          </TeaserTile>
        </div>
        <SubSection id={'landing-faq'} title={FAQ_LABELS.title} background>
          <Accordion id={'landing-accordion-id'} name={'faq'}>
            <AccordionItem title={FAQ_LABELS.general.accordionQandA[0].accordionTitle}>
              {FAQ_LABELS.general.accordionQandA[0].accordionBody}
            </AccordionItem>
            <AccordionItem title={FAQ_LABELS.general.accordionQandA[1].accordionTitle}>
              {FAQ_LABELS.general.accordionQandA[1].accordionBody}
            </AccordionItem>
            <AccordionItem title={FAQ_LABELS.general.accordionQandA[2].accordionTitle}>
              {FAQ_LABELS.general.accordionQandA[2].accordionBody}
            </AccordionItem>
          </Accordion>
        </SubSection>
      </Section>
    </>
  );
};
export default withRouter(Landing);

import React from 'react';
import { DATE_FORMATTER } from '../../commons/utility/DateFormatter';
import { ILaundryInformation } from '../containers/LaundryDayInterface';

class Formatter {
  formatRentalObjectAddress = (rentalObject: any) =>
    `${rentalObject.street} ${rentalObject.houseNumber}/${
      rentalObject.stair.length > 0 ? `${rentalObject.stair}/` : ' Tür '
    }${rentalObject.door}`;

  formatLaundryAddress = (laundry: any) => {
    if (laundry.street === null) {
      return null;
    }
    return `${laundry.street} ${laundry.houseNumber}${laundry.stair.length > 0 ? `/${laundry.stair}` : ''}`;
  };

  formatLaundryAddressWithLaundryNumber = (laundry: ILaundryInformation) =>
    `${laundry.street} ${laundry.houseNumber}${laundry.stair.length > 0 ? ` / ${laundry.stair}` : ''} / ${
      laundry.laundryNumber
    }`;

  formatLaundryNameWithStair = (laundry: any) =>
    `${this.formatLaundryWithNumber(laundry)} \u00B7 Stiege ${laundry ? laundry.stair : ''}`;

  formatLaundryWithNumber = (laundry: any) => `Waschkabine ${laundry ? laundry.laundryNumber : ''}`;

  //14.00–18.00
  formatTimeSlot(start: string, end: string): JSX.Element {
    return (
      <>
        <time dateTime={start.replace('.', ':')}>{start}</time>–<time dateTime={end.replace('.', ':')}>{end}</time>
      </>
    );
  }

  //14.00–18.00
  formatTimeSlotFromDates(start: number, end: number, displayFormat: string): JSX.Element {
    return (
      <>
        {DATE_FORMATTER.formatTime(start, displayFormat)}—{DATE_FORMATTER.formatTime(end, displayFormat)}
      </>
    );
  }
}

export const FORMATTER = new Formatter();

import { useEffect } from 'react';
import { event } from './event';

/**
 * Consumer polling hook. Subscribes an event under a given condition (default = true), and executes an action everytime an event is received.
 * @param eventType to which events it should subscribe
 * @param action what should happen when an event is received
 * @param shouldSubscribe if the consumer should be subscribed at all (default = true)
 */
export function useClientSidePollingConsumer(eventType: string, action: (any) => any, shouldSubscribe: boolean = true) {
  /* pretty stupid component: just checks if we should subscribe and once we do the event does all the work for us */
  useEffect(() => {
    if (shouldSubscribe) {
      event.on(eventType, action);
    }
  }, []);
}

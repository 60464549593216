import React from 'react';
import './RentalAccountAnnouncement.scss';
import { IOverdueRent } from '../../containers/Landing/Landing.interface';
import { Badge, Card, CardBody, Hyperlink, SVG } from 'digit.commons.ui-components';
import { RENTAL_ACCOUNT } from '../../constants/routes';
import { RENTAL_ACCOUNT_ANNOUNCEMENT } from '../../constants/component-labels';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';

interface RentalAnnouncement extends IAnnouncement {
  data: IRentalAccountAnnouncementData;
}

export interface IRentalAccountAnnouncementProps {
  id: string;
  announcement: RentalAnnouncement;
  onClose: () => any;
}

interface IRentalAccountAnnouncementData {
  RentalObjects: IOverdueRent[];
}

export function RentalAccountAnnouncement(props: IRentalAccountAnnouncementProps) {
  const renderRentalObjects = props.announcement.data.RentalObjects.map((item, idx) => (
    <li id={`${idx}-rental-announcement-rental-object-id`} key={`${idx}-rental-announcement-rental-object`}>
      <em>{item.type}</em> {item.address}
    </li>
  ));

  const close = () => {
    props.announcement.closeFunction().then(props.onClose());
  };

  return (
    <Card id={props.id} className="RentalAccountAnnouncement" background={true} onDismiss={close}>
      <header>
        <Badge id={props.id} modifier="RED" iconBefore={SVG.coins}>
          {RENTAL_ACCOUNT_ANNOUNCEMENT.iconTitle}
        </Badge>
        <h3>{RENTAL_ACCOUNT_ANNOUNCEMENT.title}</h3>
      </header>
      <CardBody>
        <dl>
          <dt>{RENTAL_ACCOUNT_ANNOUNCEMENT.description}</dt>
          <dd>
            <ul>{renderRentalObjects}</ul>
          </dd>
        </dl>
        <Hyperlink id={props.id + '-landing-rental'} to={RENTAL_ACCOUNT} iconAfter={SVG.arrow_right}>
          {RENTAL_ACCOUNT_ANNOUNCEMENT.linkTitle}
        </Hyperlink>
      </CardBody>
    </Card>
  );
}

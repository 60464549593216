import React from 'react';
import { CUSTOMER_SERVICE_MAIL, CUSTOMER_SERVICE_NUMBER } from '../component-labels';
import { DATA_PROTECTION_LINK, FACEBOOK_POLICY_LINK, GOOGLE_POLICY_LINK } from '../links';
import { Hyperlink } from 'digit.commons.ui-components';

export const DATA_PROTECTION_LABELS_APP = {
  title: 'Datenschutz',
  pageTitle: 'Wiener Wohnen Datenschutzerklärung',
  meta: 'Datenschutz - So schützen wir Ihre Daten',
  info: (
    <>
      Die Stadt Wien – Wiener Wohnen, Rosa-Fischer-Gasse 2, A-1030 Wien („
      <strong>Wiener Wohnen</strong>“ oder „<strong>Wir</strong>
      “) respektiert Ihre Privatsphäre. Diese Datenschutzerklärung gilt für die Applikation (im Folgenden kurz App) zur
      Inanspruchnahme von Services des Online Mieter*innenportals auf dem Mobilgerät, erhältlich für iOS und Android in
      den entsprechenden App-Stores.
    </>
  ),
  accordion: [
    {
      accordionTitle: '1. Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich wenden?',
      accordionBody: (
        <>
          <p className="DataProtection__p">Für die Datenverarbeitung verantwortlich:</p>
          <address className="DataProtection__address">
            <ul>
              <li>Stadt Wien – Wiener Wohnen</li>
              <li>Rosa-Fischer-Gasse 2</li>
              <li>1030 Wien</li>
              <li>Österreich</li>
            </ul>
          </address>
          <dl className="DataProtection__p">
            <dt>Sie erreichen uns unter: </dt>
            <dd>
              <Hyperlink id="data-protection-service-nr" customHref={true} to={`tel:${CUSTOMER_SERVICE_NUMBER}`}>
                {CUSTOMER_SERVICE_NUMBER}
              </Hyperlink>
            </dd>
            <dd>
              <Hyperlink id="data-protection-mail-1" to={`mailto:${CUSTOMER_SERVICE_MAIL}`} customHref={true}>
                {CUSTOMER_SERVICE_MAIL}
              </Hyperlink>
            </dd>
          </dl>

          <p className="DataProtection__p">
            Informationen zum Datenschutz und zu Ihren Rechten als betroffene Person, wie Auskunftsrecht, Recht auf
            Löschung etc. finden Sie auch unter{' '}
            <Hyperlink id="data-protection-magistrat" isInline={true} isExternal={true} to={DATA_PROTECTION_LINK}>
              {DATA_PROTECTION_LINK}
            </Hyperlink>
          </p>
        </>
      ),
    },
    {
      accordionTitle: '2. Welche personenbezogenen Daten nutzen wir?',
      accordionBody: (
        <>
          <p className="DataProtection__p">
            Die Nutzung des Mieter*innenportals auf Ihrem Mobilgerät erfolgt freiwillig. Alle angebotenen Services der
            App können im gleichen Ausmaß über das Mieter*innenportal im Internet oder die Wiener Wohnen Servicenummer
            bezogen werden. Bei der Nutzung der Services via Mieter*innenportal haben Sie die Möglichkeit statistische
            Cookies abzulehnen
          </p>
          <p className="DataProtection__p">
            Wenn Sie sich entschlossen haben, die Vorteile und Services des Mieter*innenportals auch mobil nutzen zu
            wollen, verarbeiten wir die von Ihnen im Rahmen des Downloads und der Aktivierung der App angegebenen
            personenbezogenen Daten (Vor- und Nachname, E-Mail-Adresse, Telefonnummer, IP-Adresse, ggf. Standortdaten).
          </p>
          <p className="DataProtection__p">
            Unter „personenbezogene Daten“ sind alle Informationen zu verstehen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person beziehen. Derartige personenbezogene Daten werden von uns verarbeitet,
            wenn…
          </p>
          <h4 className="DataProtection__heading">2.1 … Sie unsere App nutzen</h4>
          <dl className={'DataProtection__dl'}>
            <dt>Bei der Nutzung der App werden folgende Informationen erhoben:</dt>
            <dd>das Datum und die Uhrzeit des Aufrufs einer Seite auf unserer Website sowie deren Dauer,</dd>
            <dd>die IP-Adresse des Mobilgerätes,</dd>
            <dd>eine eindeutige Kennung des Mobilgerätes,</dd>
            <dd>Name und Version Ihres Webbrowsers,</dd>
            <dd>
              den Browsertyp und Einstellungsdaten (Bildschirmauflösung, Farbtiefe, Zeitzoneneinstellungen,
              Browser-Erweiterungen und PlugIns, Schriftarten),
            </dd>
            <dd>das Betriebssystem und -version,</dd>
            <dd>die Website (URL), die Sie vor dem Aufruf dieser Website besucht haben</dd>
            <dd>Download, Nutzung der App, Löschung der App, Update der App, etc.</dd>
          </dl>
          <p className="DataProtection__p">
            Die hier genannten Informationen werden mithilfe von Cookies erhoben. Sie haben bei der Installation der App
            der Anwendung von Cookies aktiv mittels Button zugestimmt. Wenn Sie damit nicht einverstanden sind, dass die
            hier genannten Daten von uns erhoben und verarbeitet werden, können Sie dies in Ihren Browsereinstellungen
            ändern, siehe dazu Punkt 10. Bitte beachten Sie allerdings, dass bei einer Ablehnung der Nutzung von Cookies
            möglicherweise nur eine eingeschränkte Nutzung der App erfolgen kann.
          </p>
          <p className="DataProtection__p">
            Die Nutzung des Mieter*innenportals auf Ihrem Mobilgerät erfolgt freiwillig. Wenn Sie sich entschlossen
            haben, die Vorteile und Services des Mieter*innenportals auch mobil nutzen zu wollen, verarbeiten wir die
            von Ihnen im Rahmen des Downloads und der Aktivierung der App angegebenen personenbezogenen Daten (Vor- und
            Nachname, E-Mail-Adresse, Telefonnummer, IP-Adresse, ggf. Standortdaten).
          </p>
          <p className="DataProtection__p">
            Unter „personenbezogene Daten“ sind alle Informationen zu verstehen, die sich auf eine identifizierte oder
            identifizierbare natürliche Person beziehen. Derartige personenbezogene Daten werden von uns verarbeitet,
            wenn…
          </p>
          <h4 className="DataProtection__heading">2.2 … Sie eine Anfrage an uns richten</h4>
          <dl className={'DataProtection__dl'}>
            <dt>
              {' '}
              Bei Anfragen, Fehlermeldungen, Beschwerden oder anderen Mitteilungen werden folgende Daten verarbeitet:
            </dt>
            <dd>Name (Vor- und Nachname),</dd>
            <dd>Telefonnummer,</dd>
            <dd>
              Informationen über das Anliegen (z.B. Art des Fehlers, Screenshot, Schwere des Fehlers, Datum etc.) sowie
            </dd>
            <dd>sonstige Informationen, die Sie uns bekanntgeben.</dd>
          </dl>
          <p className="DataProtection__p">
            Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Mit der Nutzung dieses Tools stimmen
            Sie einer Verarbeitung der hier genannten personenbezogenen Daten zu.
          </p>
        </>
      ),
    },
    {
      accordionTitle: '3. Features und Links zu anderen Webseiten',
      accordionBody: (
        <p className="DataProtection__p">
          Unsere App enthält aus praktischen Gründen oder zu Ihrer Information Features, für die wir mit anderen
          Unternehmen zusammenarbeiten, und Links zu anderen Websites. Features, die Social-Networking und geographische
          Tools umfassen können, sowie Verlinkungen zu anderen Websites, können unabhängig von Wiener Wohnen betrieben
          werden. Für diese können eigene Datenschutzhinweise oder -richtlinien gelten. Soweit Sie die Features und
          verlinkten Websites, die Sie besuchen, nicht im Besitz oder unter Kontrolle von Wiener Wohnen stehen, nutzen
          bzw. besuchen, sind wir nicht für die Inhalte, deren Nutzung oder deren Datenschutzpraktiken verantwortlich.
          Wir empfehlen daher dringend die Durchsicht und Prüfung entsprechender Datenschutzerklärungen.
        </p>
      ),
    },
    {
      accordionTitle:
        '4. Für welchen Zweck und auf welcher Rechtsgrundlage verarbeiten wir Ihre personenbezogenen Daten?',
      accordionBody: (
        <>
          <dl className={'DataProtection__dl'}>
            <dt className={'DataProtection__dt'}>4.1 Zweck: Zur Verfügungsstellung von online Serviceleistungen</dt>
            <dd>
              die unter Punkt 2.1 genannten personenbezogenen Daten verarbeitet werden, um (1) Ihnen diese App zur
              Verfügung zu stellen, weiter zu verbessern und zu entwickeln, (2) Nutzungsstatistiken erstellen zu können,
              (3) Angriffe auf unsere App zu erkennen, zu verhindern oder untersuchen zu können
            </dd>
            <dd>
              die unter Punkt 2.2 genannten personenbezogenen Daten verarbeitet werden, um Ihre Anfrage,
              Schadensmeldung, Beschwerde zu bearbeiten und zu beantworten.
            </dd>
          </dl>
          <dl className={'DataProtection__dl'}>
            <dt>4.2 Rechtsgrundlage: Aufgrund Ihrer Einwilligung (Art. 6 Abs. 1 a DSGVO)</dt>
            <dd>
              {' '}
              Die Nutzung der App erfolgt freiwillig. Mit der Nutzung der App stimmen Sie gleichzeitig der Verarbeitung
              Ihrer personenbezogenen Daten iSd Punktes 2 zu.
            </dd>
            <dd>
              Die Einwilligung zur Datenverarbeitung können Sie jederzeit per E-Mail an{' '}
              <Hyperlink
                id="data-protection-mail"
                customHref={true}
                isInline={true}
                to={`mailto:${CUSTOMER_SERVICE_MAIL}`}
              >
                {CUSTOMER_SERVICE_MAIL}
              </Hyperlink>{' '}
              widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis
              zum Widerruf erfolgten Datenverarbeitung nicht berührt.
            </dd>
          </dl>
        </>
      ),
    },
    {
      accordionTitle: '5. Wer bekommt meine Daten?',
      accordionBody: (
        <p className="DataProtection__p">
          Zu den unter Punkt 4.1 genannten Zwecken werden wir Ihre personenbezogenen Daten an von uns eingesetzte
          IT-Dienstleister übermitteln. Wir verwenden Auftragsverarbeiter, die Dienstleistungen in unserem Auftrag
          durchführen. Die Auftragsverarbeiter dürfen diese Daten lediglich gemäß unseren Weisungen und, soweit zur
          Durchführung von Dienstleistungen für uns oder zur Einhaltung rechtlicher Vorschriften notwendig, nutzen oder
          offenlegen. Wir verpflichten diese Auftragsverarbeiter vertraglich dazu, die Vertraulichkeit und die
          Sicherheit der personenbezogenen Daten zu gewährleisten, die sie in unserem Auftrag verarbeiten. Derzeit
          verwenden wir die Services von Stadt Wien - Wiener Wohnen Kundenservice GmbH in 1110 Wien, Medwedweg 14, Unit
          5, die Services von ACP Holding Österreich GmbH in 1120 Wien, Wagenseilgasse 3, die Services von Nessus GmbH
          in 1100 Wien, Fernkorngasse 10, sowie das Rechenzentrum der Stadt Wien betreut durch die Magistratsabteilung
          01 Wien Digital (IKT Dienstleisterin der Stadt Wien) in 1220 Wien, Stadlauer Straße 56, die Services von ARGE
          ViennaCall in 1020 Wien, Vorgartenstraße 206 C bestehend aus Vienna Communications Consulting Gmbh in 1020
          Wien, Vorgartenstraße 206 C und der Call Now Telekommunikations Service Gmbh in 1030 Wien, Erdbergstraße 52-60
          und der Webhelp Austria GmbH in 1210 Wien, Floridsdorfer Hauptstraße 1 Alle angeführten Servicedienstleister
          haben ihren Sitz in Österreich.
        </p>
      ),
    },
    {
      accordionTitle: '6. Google',
      accordionBody: (
        <p className="DataProtection__p">
          Für den Betrieb der App werden Dienstleistungen von Google (Google Firebase; Google Analytics für Firebase) in
          Anspruch genommen. Mit der Nutzung der App geben Sie daher personenbezogene Daten an Google bekannt (wie etwa
          Benutzername, Standortdaten, Häufigkeit der App-Nutzung). Diesbezüglich sind wir nicht Verantwortliche iSd des
          Art 4 Z7 DSGVO und verweisen wir ausdrücklich auf die Datenschutzerklärung und Cookie Policy von Google und
          empfehlen diese genau zu durchzulesen, abrufbar unter{' '}
          <Hyperlink id={'data-protection-google-policy'} isExternal isInline to={GOOGLE_POLICY_LINK}>
            {GOOGLE_POLICY_LINK}
          </Hyperlink>
          .
        </p>
      ),
    },
    {
      accordionTitle: '7. Wie lange werden meine personenbezogenen Daten gespeichert?',
      accordionBody: (
        <>
          <p className="DataProtection__p">
            Sobald Sie Ihre Einwilligung zur Datenverarbeitung widerrufen, werden die personenbezogenen Daten gelöscht,
            es sei denn es bestehen gesetzliche Aufbewahrungspflichten (diese können sich z.B. aus dem
            Unternehmensrecht, Steuerrecht, Bundesabgabenordnung etc. ergeben) oder die personenbezogenen Daten sind für
            die Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften notwendig.
          </p>
          <p className="DataProtection__p">Anonymisierte Daten können wir unbegrenzt speichern.</p>
        </>
      ),
    },
    {
      accordionTitle: '8. Welche Datenschutzrechte habe ich?',
      accordionBody: (
        <>
          <dl className={'DataProtection__dl'}>
            <dt className={'DataProtection__dt'}>Nach geltendem Recht sind Sie unter anderem berechtigt:</dt>
            <dd>
              <strong>Recht auf Auskunft:</strong> Sie können jederzeit überprüfen, welche personenbezogenen Daten wir
              über Sie gespeichert haben und Kopien dieser Daten erhalten,
            </dd>
            <dd>
              <strong>Recht auf Löschung:</strong> Sie können nach einem Widerruf Ihrer Einwilligung zur
              Datenverarbeitung verlangen, dass wir Ihre gespeicherten personenbezogenen Daten wieder löschen,
            </dd>
            <dd>
              <strong>Recht auf Berichtigung/ Recht auf Löschung:</strong> Sie können die Berichtigung, Ergänzung oder
              das Löschen Ihrer personenbezogenen Daten verlangen, die falsch sind, oder nicht rechtskonform verarbeitet
              werden,
            </dd>
            <dd>
              <strong>Recht auf Einschränkung:</strong> Sie können unter bestimmten Umständen können die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten verlangen,
            </dd>
            <dd>
              <strong>Recht auf Widerspruch:</strong> Sie können der Verarbeitung Ihrer personenbezogenen Daten, die
              nicht auf der Grundlage Ihrer Einwilligung beruht, widersprechen,
            </dd>
            <dd>
              <strong>Recht auf Datenübertragung:</strong> Sie können die Datenübertragbarkeit verlangen,
            </dd>
          </dl>
          <p className={'DataProtection__p'}>
            <strong>Geltendmachung:</strong> Sie können die oben aufgezählten Rechte bei der Stadt Wien – Wiener Wohnen,
            unter{' '}
            <Hyperlink id="data-protection-mail-2" to={`mailto:${CUSTOMER_SERVICE_MAIL}`} customHref={true} isInline>
              {CUSTOMER_SERVICE_MAIL}
            </Hyperlink>{' '}
            sowie bei der zuständigen Datenschutzbehörde geltend machen. Zuständige für Österreich ist die
            Datenschutzbehörde in der Wickenburggasse 8, 1080 Wien.
          </p>
        </>
      ),
    },
    {
      accordionTitle: '9. Was ist ein Cookie und welche Cookies verwenden wir?',
      accordionBody: (
        <>
          <p className={'DataProtection__p'}>
            Cookies sind kleine Dateien, die es einem Anbieter einer App ermöglichen auf dem Mobilgerät des Nutzers
            spezifische, auf den Nutzer bezogene Informationen zu speichern. Cookies helfen uns dabei, die
            Nutzungshäufigkeit und die Anzahl der Nutzer unserer App zu ermitteln sowie unsere Angebote für Sie
            komfortabel und effizient zu gestalten.
          </p>
          <dl className={'DataProtection__dl'}>
            <dt>Wir verwenden die folgenden Cookies:</dt>
            <dd>
              <i>Session Cookies:</i> Das sind Cookies, die ausschließlich für die Dauer Ihrer Nutzung unserer Website
              zwischengespeichert werden. Die meisten der Cookies auf dieser Website sind Session Cookies.
            </dd>
            <dd>
              <i>Permanente Cookies:</i> Das sind Cookies, die Informationen über Besucher festhalten, die wiederholt
              unsere App nutzen. Der Zweck des Einsatzes dieser Cookies besteht darin, eine optimale Benutzerführung
              anbieten zu können sowie Besucher wiederzuerkennen und bei wiederholter Nutzung eine möglichst attraktive
              App und interessante Inhalte präsentieren zu können.
            </dd>
            <dd>
              <i>First Party und Third Party Cookies:</i> Beim nächsten Aufruf der App mit demselben Endgerät wird das
              Cookie und die darin gespeicherten Informationen entweder an die Webseite zurückgesandt, die sie erzeugt
              hat (First Party Cookie) oder an eine andere Webseite gesandt, zu der es gehört (Third Party Cookie).
            </dd>
            <dd>
              <i>Statistik Cookies:</i> Statistik Cookies sammeln Informationen darüber, wie Webseiten genutzt werden,
              um folglich deren Attraktivität, Inhalt und Funktionalität zu verbessern.
            </dd>
          </dl>
          <dl className={'DataProtection__dl'}>
            <dt className={'DataProtection__p'}>
              <strong>
                Wir verwenden die folgenden Cookies, Push-Benachrichtigungen und Social Media PlugIns von
                Drittanbietern:
              </strong>
            </dt>
            <dd>
              <strong>Matomo:</strong> Die App verwendet Matomo (ehemals Piwik), eine Open-Source-Software zur
              statistischen Auswertung der Besucherzugriffe. Matomo verwendet Cookies, die auf Ihrem Mobilgerät
              gespeichert werden und die eine anonymisierte Analyse ihrer Nutzung der App ermöglichen. Ein Rückschluss
              auf eine bestimmte Person ist dabei nicht möglich, da ihre IP-Adresse sofort nach der Verarbeitung und vor
              der Speicherung anonymisiert wird.
              <p>
                Sie können sich entscheiden, ob in ihrem Browser ein eindeutiger Webanalyse-Cookie abgelegt werden darf,
                um dem Betreiber der App die Erfassung und Analyse verschiedener statistischer Daten zu ermöglichen.
              </p>
            </dd>
            <dd>
              <strong>Google Firebase:</strong> Änderungen im Mieter*innenportal erfahren Sie automatisch über
              sogenannte Push-Benachrichtigungen. Diese Services werden mithilfe der Software Google Firebase
              ermöglicht. Derartige Push-Benachrichtigungen beinhalten lediglich einen Hinweis darüber, dass sich im
              Kundenkonto etwas geändert hat; darüberhinausgehende Informationen, wie etwa über den Inhalt der Änderung
              werden in den Benachrichtigungen nicht angezeigt. Sie können den Erhalt von Push-Nachrichten deaktivieren.
            </dd>
            <dd>
              <strong>Google Analytics für Firebase:</strong> Im Rahmen von Google Firebase erhebt Google Analytics
              statistische Daten über die Besuchsdauer und das Besucherverhalten sowie Standortdaten, die für die
              Nutzung bestimmter Location-Services notwendig sind. Ein Zugriff auf den Inhalt des Mieter*innenportals
              oder die über den Inhalt der im Mieter*innenportal genutzten Services erfolgt durch Google Analytics
              nicht.
            </dd>
            <dd>
              <strong>Google:</strong> Für den Betrieb der App werden Dienstleistungen von Google (Google Firebase;
              Google Analytics für Firebase) in Anspruch genommen. Mit der Nutzung der App geben Sie daher
              personenbezogene Daten an Google bekannt (wie etwa Benutzername, Standortdaten, Häufigkeit der
              App-Nutzung). Diesbezüglich sind wir nicht Verantwortliche iSd des Art 4 Z7 DSGVO und verweisen wir
              ausdrücklich auf die Datenschutzerklärung und Cookie Policy von Google und empfehlen diese genau zu
              durchzulesen, abrufbar unter https://policies.google.com/. Darüberhinausgehende Informationen über die
              Inhalte des Mieter*innenportals (wie etwa Informationen zum Mieterkonto etc) erhält Google nicht.
            </dd>
            <dd>
              <strong>Facebook:</strong> Auf unserer Website werden sogenannte Social Media Plugins ("Plugins") des
              sozialen Netzwerkes Facebook verwendet, das von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA
              ("Facebook") betrieben wird.
              <p>
                Mit dem Besuch unserer Website erfolgt noch keine Verbindung mit dem Server von Facebook. Wenn Sie auf
                die Schaltfläche klicken, öffnet sich ein neues Browserfenster und ruft die Seite von Facebook auf, auf
                der Sie (ggf. nach Eingabe Ihrer Login-Daten) mit den dortigen Plugins interagieren können.
              </p>
              <p>
                Facebook Inc. mit Sitz in den USA ist für das us-europäische Datenschutzübereinkommen „Privacy Shield“
                zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet. Bitte
                beachten Sie, dass Wiener Wohnen für die Verarbeitung ihrer personenbezogenen Daten, die möglicherweise
                über die Nutzung des Plugins erfolgt, nicht Verantwortliche iSd DSGVO ist. Den Zweck und den Umfang der
                Datenverarbeitung durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum
                Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenrichtlinie von Facebook, abrufbar unter{' '}
                <Hyperlink id={'data-protection-facebook-policy'} isExternal isInline to={FACEBOOK_POLICY_LINK}>
                  {FACEBOOK_POLICY_LINK}
                </Hyperlink>
                .
              </p>
            </dd>
          </dl>
          <p className={'DataProtection__p'}>
            Bitte beachten Sie darüber hinaus, dass hinsichtlich der Cookies von Drittanbietern Wiener Wohnen nicht als
            Verantwortliche gilt.
          </p>
        </>
      ),
    },
    {
      accordionTitle: '10. Cookies verwalten und löschen',
      accordionBody: (
        <>
          <p className="DataProtection__p">
            „Session Cookies“ fallen in die Kategorie „unbedingt notwendige Cookies“ – diese sind für den Betrieb der
            Online Services unbedingt erforderlich und von der Einwilligungspflicht ausgenommen.
          </p>
          <p className="DataProtection__p">
            Für die Verwendung aller anderen Cookies benötigen wir Ihre Einwilligung. Sofern Sie keine Einwilligung in
            die Verwendung der anderen Cookies abgegeben haben, findet keine Verarbeitung betreffend dieser Cookies
            statt.
          </p>
          <p className="DataProtection__p">
            Wenn Sie Cookies auf Ihrem Mobilgerät kontrollieren wollen, können Sie ihre Browser-Einstellungen so wählen,
            dass Sie eine Benachrichtigung bekommen, wenn eine Website Cookies speichern will. Sie können Cookies auch
            blockieren und löschen, wenn sie bereits auf Ihrem Mobilgerät gespeichert wurden.
          </p>
          <p className="DataProtection__p">
            Obwohl jeder Browser über unterschiedliche Parameter verfügt, erfolgt die Konfiguration der Cookies
            üblicherweise im Menü <i>„Einstellungen“</i> oder <i>„Tools“</i>. Weitere Details zur Konfiguration von
            Cookies in Ihrem Browser finden Sie im Menü <i>„Hilfe“</i>.
          </p>
          <p className={'DataProtection__p'}>
            <strong>Bitte beachten Sie</strong> aber, dass eine Einschränkung der Cookies auch zu einer eingeschränkten
            Darstellung der App und mit einer eingeschränkten Benutzerführung sowie einer eingeschränkten Funktionalität
            führen kann.
          </p>
        </>
      ),
    },
  ],
};

import { mutationSubmitFeedback } from './Feedback.graphql';
import { IExtendedError } from '../ExtendedError.interface';
import { ISubmitFeedbackData, ISubmitFeedbackInput } from './Feedback.interface';
import { FEEDBACK } from '../../../portal/constants/containers/feedback-labels';
import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import { IGraphQL } from '../../interfaces/GraphQL.interface';
import AbstractRequestApi from '../AbstractRequestApi';

class FeedbackApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().feedbackApi,
      'FeedbackApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }

  submitFeedback(feedbackBody: ISubmitFeedbackInput): Promise<IGraphQL<ISubmitFeedbackData>> {
    const query = {
      query: mutationSubmitFeedback(FeedbackApi.convertFeedbackBodyToString(feedbackBody)),
    };
    return this.postGraphQlRequest(Configuration.apiConfig().paths.pathToGraphQL, 'submitFeedback', query);
  }

  postSubmitFeedback(feedbackBody: ISubmitFeedbackInput): Promise<IGraphQL<ISubmitFeedbackData>> {
    return this.submitFeedback(feedbackBody).catch((error: IExtendedError) => {
      return this.handleResponse(error);
    });
  }

  /** transform the ISubmitFeedbackInput object into a string (representing an array structure) that is readable for graphQL.
   *
   * What it looks like beforehand:
   * questions: {
   *     Frage1: vergebene Sterne1,
   *     Frage2: vergebene Sterne2.
   *     Frage3: vergebene Sterne3
   * }
   *
   * how it's sent to the backend:
   * questions: [
   *    {text: "Frage1", stars: vergebene Sterne1},
   *    {text: "Frage2", stars: vergebene Sterne2},
   *    {text: "Frage3", stars: vergebene Sterne3}
   * ]
   */
  static convertFeedbackBodyToString(feedbackBody: ISubmitFeedbackInput): string {
    const questions = [FEEDBACK.questions[0].text, FEEDBACK.questions[1].text, FEEDBACK.questions[2].text];
    let feedbackBodyMapped: string = 'questions: [';
    /* assigment here in order to avoid a TSLint false-positive regarding usage of const within for loop */
    let question;
    for (question of questions) {
      feedbackBodyMapped += '{text: "' + question + '", stars: ' + feedbackBody.questions[question] + '}, ';
    }
    feedbackBodyMapped += ']';
    if (feedbackBody.optionalText && feedbackBody.optionalText.length > 0) {
      feedbackBodyMapped += ', optionalText: """' + feedbackBody.optionalText + '"""';
    }
    return feedbackBodyMapped;
  }
}

export default new FeedbackApi();

import React from 'react';
import { IPipeAnnouncementData } from '../../containers/Landing/Landing.interface';
import { Badge, Card, CardBody, Hyperlink, SVG } from 'digit.commons.ui-components';
import { IAnnouncement } from '../../../commons/api/announcement/Announcement.interface';
import './Announcement.scss';
import { PIPE_ANNOUNCEMENT } from '../../constants/component-labels';

interface PipeAnnouncement extends IAnnouncement {
  data: IPipeAnnouncementData;
}

export interface PipeAnnouncementProps {
  id: string;
  announcement: PipeAnnouncement;
  onClose: () => any;
}

export function PipeAnnouncement(props: PipeAnnouncementProps) {
  const close = () => {
    props.announcement.closeFunction().then(props.onClose());
  };

  const pipeAnnouncement = props.announcement.data.newsData;

  const badgeDetailsForType = {
    NEWS: { svg: SVG.alert, title: 'NACHRICHT', modifier: 'YELLOW' },
    ALERT: { svg: SVG.bell, title: 'AUFGEPASST', modifier: 'RED' },
    INFORMATIVE: { svg: SVG.lightbulb, title: 'WISSENSWERTES', modifier: 'GREEN' },
    CLEANING: { svg: SVG.construction_flat, title: 'SANIERUNG', modifier: 'GREEN' },
  };

  const shouldRenderLink = (): boolean => pipeAnnouncement.link && pipeAnnouncement.link.trim().length > 0;

  return (
    <Card id={props.id} className="Announcement" background={true} onDismiss={close}>
      <header>
        <Badge
          id={props.id}
          modifier={badgeDetailsForType[props.announcement.type].modifier}
          iconBefore={badgeDetailsForType[props.announcement.type].svg}
        >
          {badgeDetailsForType[props.announcement.type].title}
        </Badge>
        <h3>{pipeAnnouncement.title}</h3>
      </header>
      <CardBody>
        <p className={'Announcement__description'}>
          {pipeAnnouncement.text}
          {shouldRenderLink() && (
            <>
              {'\n'}
              <Hyperlink
                id={`${props.id}-landing-pipe`}
                to={pipeAnnouncement.link}
                isExternal={true}
                iconAfter={SVG.external_link}
                className={'Announcement__description-link'}
              >
                {PIPE_ANNOUNCEMENT.linkText}
              </Hyperlink>
            </>
          )}
        </p>
      </CardBody>
    </Card>
  );
}

import { Configuration } from '../../../Configuration';
import { stdpAuthenticationRequiredHandler } from '../../../AuthenticationState';
import AbstractRequestApi from '../AbstractRequestApi';

class RentalAnnouncementApi extends AbstractRequestApi {
  constructor() {
    super(
      () => Configuration.apiConfig().rentalApi,
      'RentalAnnouncementApi',
      () => Configuration.queryConfig(),
      () => stdpAuthenticationRequiredHandler
    );
  }
}

export default new RentalAnnouncementApi();

import React from 'react';
import { Badge, Card, CardBody, SVG } from 'digit.commons.ui-components';
import { ANNOUNCEMENT } from '../../constants/components/announcement';
import '../../../portal/components/Announcement/Announcement.scss';

interface ILockedAnnouncementProps {
  id: string;
  showDetails?: boolean;
  onClose?: () => any;
}

const LockedAnnouncement: React.FC<ILockedAnnouncementProps> = props => {
  const { id, onClose, showDetails } = props;
  const { badge, title, body } = ANNOUNCEMENT.locked;

  return (
    <Card
      id={`${id}-locked-announcement`}
      className="Announcement Announcement--locked"
      onDismiss={showDetails ? null : onClose}
      dismissDescribedById={`${id}-locked-announcement-header-id`}
    >
      <header id={`${id}-locked-announcement-header-id`}>
        <Badge id={`${id}-locked-announcement`} modifier="RED" iconBefore={SVG.laundry_locked}>
          {badge}
        </Badge>
        <h3>{title}</h3>
      </header>
      <CardBody>{showDetails ? body.details : body.preview}</CardBody>
    </Card>
  );
};

export default LockedAnnouncement;

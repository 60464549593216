import React from 'react';
import './WhatsNewAnnouncement.scss';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Hyperlink,
  SVG,
  DescriptionList,
  IDescription,
} from 'digit.commons.ui-components';
import { IContentNode } from '../../../commons/api/content/Content.interface';
import { WHATS_NEW } from '../../constants/routes';
import { WHATS_NEW_ANNOUNCEMENT_LABELS } from '../../constants/containers/whats-new-labels';
import { IFeature } from './WhatsNewAnnouncement.interface';
import { useHistory } from 'react-router-dom';

interface IWhatsNewAnnouncementProps {
  features: IContentNode<IFeature>[];
  onCloseHandler: () => void;
}

const MAX_VISIBLE_FEATURES = 3;

export const WhatsNewAnnouncement: React.FC<IWhatsNewAnnouncementProps> = ({ features, onCloseHandler }) => {
  const { badge, history, acknowledged } = WHATS_NEW_ANNOUNCEMENT_LABELS;

  const reactHistory = useHistory();

  return features?.length > 0 ? (
    <Card id={'whats-new-announcement'} className="WhatsNewAnnouncement" onDismiss={onCloseHandler}>
      <header>
        <Badge id={'whats-new-announcement'} modifier="GREEN" iconBefore={SVG.alert}>
          {badge}
        </Badge>
      </header>
      <CardBody>
        <DescriptionList
          descriptions={features
            .map(item => ({ ...item.content, date: item.publishDate } as IDescription))
            .slice(0, MAX_VISIBLE_FEATURES)}
        />

        <div className={'WhatsNewAnnouncement__navigation'}>
          <div className={'WhatsNewAnnouncement__details-link'}>
            <Hyperlink
              id={'whats-new-details'}
              iconAfter="arrow-right"
              onClick={e => {
                e.preventDefault();
                reactHistory.push({
                  pathname: WHATS_NEW,
                  state: {
                    isBackLinkVisible: true,
                  },
                });
                onCloseHandler();
              }}
            >
              {history}
            </Hyperlink>
          </div>
          <div
            className={`WhatsNewAnnouncement__ack-button WhatsNewAnnouncement__ack-button${
              features?.length > MAX_VISIBLE_FEATURES ? '--50' : '--100'
            }`}
          >
            <Button id={'whats-new-acknowledge'} onClick={onCloseHandler}>
              {acknowledged}
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Popover.scss';
import { Icon, SVG } from 'digit.commons.ui-components';
import { POPOVER } from '../../constants/component-labels';

export interface IPopoverProps {
  id: string;
  user: string;
}

const Popover: React.FC<IPopoverProps> = props => {
  const [isOpen, setOpen] = useState<boolean>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLElement>(null);

  const handleClick = useCallback(
    e => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target) &&
        contentRef.current &&
        !contentRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    },
    [setOpen, isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick, false);
      return () => document.removeEventListener('click', handleClick, false);
    }
  }, [isOpen, handleClick]);

  const myProfileClicked = () => {
    if (isOpen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const { id, user, children } = props;

  return (
    <div id={`${id}-popover-id`} className={`Popover${isOpen ? ' Popover--visible' : ''}`}>
      <button className="Popover__button" onClick={myProfileClicked} aria-expanded={isOpen} ref={buttonRef}>
        <Icon icon={SVG.account} />
        <Icon className="Popover__arrow" icon={SVG.arrow_down} />
        <span className="sr-only">Profilmenü</span>
      </button>
      {isOpen && (
        <div className="Popover__popover">
          <dl className={'Popover__user'}>
            <dt>{POPOVER.loggedInAs}</dt>
            <dd>{user}</dd>
          </dl>
          <nav className="Popover__nav" ref={contentRef}>
            <ul className="Popover__menu-list">{children}</ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Popover;

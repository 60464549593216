import React from 'react';

class Formater {
  formatSAPString(sapText: String) {
    if (sapText === null) return '';

    const indexOfComma = sapText.indexOf(',');
    const zipCode = this.fourDigitZipCode(sapText.substr(0, indexOfComma + 1));
    const secondPart = sapText
      .substr(indexOfComma + 1, sapText.length)
      .toLowerCase()
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    return `${secondPart}${zipCode}`;
  }

  amountFormat(amount: number) {
    return amount === null ? '' : Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
  }

  fourDigitZipCode(zipCode: string) {
    zipCode = zipCode.trim();
    if (zipCode) {
      if (zipCode.replace('.,', '').length === 1) {
        return `, 10${zipCode.replace('.,', '')}0`;
      } else if (zipCode.replace('.,', '').length === 2) {
        return `, 1${zipCode.replace('.,', '')}0`;
      } else {
        return `, ${zipCode}`;
      }
    }
    return zipCode;
  }

  formatAddressWithCity(addressData: any) {
    let address = `${addressData.streetName}  ${addressData.streetnumber}`;
    if (addressData.stairAndDoor) {
      address = address + `/${addressData.stairAndDoor}`;
    }
    address = address + `, ${addressData.zipcode}  ${addressData.city}`;
    return address;
  }

  /** formats a given phone number, returns an empty string for an undefined, empty or if the phone number after removing the prefixes is shorter than 2 digits */
  phoneNumberFormat(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    return phoneNumber;
  }

  timeTagExtension(time: string) {
    return <time dateTime={time}>{time.replace(':', '.')}</time>;
  }

  dateTimeTagExtension(date: string) {
    const [day, month, year] = date.split('.');
    return <time dateTime={`${year}-${month}-${day}`}>{date}</time>;
  }

  format(template: string, ...values: string[]) {
    for (let index = 0; index < values.length; index++) {
      template = template.replace(`{${index}}`, values[index]);
    }
    return template;
  }

  firstNameLastNameLabel(firstName: string, lastName: string) {
    return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`;
  }
}

export const FORMATTER = new Formater();

import {
  COMPLEX_INFORMATION,
  DOCUMENT_ARCHIVE,
  LANDING,
  LAUNDRY_DAY,
  RENTAL_ACCOUNT,
  REQUESTS_AND_DOCUMENTS,
} from '../../constants/routes';

export const HEADER_MENU = {
  items: [
    {
      name: 'Startseite',
      to: LANDING,
      id: 'header-landing-link-id',
    },
    {
      name: 'Mietzinskonto',
      to: RENTAL_ACCOUNT,
      id: 'header-rental-acccount-link-id',
    },
    {
      name: 'Waschtage',
      to: LAUNDRY_DAY,
      id: 'header-laundry-day-link-id',
    },
    {
      name: 'Hof-Informationen',
      to: COMPLEX_INFORMATION,
      id: 'header-building-complex-link-id',
    },
    {
      name: 'Anträge und Dokumente',
      to: REQUESTS_AND_DOCUMENTS,
      id: 'header-requests-and-documents-link-id',
    },
    {
      name: 'Dokumentenarchiv',
      to: DOCUMENT_ARCHIVE,
      id: 'header-document-archive-link-id',
    },
  ],
};

import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import 'moment/locale/de-at';
import { Piwik } from './portal/utility/piwikSetup';
/** Context */
import Landing from './portal/containers/Landing/Landing';
/** Components */
import Header from './portal/components/Header/Header';
import { ErrorPanel, InfoBar, LoadingIndicator, Section } from 'digit.commons.ui-components';
import FeedbackBox from './portal/containers/FeedbackBox/FeedbackBox';
/** Utility */
import { piwikEvents } from './commons/utility/piwikEvents';
import ReactPiwik from 'react-piwik';
import * as Sentry from '@sentry/react';
/** Constants */
import {
  COMPLEX_INFORMATION,
  COMPLEX_INFORMATION_DETAILS,
  CONTACT,
  COOKIE_SETTINGS,
  DATA_PROTECTION,
  DATA_PROTECTION_APP,
  FAQ,
  IMPRESSUM,
  INVALID_CONTRACT,
  LANDING,
  LOGIN_REDIRECT,
  NEW_MDM,
  NEW_PIN,
  PRELANDING,
  PROFILE,
  PROFILE_CHANGE_NAME,
  PROFILE_CHANGE_NAME_CONFIRMATION,
  REGISTRATION,
  REGISTRATION_CONFIRMATION,
  RENTAL_ACCOUNT,
  WHATS_NEW,
} from './portal/constants/routes';
import { LAUNDRY_DAY_ROUTES } from './laundryday/constants/routes';
import { APPLICATION_FORM_ROUTES } from './applicationform/constants/routes';
import { DOCUMENT_ARCHIVE_ROUTES } from './documentarchive/constants/routes';
/** Authentication */
import { AuthenticationState } from './AuthenticationState';
import TenantApi from './commons/api/tenant/Tenant.api';
import { ERROR_BOUNDARY, PAGE_TITLE_APP } from './portal/constants/component-labels';
import { TrackingContextProvider } from './portal/context/TrackingContext';
import Footer from './portal/components/Footer/Footer';

import { areStatisticalCookiesAccepted, CookieLayover } from './portal/containers/CookieLayover/CookieLayover';
import ErrorBoundary from './portal/containers/ErrorBoundary/ErrorBoundary';
import InvalidContract from './portal/containers/InvalidContract/InvalidContract';
import { Configuration } from './Configuration';
import { Integrations } from '@sentry/tracing';
import { Breadcrumb } from '@sentry/types/dist/breadcrumb';
/** Extensions */
import './commons/extensions/Array.extension';
import { ITenantData } from './commons/api/tenant/Tenant.interface';
import { TenantDataContextProvider } from './portal/context/TenantDataContext';
import DataProtectionApp from './portal/containers/DataProtectionApp/DataProtectionApp';

const ApplicationFormRoutes = lazy(() => import('./applicationform/ApplicationFormRoutes'));
const LaundryDayRoutes = lazy(() => import('./laundryday/LaundryDayRoutes'));
const DocumentArchiveRoutes = lazy(() => import('./documentarchive/DocumentArchiveRoutes'));

/** Containers */
const Registration = lazy(() => import('./portal/containers/Registration/Registration'));
const NewMdm = lazy(() => import('./portal/containers/NewCredentials/NewMdm/NewMdm'));
const NewPin = lazy(() => import('./portal/containers/NewCredentials/NewPin/NewPin'));
const ChangeName = lazy(() => import('./portal/containers/Profile/ChangeName/ChangeName'));
const PreLanding = lazy(() => import('./portal/containers/PreLanding/PreLanding'));
const Profile = lazy(() => import('./portal/containers/Profile/Profile'));
const Imprint = lazy(() => import('./portal/containers/Imprint/Imprint'));
const ComplexInformation = lazy(() => import('./portal/containers/ComplexInformation/ComplexInformation'));
const RentalAccount = lazy(() => import('./portal/containers/RentalAccount/RentalAccount'));
const DataProtection = lazy(() => import('./portal/containers/DataProtection/DataProtection'));
const Contact = lazy(() => import('./portal/containers/Contact/Contact'));
const Faq = lazy(() => import('./portal/containers/Faq/Faq'));
const DownTime = lazy(() => import('./portal/containers/DownTime/DownTime'));
const NoMatchingRoute = lazy(() => import('./portal/containers/NoMatchingRoute/NoMatchingRoute'));
const RegistrationConfirmation = lazy(() =>
  import('./portal/containers/Registration/RegistrationConfirmation/RegistrationConfirmation')
);
const ComplexInformationDetails = lazy(() =>
  import('./portal/containers/ComplexInformation/ComplexInformationDetails/ComplexInformationDetails')
);
const ChangeNameConfirmation = lazy(() =>
  import('./portal/containers/Profile/ChangeName/ChangeNameConfirmation/ChangeNameConfirmation')
);
const WhatsNewHistory = lazy(() => import('./portal/containers/WhatsNewHistory/WhatsNewHistory'));

const TenantNotAvailableErrorPanel = () => (
  <Section id={'app-tenant'} title={PAGE_TITLE_APP}>
    <ErrorPanel
      id={'app-tenant'}
      title={ERROR_BOUNDARY.title}
      errorText={ERROR_BOUNDARY.errorText}
      descriptionText={ERROR_BOUNDARY.description}
    />
  </Section>
);

const App: React.FC = () => {
  const [loggedInGepartId, setLoggedInGepartId] = useState<string>('');
  const [numberOfRetries, setNumberOfRetries] = useState<number>(0);
  const [tenantData, setTenantData] = useState<ITenantData>({ tenant: null, clientFeatures: null });
  const [tenantError, setTenantError] = useState<boolean>(false);
  const {
    authRedirectUrl,
    isLoggedIn,
    isOtfuLoggedIn,
    isContractInvalid,
    isInDowntime,
    isAccessToPortalBlocked,
  } = AuthenticationState;

  useEffect(() => {
    const initialize = async () => {
      if (AuthenticationState.isLoggedIn) {
        await getTenantById();
      } else {
        setTenantError(true);
      }
    };

    Sentry.init({
      enabled: Configuration.sentryConfig().DSN !== '',
      dsn: Configuration.sentryConfig().DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: Configuration.sentryConfig().env,
      beforeBreadcrumb(breadcrumb: Breadcrumb, hint) {
        if (breadcrumb.category === 'fetch') {
          breadcrumb.data.url = breadcrumb.data.url.split('&query=')[0];
        }
        return breadcrumb;
      },
      attachStacktrace: true,
    });

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (numberOfRetries !== 0) {
      getTenantById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfRetries]);

  const piwikSetup = areStatisticalCookiesAccepted()
    ? new ReactPiwik({
        url: Configuration.piwikConfig().PIWIK,
        siteId: Configuration.piwikConfig().PIWIK_SITE_ID,
        trackDocumentTitle: true,
        enableLinkTracking: true,
      })
    : null;

  const getTenantById = async () => {
    try {
      const responseWithData = await TenantApi.fetchTenantNameAndFeatures();
      if (responseWithData.data.tenant) {
        const response = responseWithData.data.tenant.details;
        setTenantData(responseWithData.data);
        setLoggedInGepartId(response.gepartId);
        setNumberOfRetries(0);
      } else if (numberOfRetries < 5) {
        setTimeout(() => setNumberOfRetries(numberOfRetries + 1), 1500);
      }
    } catch (error) {
      setTenantError(true);
      piwikEvents.trackEvent(
        'technischer Fehler',
        'Laden der Applikation (App.tsx)',
        'Fehler beim Laden des Namens des Mieters'
      );
    }
  };

  const focusOnTitle = () => {
    const title: HTMLElement = document.getElementById('app-main-id').querySelector('h2');
    if (title != null) {
      title.focus();
    }
  };

  return (
    <>
      {
        /**
         * Important, due to backend changes (asychronous Kafka instead of REST call):
         * There are four scenarios:
         * 1) getTenantById will fail because the user does not have a x-pvp-wienerwohnen-customerid in the header
         * 2) getTenantById will fail because tenant-service is not available
         * 3) getTenantById will not fail, but doesn't return a tenant
         *          (NEW: this is because Kafka is asynchronous, so after registering the database entry
         *                for the registered tenant might not be persisted at this point)
         * 4) getTenantById will return the tenant
         *
         * In case 1) we redirect to login/registration
         * In case 2) the error message (data not available) is shown
         * In case 3) we will poll for the gepartId (at the moment 5 times, every 1.5 seconds) and a loading indicator
         *            will be shown all the time, until the 5th retry fails - then the error message will be shown.
         * In case 4) everything will be fine (besides short loading indicator until the tenant is fetched).
         */
        isLoggedIn && !loggedInGepartId && !tenantError && numberOfRetries < 5 ? ( // case 3)
          <LoadingIndicator id={'app-tenant'} />
        ) : (
          // all the other cases
          <TrackingContextProvider>
            <TenantDataContextProvider tenantData={tenantData}>
              <Router>
                <>
                  {areStatisticalCookiesAccepted() && <Piwik piwikSetup={piwikSetup} />}
                  <CookieLayover />
                  {(AuthenticationState.oneWeekBeforeDowntime || (isInDowntime && !isAccessToPortalBlocked)) && (
                    <aside>
                      <InfoBar
                        id={`downtime-${AuthenticationState.downtime.startDate}`}
                        text={AuthenticationState.downtime.reason}
                      />
                    </aside>
                  )}
                  <Header
                    focusOnTitleHandler={() => focusOnTitle()}
                    isLoggedIn={isLoggedIn}
                    isOtfuLoggedIn={isOtfuLoggedIn}
                    isContractInvalid={isContractInvalid}
                    siteTitle={PAGE_TITLE_APP}
                    isInDownTime={isInDowntime && isAccessToPortalBlocked}
                  />

                  <main id="app-main-id">
                    <ErrorBoundary>
                      <Suspense fallback={<LoadingIndicator id={'lazy-loading-indicator'} />}>
                        <Switch>
                          [...
                          {loggedInGepartId && [
                            <Route exact={true} key="route-1" path={PRELANDING}>
                              <Redirect to={LANDING} />
                            </Route>,
                            <Route exact={true} key="route-2" path={INVALID_CONTRACT}>
                              <Redirect to={LANDING} />
                            </Route>,
                            <Route
                              exact
                              key="route-3"
                              path={LANDING}
                              render={props => (isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <Landing />)}
                            />,
                            <Route
                              exact
                              key="route-4"
                              path={PROFILE}
                              render={props => (isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <Profile />)}
                            />,
                            <Route exact key="route-5" path={PROFILE_CHANGE_NAME} render={() => <ChangeName />} />,
                            <Route
                              key="route-6"
                              path={PROFILE_CHANGE_NAME_CONFIRMATION}
                              component={ChangeNameConfirmation}
                            />,
                            <Route
                              exact
                              key="route-7"
                              path={`${RENTAL_ACCOUNT}/:vkont?`}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <RentalAccount />
                              }
                            />,
                            <Route
                              exact
                              key="route-7"
                              path={COMPLEX_INFORMATION}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <ComplexInformation />
                              }
                            />,
                            <Route
                              key="route-8"
                              path={`${COMPLEX_INFORMATION_DETAILS}/:complexId`}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <ComplexInformationDetails />
                              }
                            />,
                            <Route
                              exact={true}
                              key="route-9"
                              path={DOCUMENT_ARCHIVE_ROUTES}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <DocumentArchiveRoutes />
                              }
                            />,
                            <Route
                              exact={true}
                              key="route-10"
                              path={LAUNDRY_DAY_ROUTES}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <LaundryDayRoutes />
                              }
                            />,
                            <Route
                              exact={true}
                              key="route-11"
                              path={APPLICATION_FORM_ROUTES}
                              render={props =>
                                isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <ApplicationFormRoutes />
                              }
                            />,
                            <Route exact={true} key="route-99" path={WHATS_NEW}>
                              <WhatsNewHistory />
                            </Route>,
                          ]}
                          <Route
                            exact={true}
                            path={REGISTRATION}
                            key="route-12"
                            render={props =>
                              isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <Registration />
                            }
                          />
                          <Route
                            exact={true}
                            path={NEW_MDM}
                            key="route-13"
                            render={() => (isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <NewMdm />)}
                          />
                          ,
                          <Route
                            exact={true}
                            path={NEW_PIN}
                            key="route-14"
                            render={() => (isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <NewPin />)}
                          />
                          ,
                          <Route
                            key="route-15"
                            path={REGISTRATION_CONFIRMATION}
                            render={() =>
                              isInDowntime && isAccessToPortalBlocked ? <DownTime /> : <RegistrationConfirmation />
                            }
                          />
                          <Route key="route-16" path={IMPRESSUM}>
                            <Imprint isLoggedIn={isLoggedIn} redirectPath={authRedirectUrl} />
                          </Route>
                          ,
                          <Route key="route-17" path={DATA_PROTECTION}>
                            <DataProtection isLoggedIn={isLoggedIn} redirectPath={authRedirectUrl} />
                          </Route>
                          ,
                          <Route key="route-17" path={DATA_PROTECTION_APP}>
                            <DataProtectionApp />
                          </Route>
                          ,
                          <Route key="route-18" path={CONTACT}>
                            <Contact isLoggedIn={isLoggedIn} redirectPath={authRedirectUrl} />
                          </Route>
                          ,
                          <Route key="route-19" path={FAQ}>
                            <Faq isLoggedIn={isLoggedIn} redirectPath={authRedirectUrl} />
                          </Route>
                          ,
                          <Route key="route-20" path={PRELANDING}>
                            {isInDowntime && isAccessToPortalBlocked ? (
                              <DownTime />
                            ) : (
                              <PreLanding isLoggedIn={isLoggedIn} />
                            )}
                          </Route>
                          ,
                          <Route key="route-21" path={LOGIN_REDIRECT}>
                            <Redirect to={LANDING} />
                          </Route>
                          ,
                          <Route key="route-22" path={COOKIE_SETTINGS}>
                            <PreLanding isLoggedIn={false} />
                          </Route>
                          ,
                          <Route key="route-23" exact={true} path={INVALID_CONTRACT}>
                            <InvalidContract />
                          </Route>
                          ,
                          <Route
                            key="route-24"
                            render={() => {
                              if (!isLoggedIn) {
                                return <Redirect to={authRedirectUrl} />;
                              } else if (tenantError || numberOfRetries >= 5) {
                                return <TenantNotAvailableErrorPanel />;
                              } else {
                                return <NoMatchingRoute />;
                              }
                            }}
                          />
                          ]
                        </Switch>
                        {isLoggedIn && <FeedbackBox id={'app'} />}
                      </Suspense>
                    </ErrorBoundary>
                  </main>
                  <Footer isLoggedIn={isLoggedIn} focusOnTitleHandler={() => focusOnTitle()} />
                </>
              </Router>
            </TenantDataContextProvider>
          </TrackingContextProvider>
        )
      }
    </>
  );
};

export default App;

export default class DocumentArchiveSCSInitializer {
  private static configuration: IDAConfiguration;
  private static DEFAULT_FETCH_TIMEOUT = 15000;
  private static authRequiredHandler: () => void;

  static apiConfig(): IDAApiConfig {
    return DocumentArchiveSCSInitializer.configuration.api;
  }

  static queryConfig(): IDAQueryConfig {
    return DocumentArchiveSCSInitializer.configuration.query
      ? DocumentArchiveSCSInitializer.configuration.query
      : { timeoutInMillis: this.DEFAULT_FETCH_TIMEOUT };
  }

  static init(configuration: IDAConfiguration, authenticationRequiredHandler: () => void) {
    DocumentArchiveSCSInitializer.configuration = configuration;
    DocumentArchiveSCSInitializer.authRequiredHandler = authenticationRequiredHandler;
  }

  static piwikConfig(): IPiwikConfig {
    return DocumentArchiveSCSInitializer.configuration.piwik;
  }

  static authenticationRequiredHandler(): () => void {
    return DocumentArchiveSCSInitializer.authRequiredHandler;
  }
}

export interface IDAConfiguration {
  api: IDAApiConfig;
  query: IDAQueryConfig;
  piwik: IPiwikConfig;
}

interface IDAApiConfig {
  documentArchiveApi: string;
  paths: IDAPaths;
}

interface IDAPaths {
  pathToGraphQL: string;
}

export interface IDAQueryConfig {
  timeoutInMillis: number;
}

interface IPiwikConfig {
  PIWIK: string;
  PIWIK_SITE_ID: number;
  enableHeatMapSessionRecording: boolean;
}

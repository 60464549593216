import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorPanel, Section } from 'digit.commons.ui-components';
import { INVALIDCONTRACT_LABELS } from '../../constants/containers/invalidcontract-labels';

const InvalidContract: React.FC = props => {
  const { pageTitle, title, meta, errorPanel } = INVALIDCONTRACT_LABELS;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charSet={'utf-8'} />
        <meta name={'description'} content={meta} />
      </Helmet>
      <Section id={'invalidcontract'} title={title} removePaddingBottom={true}>
        <ErrorPanel
          id={'invalid-contract'}
          title={errorPanel.title}
          errorText={errorPanel.errorText}
          descriptionText={errorPanel.descriptionText}
        />
      </Section>
    </>
  );
};

export default InvalidContract;
